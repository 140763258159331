import React from 'react'
import clsx from 'clsx'
import { Box } from '@mui/material';

import { useStyles } from '../Style/StyleAdmin';
import ResumoPage from '../Page/ResumoPage';
import { useHistory } from 'react-router-dom';

function Resumo() {
  const classes = useStyles();
  const history = useHistory();



  const irPagamento = () => {
    history.push('conclusao');
    console.log('foi')

  };

  return (
    <Box sx={{background:'#f0f0f0'}}>
      <ResumoPage proximoPasso={irPagamento} />
    </Box>
  )
}

export default Resumo;
