import React from "react";
import { makeStyles } from '@mui/styles';
import Logo from "../../images/logo3d.png";
import { Image, Layout } from "./style";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        width:'100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

const LoadingModal = () => {
    const classes = useStyles();

    return (
        <div>
            <div>
                <Layout>
                <Image src={Logo}  />
                    <h1>
                       Carregando...
                    </h1>
                </Layout>
            </div>
        </div>
    );
}

export default LoadingModal;
