import { withRouter, Link } from "react-router-dom";

import { Row, Card, Col, ListGroup, Badge, Tab } from "react-bootstrap";

///////////////////////////
import PerfectScrollbar from 'react-perfect-scrollbar'
//////////////////////////

import axios from 'axios';
import React, { useEffect, useState, Fragment } from 'react';
import NumberFormat from 'react-number-format';

import { makeStyles } from '@mui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import numeral from 'numeral'


import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ptBR } from "date-fns/locale";
import DateFnsUtils from '@date-io/date-fns';

//actions
import totalVendasPorVendedorPorData from '../../actions/relatorios/total-vendas-vendedor-e-data';
import pedidosByVendedor from '../../actions/relatorios/lista-pedidos-by-vendedor';



// componemts
import VendasTotalVendido from '../../components/Vendas/VendasTotalVendido';
import CardListaPedidos from '../../components/Vendas/CardListaPedidos';

//functions

import { getDataUsuario } from '../../services/auth'

import {
  Grid,
  Divider,
  Box
} from '@material-ui/core';

import { useHistory } from "react-router-dom";

const sampleContainer = {
  maxHeight: "600px"
};

const sampleContent = {
  height: "700px",
  background: "black",
  color: "white"
};

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  conteudo: {
    background: '#f0f0f0',
    padding: 30,
    height: '90vh'

  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  card: {
    background: '#fff',
    padding: 30,
    borderRadius: 5
  },
  headerPedido: {
    borderBottom: '1px solid #f0f0f0',
    paddingBottom: 4
  },
  labelPedido: {
    fontSize: 10,
    color: '#999'

  },
  value_xl: {
    fontSize: 16,
    fontWeight: 900,
    color: '#333'
  },
  value_md: {
    fontSize: 12,
    fontWeight: 600,
    color: '#333'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});





function VendasPage(props) {

  const [inicio, setInicio] = React.useState(new Date());
  const [final, setFinal] = React.useState(new Date());

  const [mes, setMes] = React.useState('03');
  const [ano, setAno] = React.useState('2022');

  const [vendasVendedor, setVendasVendedor] = React.useState('');
  const [listaVendas, setListaVendas] = useState([])
  const [key, setKey] = useState(0);

  const buscarMetricas = () => {
    totalVendasPorVendedorPorData({ cpf: getDataUsuario().cpf, inicio: inicio, fim: final })
      .then(res => {
        setVendasVendedor(res.data.vendas);
      }).catch(e => console.log(e));


    pedidosByVendedor({ cpf: getDataUsuario().cpf, inicio: inicio, fim: final })
      .then(res => {
        setListaVendas(res.data);
      }).catch(e => console.log(e));
  }

  useEffect(() => {
    buscarMetricas();
  }, []);



  const handleChangeInicio = (date) => {
    setInicio(date);
  };

  const handleChangeFinal = (date) => {
    setFinal(date);
  };



  return (
    <Grid style={{ marginTop: 5, padding: 20 }} container spacing={3}>

      <Grid item md={12}>
        <Box style={{ background: '#f0f0f0', padding: 20, borderRadius: 10 }}>
          <Grid container >
            <Grid item md={4}>
              <Grid container alignItems="center" spacing={2}>
                  <Grid item md sm={3} xs={12}>
                    <DatePicker
                      disableToolbar
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      label="Data inicial"
                      value={inicio}
                      onChange={handleChangeInicio}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                  <Grid item md sm={3} xs={12}>
                    <DatePicker
                      disableToolbar
                      margin="normal"
                      variant="inline"
                      id="date-picker-inline"
                      label="Data final"
                      format="dd/MM/yyyy"
                      value={final}
                      onChange={handleChangeFinal}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </Grid>
                  <Grid item md>
                    <Button onClick={buscarMetricas} color="secondary" variant="contained">Pesquisar</Button>
                  </Grid>
              </Grid>
            </Grid>



          </Grid>
        </Box>
      </Grid>

      <Grid md={4} item>
        <Grid spacing={4} container>
          <Grid item md={12}>
            <Grid item md={12}>
            </Grid>
            <Grid container spacing={6}>
              <Grid item md={12} >
                <Typography variant="h6">PDV</Typography>
                <Divider />
                <Grid container style={{ marginTop: 20 }} spacing={2}>
                  <Grid item md={12} sm={12} xs={12}>
                    <Typography variant="subtitle1">Vendas</Typography>
                  </Grid>
                  <Grid item md={6} sm={12} xs={12}>
                    <VendasTotalVendido
                      titulo={"Total Vendas"}
                      // valor={numeral(vendasVendedor.totalProdutos).format('0,0.00')}
                      valor={vendasVendedor ? numeral(vendasVendedor.totalProdutos).format('0,0.00') : 0}
                    />
                  </Grid>

                  <Grid item md={6} sm={12} xs={12}>
                    <VendasTotalVendido
                      titulo={"Quantidade Vendas"}
                      valor={vendasVendedor ? vendasVendedor.totalPedidos : 0}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* <Grid item md={6} >
                    <Typography variant="h6">LINX</Typography>
                    <Divider/>
                      <Grid container style={{marginTop:20}} spacing={2}>
                            <Grid item md={12} sm={12} xs={12}>
                            <Typography variant="subtitle1">Vendas</Typography>
                            </Grid>
                              <Grid item md={6} sm={12} xs={12}>
                              <VendasTotalVendido
                              titulo={"Total Vendas"}
                              />
                              </Grid>
                              <Grid item md={6} sm={12} xs={12}>
                              <VendasTotalVendido
                              titulo={"Quantidade Vendas"}
                              />
                              </Grid>

                      </Grid>
                    </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid md={8} item>

        <Grid spacing={4} container>
          <Grid item md={12}>
            <Grid item md={12}>

            </Grid>
            <Grid container spacing={6}>
              <Grid item md={12} >
                <Typography variant="h6">Minhas Vendas</Typography>
                <Divider />
                <Grid container style={{ marginTop: 20 }} spacing={2}>



                  <Grid item md={12} sm={12} xs={12}>
                    <Box style={{ maxHeight: 400, padding: 20, overflowY: 'scroll' }}>
                      {listaVendas.map(res => (
                        <div>
                          <CardListaPedidos
                            dataPedido={res}
                            pedido={res.number}
                            frete={res.shipping_price}
                            valor={res.items}
                            status={res.status}
                            cliente={res.first_name}
                            data={res.date_created}
                            pago={res.paid === true ? "Sim" : "Não"}
                          />
                        </div>

                      ))}
                    </Box>
                  </Grid>








                </Grid>
              </Grid>

            </Grid>
          </Grid>
        </Grid>


      </Grid>
    </Grid>



  )
}
export default withRouter(VendasPage)
