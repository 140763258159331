import axios from "axios";

const getPedidoByNumber = number => {
    return axios({
        method: "get",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/pedidos/pedido/" + number
    });
};

export default getPedidoByNumber;
