import React, { Fragment, useState } from 'react'
import { Dropdown, SplitButton, ButtonGroup } from 'react-bootstrap'
//import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap'
import { Form } from 'react-bootstrap'
import avatar1 from '../../images/avatar/1.jpg'
import Shipping from '../Main/Frete'

import {BiStore} from 'react-icons/bi';
import {FaShippingFast} from 'react-icons/fa';
import {FiArrowLeftCircle} from 'react-icons/fi';

import {useHistory} from "react-router-dom";

import Stepper from "../../components/Stepper";

import buscarClientePorCpfLinx from '../../actions/cadastro/buscar-cliente-por-cpf-linx'



const CadastroPage = () => {

  const [tipoEntrega, setTipoEntrega] = React.useState('');

  const [cpf, setCpf] = useState('');
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');


  const history = useHistory();
 
  const voltarPdv = () => {
   history.push('frete')
 }



  return (
    <Fragment>
           <div className='container'>
    
      <div className='row' style={{paddingBottom:30, paddingTop:10, borderRadius:10, alignContent:'center', alignItems:'center' }}>
           <div className='col-xl-2 col-xxl-2'>
           <FiArrowLeftCircle onClick={voltarPdv} size={40} /> Voltar
             </div>
             <div className='col-xl-10 col-xxl-10'>
           <Stepper
           valorStep={2}
           />
             </div>
             </div>
        <div className='col-xl-12 col-md-12 col-xxl-12'>

        <div className="row">
        <div className='col-xl-12'>
              <div className='card profile-card'>
   
                <div className='card-body'>
                  <form>
                    <div className='mb-2'>
                      <div className='title mb-4'>
                        <span className='fs-18 text-black font-w600'>
                          CADASTRO DE CLIENTE
                        </span>
                      </div>
                      <div className='row'>
                        <div className='col-xl-4 col-sm-6'>
                          <div className='form-group'>
                            <label>CPF</label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Digite o CPF'
                              value={cpf}
                              onChange={e => {
                                setCpf(e.target.value);
                                if(cpf.length >= 11) {
                                  buscarClientePorCpfLinx(cpf).then(res => {

                                    if(res.data.user){
                                      setNomeCompleto(res.data.user.CLIENTE_VAREJO);
                                      setTelefone(res.data.user.DDD_CELULAR+res.data.user.CELULAR);
                                      setBairro(res.data.user.BAIRRO);
                                      setRua(res.data.user.ENDERECO);
                                      setEstado(res.data.user.UF);
                                      setCep(res.data.user.CEP);
                                      setNumero(res.data.user.NUMERO);
                                      setCidade(res.data.user.CIDADE);
                                      setComplemento(res.data.user.COMPLEMENTO);
                                      setEmail(res.data.user.EMAIL);

                                    } else {
                                      alert('Esse ccpf não possui cadastro');
                                    }
                                      
                                  }).catch(err => console.log(err));
                                }
                               
                              
                              }}
                            />
                          </div>
                        </div>
       
                        <div className='col-xl-8 col-sm-6'>
                          <div className='form-group'>
                            <label>Nome</label>
                            <input
                              type='text'
                              className='form-control'
                              placeholder='Digite seu nome completo'
                              value={nomeCompleto}
                              onChange={e => setNomeCompleto(e.target.value)}
                            />
                          </div>
                        </div>
                        
                        <div className='col-xl-4 col-sm-6'>
                          <div className='form-group'>
                            <label>Whatsapp</label>
                            <div className='input-group input-icon mb-3'>
                              <div className='input-group-prepend'>
                                <span
                                  className='input-group-text'
                                  id='basic-addon2'
                                >
                                  <i
                                    className='fa fa-whatsapp'
                                    aria-hidden='true'
                                  />
                                </span>
                              </div>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Telefone'
                                value={telefone}
                                onChange={e => setTelefone(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-xl-4 col-sm-6'>
                          <div className='form-group'>
                            <label>Email</label>
                            <div className='input-group input-icon mb-3'>
                              <div className='input-group-prepend'>
                                <span
                                  className='input-group-text'
                                  id='basic-addon3'
                                >
                                  <i className='las la-envelope' />
                                </span>
                              </div>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Email'
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
            
                      </div>
                    </div>
                    <div className='mb-sm-5 mb-2'>
                      <div className='title mb-4'>
                        <span className='fs-18 text-black font-w600'>
                          INFORMAÇÕES PESSOAIS
                        </span>
                      </div>

                      <div className='row'>
     
     <div className='col-xl-5 col-sm-6'>
       <div className='form-group'>
         <label>Cep</label>
         <div className='input-group'>
           <input
             type='text'
             className='form-control'
             placeholder='CEP'
             value={cep}
             onChange={e => setCep(e.target.value)}
           />
         </div>
       </div>
     </div>
     <div className='col-xl-5 col-sm-6'>
                          <div className='form-group'>
                            <label>Rua</label>
                            <div className='input-group'>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Rua'
                                value={rua}
                                onChange={e => setRua(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='col-xl-2 col-sm-6'>
                          <div className='form-group'>
                            <label>Numero</label>
                            <div className='input-group'>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Número'
                                value={numero}
                                onChange={e => setNumero(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                  </div>


                      <div className='row'>
     
         

                        <div className='col-xl-4 col-sm-6'>
                          <div className='form-group'>
                            <label>Bairro</label>
                            <div className='input-group'>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Bairro'
                                value={bairro}
                                onChange={e => setBairro(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

            
                        <div className='col-xl-2 col-sm-6'>
                          <div className='form-group'>
                            <label>Complemento</label>
                            <div className='input-group'>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Complemento'
                                value={complemento}
                                onChange={e => setComplemento(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='col-xl-4 col-sm-6'>
                          <div className='form-group'>
                            <label>Cidade</label>
                            <div className='input-group'>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Cidade'
                                value={cidade}
                                onChange={e => setCidade(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>

                        <div className='col-xl-2 col-sm-6'>
                          <div className='form-group'>
                            <label>Estado</label>
                            <div className='input-group'>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Estado'
                                value={estado}
                                onChange={e => setEstado(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
       
                      </div>
                    </div>
     
                    <div>
     
                    </div>
                  </form>
                </div>
              </div>
            </div>
         </div>




        </div>
      
      </div>
    </Fragment>
  )
}

export default CadastroPage;
