import React from 'react'
import clsx from 'clsx'
import { Container, Grid, Paper } from '@material-ui/core';

import { useStyles } from '../Style/StyleAdmin';
import PedidosPage from '../Page/PedidosPage'
import FilterPage from '../Page/FilterPage'
import NoActivity from '../Page/NoActivity'
import Cart from '../../components/Cart'
import { useHistory } from 'react-router-dom';



function Pedidos() {
  const classes = useStyles();
  const history = useHistory();

  const irResumo = () => {
    history.push('resumo');
    console.log('foi')
    
  };

  

  return(
    <div style={{background:'#f0f0f0'}}>
      <Container maxWidth="false" style={{marginTop:30}}>
        <PedidosPage />
      </Container> 
           
    </div>
  )
}

export default Pedidos;
