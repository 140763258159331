import React from 'react';
import { Avatar, Container, Grid, Typography } from '@mui/material';

export default function SemResultados() {

  return (
    <Container >
      <Grid container style={{ justifyContent: 'center' }} spacing={3}>
        <Grid container justify="center" alignItems="center" item xs={12} md={8} lg={12}>
          <Avatar alt="Remy Sharp" src="https://cdn.dribbble.com/users/3209358/screenshots/12585283/media/f59448f8ddf1b89a0c59153c14736b5c.gif" />
          <Typography variant="h6" gutterBottom>
            Nenhum resultado encontrado
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}
