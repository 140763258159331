
import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';

import { Link } from 'react-router-dom'; // Importa o Link do react-router-dom


const DrawerMenuCategorias = ({open, onClose,  }) => {

    const MENU = [
        { slug: 'feminino', titulo: 'feminino' },
        { slug: 'masculino', titulo: 'masculino' },
        { slug: 'infantil', titulo: 'infantil' },
        { slug: 'esportivo', titulo: 'esportivo' },
        { slug: 'acessorios', titulo: 'acessorios' }
      ]
    return (

        <Drawer open={open} onClose={onClose}>
            <Box sx={{ width: 250 }} role="presentation" onClick={onClose}>
                <List>
                    {MENU && MENU.map((item) => (
                        <ListItemButton key={item.titulo} disablePadding component={Link} to={`/pdv/c/${item.slug}`}>
                        <Typography>{item.titulo}</Typography>
                        </ListItemButton>
                    ))}
                </List>
       
            </Box>
        </Drawer>



    )
}

export default DrawerMenuCategorias
