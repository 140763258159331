import React from 'react'
import clsx from 'clsx'
import { Container, Grid, Paper } from '@material-ui/core';

import { useStyles } from '../Style/StyleAdmin';
import SuportePage from '../Page/SuportePage'
import FilterPage from '../Page/FilterPage'
import NoActivity from '../Page/NoActivity'
import Cart from '../../components/Cart'
import { useHistory } from 'react-router-dom';



function Suporte() {
  const classes = useStyles();
  const history = useHistory();

  const irResumo = () => {
    history.push('resumo');
    console.log('foi')
    
  };

  

  return(
    <div style={{background:'#fff', margin:10, }}> 

      <Grid container spacing={3}>
        {/* Product */}
        <Grid item xs={12} md={12} lg={12}>
        
            <SuportePage />

        </Grid>
 
      </Grid>

    </div>
  )
}

export default Suporte;
