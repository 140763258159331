import React, { Fragment, useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

//material ui

import {
  Stack,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Grid,
  TextField,
  Card,
  Typography,
  CircularProgress,
  Tab,
  Tabs,
  Box,
  Checkbox,
  FormControlLabel,
  Button,
  Container,
  Divider
} from '@mui/material';
import Alert from '@material-ui/lab/Alert';
//icons
import { FiArrowLeftCircle } from 'react-icons/fi';
//redux
import { useDispatch, useSelector } from "react-redux";
//actions
import Actions from "../../constants/Actions";
import buscaCep from "../../actions/buscaCep";
import criarCliente from '../../actions/cadastro/criar-cliente'
//services
import { getCepUser, getDataUsuario, getIsMaquininhaUser } from '../../services/auth'
//utils
import { validaParcelas, getValorTotal, mascararTel, soNumeros, maskCPF, maskDate, validarCPF, phoneMask, getValorSubTotal } from '../../utils/functions';
//components
import Stepper from "../../components/Stepper";
import CartResumo from '../../components/CartResumo';
// actions novo
import criarPedido from '../../actions/pedidos/criar-pedido';
import buscarClientePorCpfLinx from '../../actions/cadastro/buscar-cliente-por-cpf-linx';
import consultarClientePorCpf from '../../actions/cadastro/consultar-cliente-cpf';
import editarCliente from '../../actions/cadastro/editar-cliente';
import actionLoginMoovpay from '../../actions/moovpay/actionLoginMoovpay';
import CheckoutShipping from '../../sections/checkout/CheckoutShipping';

const moment = require('moment');

const ItemPaymentType = ({ title, onClick, background, disabled }) => {
  return (
    <Card sx={{
      borderRadius: 1,
      p: 2,
      boxShadow: 1,
      background: background
    }}

    >
    <Button
    disabled={disabled}
    onClick={onClick}
    >
 
      <Typography>{title}</Typography>
 
    </Button>
    </Card>
  )

}

const ResumoPage = ({ proximoPasso }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [cpf, setCpf] = useState('');
  const [nomeCompleto, setNomeCompleto] = useState('');
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [telefone, setTelefone] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [rua, setRua] = useState('');
  const [numero, setNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [tipoPagamento, setTipoPagamento] = useState('');
  const [transportadora, setTransportadora] = useState('');
  const [cpfBuscado, setCpfBuscado] = React.useState('Inicial');
  const [parcelaSelecionada, setParcelaSelecionada] = useState('1');
  const valorFrete = useSelector(state => state.valorFrete);
  const [valorAjuste, setValorAjuste] = React.useState(0);
  const valorDesconto = useSelector(state => state.valorDesconto);
  const cartItems = useSelector(state => state.cartItems);
  const [tipoEntrega, setTipoEntrega] = useState("Retirada")
  const shippingId = useSelector(state => state.shippingId);
  const paymentId = useSelector(state => state.paymentId);
  const parcelas = validaParcelas(getValorTotal(cartItems, valorFrete, valorDesconto, valorAjuste));
  const [value, setValue] = useState(0);
  const [clienteId, setClienteId] = useState('');


  // ederreço cobrnaça
  const [addressIsCob, setAddressIsCob] = useState(null);
  const [cepCob, setCepCob] = useState('');
  const [ruaCob, setRuaCob] = useState('');
  const [numeroCob, setNumeroCob] = useState('');
  const [complementoCob, setComplementoCob] = useState('');
  const [bairroCob, setBairroCob] = useState('');
  const [cidadeCob, setCidadeCob] = useState('');
  const [estadoCob, setEstadoCob] = useState('');


  const [loadingCriarPedido, setLoadingCriarPedido] = useState(false);
  const [isPesquisaCrm, setIsPesquisaCrm] = useState(false);
  const [dataCrm, setDataCrm] = useState(false);
  const [stepCrm, setSetpCrm] = useState(0)
  const [openModalCrm, setOpenModalCrm] = useState(false);

  const [valorFreteRetirada, setValorFreteRetirada] = useState(0);
  const [retiradaGratis, setRetiradaGratis] = useState(false);
  const [entregaBloqueada, setEntregaBloqueada] = useState(false);
  const [loginMoovpay, setLoginMoovpay] = useState(null);

  const valorTotalGeral = getValorTotal(cartItems, valorFrete, valorDesconto, valorAjuste);

  // useEffect(() => {
  //    if(cartItems.length === 0) {
  //     alert('Seu Carrinho está vazio, você será redirecionado para a página inicial')
  //     history.push('/');
  //    }
  // }, []);


  const voltarPdv = () => {
    history.push('frete')
  }

  // Inicial || Buscado || Invalido

  const handleChange = (event, newValue) => {
    setTipoPagamento("");
    setValue(newValue);
  };

  const handleChangeAddressIsCob = (event) => {
    setAddressIsCob(event.target.checked);
    setBairroCob(bairro);
    setRuaCob(rua);
    setEstadoCob(estado);
    setCepCob(cep);
    setNumeroCob(numero);
    setCidadeCob(cidade);
    setComplementoCob(complemento);

  };

  const notifyError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const notifyWarning = (message) => {
    toast.warning(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleRetirada = () => {
    if (retiradaGratis) {
      setValorFreteRetirada(0);
      dispatch({ type: Actions.TIPO_ENTREGA, tipoEntrega: "Retirada" });
      setTipoEntrega('Retirada');
      setTransportadora('')
      return;
    }
    if (getCepUser() >= 35500000 & getCepUser() <= 35505000) {
      setValorFreteRetirada(0)
    } else {
      if (getValorSubTotal(cartItems) < 149) {
        setValorFreteRetirada(20)
      } else {
        setValorFreteRetirada(0)
      }
    }
    dispatch({ type: Actions.TIPO_ENTREGA, tipoEntrega: "Retirada" });
    dispatch({ type: Actions.SHIPPING_ID, shippingId: "61560325eaf72f4fe7f5ee27" });

    setTipoEntrega('Retirada');
    setTransportadora('')
  }

  const handleEntrega = () => {
    setTipoEntrega('Entrega');
    dispatch({ type: Actions.TIPO_ENTREGA, tipoEntrega: "Entrega" });
    setTransportadora('')
  }



  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const limparCampos = () => {
    setNomeCompleto("");
    setTelefone("");
    setBairro("");
    setRua("");
    setEstado("");
    setCep("");
    setNumero("");
    setCidade("");
    setComplemento("");
    setEmail("");
  }

  const limparCamposCob = () => {
    setBairroCob("");
    setRuaCob("");
    setEstadoCob("");
    setCepCob("");
    setNumeroCob("");
    setCidadeCob("");
    setComplementoCob("");
  }

  const handleBuscaCep = async (zipCode) => {
    try {
      const address = await buscaCep(zipCode)
      if (address.erro) {
        alert('CEP ano encontrado!')
      } else {
        setCidade(address.localidade);
        setEstado(address.uf);
        return address;
      }
    } catch (address) {
      alert('CEP ano encontrado!');
    }
  }



  const buscarCpfMongo = () => {
    limparCampos();

    if (cpf.length === 11) {
      setCpfBuscado('Buscando');

      if (validarCPF(cpf) === true) {
        consultarClientePorCpf(soNumeros(cpf)).then(res => {
          if (res.data.isCliente) {
            // if(res.data.data.phone) {
            //      setOpenModalCrm(true)
            //  }
            setClienteId(res.data.data._id);
            setCpfBuscado('Buscado');
            setNome(res.data.data.first_name);
            setSobrenome(res.data.data.last_name);
            setTelefone(res.data.data.phone);
            setDataNascimento(res.data.data.data_nascimento);
            setBairro(res.data.data.endereco_entrega[0].bairro);
            setRua(res.data.data.endereco_entrega[0].logradouro);
            setEstado(res.data.data.endereco_entrega[0].uf);
            setCep(res.data.data.endereco_entrega[0].cep);
            setNumero(res.data.data.endereco_entrega[0].numero);
            setCidade(res.data.data.endereco_entrega[0].localidade);
            setComplemento(res.data.data.endereco_entrega[0].complemento);
            setEmail(res.data.data.email);
            console.log('res.data', res.data.data.phone)

          } else {
            notifyWarning("Esse CPF não possui cadastro no Venda Fácil, buscando em linx....");
            buscarCpfLinx();
            setClienteId('');
          }
        }).catch(err => {
          console.log(err);
          setCpfBuscado('Buscado');
          notifyError("Não foi possível estabelecer conexão com Venda Fácil");
        });
      } else {
        setCpfBuscado('Inicial');
        notifyError("CPF Digitado é inválido");
      }



    }
  }



  const buscarCpfLinx = () => {
    limparCampos();
    if (cpf.length === 11) {
      setCpfBuscado('Buscando');
      setTimeout(() => {
        if (!cpfBuscado === 'Buscado')
          setCpfBuscado('Buscado');
      }, 10000);

      if (validarCPF(cpf) === true) {
        buscarClientePorCpfLinx(soNumeros(cpf)).then(res => {
          if (res.data.user) {
            console.log('teste', res.data.user.ANIVERSARIO)

            setCpfBuscado('Buscado');
            setNomeCompleto(res.data.user.CLIENTE_VAREJO);
            setTelefone(res.data.user.DDD_CELULAR + res.data.user.CELULAR);

            setDataNascimento(moment((new Date(res.data.user.ANIVERSARIO)).toLocaleString('en-US', { timeZone: 'Europe/Berlin' })).format("DD/MM/YYYY"));
            setBairro(res.data.user.BAIRRO);
            setRua(res.data.user.ENDERECO);
            setEstado(res.data.user.UF);
            setCep(res.data.user.CEP);
            setNumero(res.data.user.NUMERO);
            setCidade(res.data.user.CIDADE);
            setComplemento(res.data.user.COMPLEMENTO);
            setEmail("");
            dispatch({ type: Actions.SHIPPING_ID, shippingId: null });

          } else {
            notifyError("Esse CPF não possui cadastro, confira se foi inserido corretamente.");
            setCpfBuscado('Buscado');
          }
        }).catch(err => {
          console.log(err);
          setCpfBuscado('Buscado');
          notifyError("Não foi possível estabelecer conexão com Linx");
        });

      } else {
        setCpfBuscado('Inicial');
        notifyError("CPF Digitado é inválido");
      }



    }
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const onStartPedido = async (id) => {

    try {
      let statusId = "60774fa684e6861cb41a472f";
      let shippingPrice = valorFrete;
      let items = cartItems;
      let parcelas = parcelaSelecionada;
      let desconto = valorDesconto;
      let responseZoop = null;
      let dadosOrigemPedido = {
        cpf: getDataUsuario().cpf,
        filial: getDataUsuario().filial,
        nome: getDataUsuario().nome,
        sobrenome: getDataUsuario().sobrenome
      }

      let personalData = {
        full_name: nome + " " + sobrenome,
        nome: nome,
        sobrenome: sobrenome,
        mobile: telefone,
        cpf: soNumeros(cpf),
        email: email ? email : ""
      }

      let shippingAddress = {
        full_name: nomeCompleto,
        address1: rua,
        address2: bairro,
        city: cidade,
        country: "Brazil",
        postal_code: cep,
        state: estado,
        phone: telefone,
        number: numero,
        company: "",
        tax_number: "",
        coordinates: {
          latitude: "",
          longitude: ""
        },
        details: complemento

      }

      let billingAddress = {

        full_name: nomeCompleto,
        address1: rua,
        address2: bairro,
        city: cidade,
        country: "Brazil",
        postal_code: cep,
        state: estado,
        phone: telefone,
        number: numero,
        company: "",
        tax_number: "",
        coordinates: {
          latitude: "",
          longitude: ""
        },
        details: complemento,
      }

      const responsePedido = await criarPedido({ customerId: id, desconto, billingAddress, shippingAddress, personalData, items, shippingPrice, statusId, tipoEntrega, shippingId, paymentId, parcelas, responseZoop, dadosOrigemPedido })
      setLoadingCriarPedido(false)

      
      if (responsePedido) {
        setLoadingCriarPedido(false)
        dispatch({ type: Actions.ORDER_NUMBER, orderNumber: responsePedido.data.number });
        dispatch({ type: Actions.MEU_CARRINHO, cartItems: [] });
        history.push(`/pdv/conclusao?order=${responsePedido.data.number}`);
      }

    } catch (error) {
      setLoadingCriarPedido(false);
      alert('Erro ao Criar Pedido, tente novamente. Caso o erro persista, entre em contato com o suporte');
      console.log('error', error)
    }

  }

  const onCriarCliente = async () => {
    let data = {
      email,
      phone: telefone,
      nome,
      sobrenome,
      password: null,
      data_nascimento: dataNascimento,
      cpf: cpf,
      endereco_entrega: [{
        logradouro: rua,
        numero: numero,
        bairro: bairro,
        localidade: cidade,
        cep: cep,
        uf: estado,
        complemento: complemento
      }],
      endereco_cobranca: [{
        logradouro: ruaCob,
        numero: numeroCob,
        bairro: bairroCob,
        localidade: cidadeCob,
        cep: cepCob,
        uf: estadoCob,
        complemento: complementoCob
      }]
    }

    try {
      const res = await criarCliente(data);
      await onStartPedido(res.data.data._id);
      setClienteId(res.data.data._id);
      console.log('Resultado cadstro de cliente', res.data)
    } catch (error) {
      notifyError("Erro ao cadastrar no Venda Fácil");
    }

  }

  const onEditarCliente = async () => {
    let data = {
      email,
      phone: telefone,
      nome,
      sobrenome,
      password: null,
      data_nascimento: dataNascimento,
      cpf: cpf,
      endereco_entrega: [{
        logradouro: rua,
        numero: numero,
        bairro: bairro,
        localidade: cidade,
        cep: cep,
        uf: estado,
        complemento: complemento
      }],
      endereco_cobranca: [{
        logradouro: ruaCob,
        numero: numeroCob,
        bairro: bairroCob,
        localidade: cidadeCob,
        cep: cepCob,
        uf: estadoCob,
        complemento: complementoCob
      }]
    }

    try {
      const res = await editarCliente(clienteId, data);
    } catch (error) {
      notifyError("Erro ao cadastrar no Venda Fácil");
    }

  }

  const fazerPedido = async () => {
    setLoadingCriarPedido(true)
    if (clienteId) {
      try {
        await onEditarCliente();
        await onStartPedido(clienteId);

      } catch (error) {
        alert('Erro ao cadastrar cliente');
        console.log('erro ao cadastar cliente')
      }
    } else {

      try {
        await onCriarCliente();
      } catch (error) {
        alert('Erro ao cadastrar cliente');
        console.log('erro ao cadastar cliente')
      }
    }
  }




  const handleChangeParcelas = (event) => {
    setParcelaSelecionada(event.target.value);
  };


  const handleCartaoCredito = () => {
    setTipoPagamento('Cartão Crédito')
    setParcelaSelecionada("1");
    dispatch({ type: Actions.PAYMENT_ID, paymentId: "6168e68dbec438dc351a3065" });
  }

  const handleCartaoDebito = () => {
    setTipoPagamento('Cartão Débito');
    setParcelaSelecionada("1");
    dispatch({ type: Actions.PAYMENT_ID, paymentId: "6168e69ebec438dc351a3066" });
  }

  const handlePixCheckout = () => {
    setTipoPagamento('Pix Checkout');
    setParcelaSelecionada("1");
    dispatch({ type: Actions.PAYMENT_ID, paymentId: "62c753914e8a07e77586dc82" });
  }

  const handleMoovpay = () => {
    setTipoPagamento('Moovpay');
    setParcelaSelecionada("1");
    dispatch({ type: Actions.PAYMENT_ID, paymentId: "65ef574e94335d158c264b32" });
    hanldeLoginMoovpay();
  }

  const handleLinkPagamento = () => {
    dispatch({ type: Actions.PAYMENT_ID, paymentId: "6168e6afbec438dc351a3067" });
    setTipoPagamento('Link Pagamento')
  }



  const handleEscolhePropria = () => {
    if (retiradaGratis) {
      setValorFreteRetirada(0);
      dispatch({ type: Actions.TIPO_ENTREGA, tipoEntrega: "Retirada" });
      setTipoEntrega('Retirada');
      setTransportadora('Total');
      dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
      dispatch({ type: Actions.SHIPPING_ID, shippingId: "61560325eaf72f4fe7f5ee27" });
      return;
    }
    setTransportadora('Total');
    dispatch({ type: Actions.SHIPPING_ID, shippingId: "61560325eaf72f4fe7f5ee27" });
    dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })

    if (tipoEntrega === "Retirada") {
      if (getCepUser() >= 35500000 & getCepUser() <= 35505000) {
        dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
      } else {
        if (getValorSubTotal(cartItems) < 149) {
          dispatch({ type: Actions.valorFrete, valorFrete: 20.00 })
        } else {
          dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
        }
      }
    }


    if (tipoEntrega === 'Entrega') {
      if (cep >= 35500000 & cep <= 35505000) {
        if (getValorSubTotal(cartItems) < 199) {
          dispatch({ type: Actions.valorFrete, valorFrete: 14.00 })
        } else {
          dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
        }

      } else {
        if (getValorSubTotal(cartItems) < 199) {
          dispatch({ type: Actions.valorFrete, valorFrete: 20.00 })
        } else {
          dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
        }
      }
    }
  }

  async function hanldeLoginMoovpay() {
       try {
        let data = {
          "scope": {
          "valor": valorTotalGeral,
          "cpf": cpf
          }
        }
        const response = await actionLoginMoovpay(data);
        if(response.status === 200) {
          setLoginMoovpay(response.data.data)
        }
       } catch (error) {
         alert('Erro ao autenticar no Moovpay entre em contato com o suporte')
       }
  }

  return (
    <>

      <Stack direction="column" spacing={3}>
        <Box sx={{ width: '100%', background: "#e5e5e5", py: 2 }}>
          <Container fullWidth maxWidth>
            <Stack justifyContent={"space-between"} direction={"row"}>
              <IconButton>
                <FiArrowLeftCircle onClick={voltarPdv} size={45} />
              </IconButton>
              <Stepper valorStep={2} />
            </Stack>
          </Container>
        </Box>

        <Container fullWidth maxWidth>
          <Stack direction={{xs:"column", sm:"column", md:"column", lg:"row"}} spacing={3}>
            <Box sx={{ width: {xs:"100%", sm:"100%", md:"100%", lg:"40%"} }}>
              <Card sx={{ p: 3 }}>
                <Stack direction={"column"}>
                  <Stack direction={"row"} spacing={2}>
                    <TextField
                      fullWidth
                      variant="filled"
                      id="standard-basic"
                      label="CPF"

                      size="small"
                      placeholder='Digite o CPF'
                      value={maskCPF(cpf)}
                      onChange={e => {
                        setCpf(soNumeros(e.target.value));
                      }}
                    />
                    {cpfBuscado === 'Buscando' ? (
                      <Grid item md>
                        <CircularProgress size={40} color="secondary" />
                      </Grid>

                    ) : (
                      <Grid item md>
                        <Button onClick={buscarCpfMongo} size='large' color='primary' variant='contained'>Pesquisar</Button>
                      </Grid>
                    )}
                  </Stack>


                  {cpfBuscado === 'Buscado' && (
                    <>
                      <Grid container style={{ marginTop: 20 }} spacing={1}>
                        <Grid item md={12}><Typography style={{ marginBottom: 15, marginTop: 15 }} variant="h6">Informações Básicas</Typography></Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextField
                            fullWidth
                            variant="filled"
                            id="standard-basic"
                            label="Nome"
                            value={nome}
                            size="small"
                            onChange={e => setNome(e.target.value)}
                          />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextField
                            fullWidth
                            variant="filled"
                            id="standard-basic"
                            label="Sobrenome"
                            value={sobrenome}
                            size="small"
                            onChange={e => setSobrenome(e.target.value)}
                          />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextField value={dataNascimento} size="small" onChange={e => setDataNascimento(maskDate(e.target.value))} fullWidth variant="filled" id="standard-basic" label="Data Nasc." />
                        </Grid>

                      </Grid>

                      <Grid container style={{ marginTop: 10 }} spacing={1}>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextField
                            fullWidth
                            variant="filled"
                            id="standard-basic"
                            label="Telefone"
                            value={phoneMask(telefone)}
                            size="small"
                            onChange={e => setTelefone(e.target.value)}
                          />
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextField size="small" value={email} onChange={e => setEmail(e.target.value)} fullWidth variant="filled" id="standard-basic" label="Email" />
                        </Grid>
                      </Grid>

                      <Grid container style={{ marginTop: 20 }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography style={{ marginBottom: 15, marginTop: 15 }} variant="h6">Endereço de Entrega</Typography>
                        </Grid>
                        <Grid item md={6}>
                          <TextField
                            fullWidth
                            variant="filled"

                            id="standard-basic"
                            label="CEP"
                            value={cep}
                            size="small"
                            onChange={e => setCep(e.target.value)}
                            onBlur={e => {
                              if (cep.length === 8) {
                                buscaCep(cep).then(res => {
                                  console.log('cep data', res)
                                  setBairro(res.bairro);
                                  setRua(res.logradouro);
                                  setEstado(res.uf);
                                  setCidade(res.localidade);
                                  setComplemento("");
                                  setNumero("");
                                }).catch(e => console.log(e))
                              }
                            }
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container style={{ marginTop: 20 }} spacing={2}>
                        <Grid item lg={6} md={12} sm={12} xs={12}>
                          <TextField
                            fullWidth
                            variant="filled"

                            id="standard-basic"
                            label="Logradouro"
                            value={rua}
                            size="small"
                            onChange={e => setRua(e.target.value)}
                          />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                          <TextField
                            fullWidth
                            variant="filled"

                            id="standard-basic"
                            label="Bairro"
                            value={bairro}
                            size="small"
                            onChange={e => setBairro(e.target.value)}
                          />
                        </Grid>
                        <Grid item md={4} sm={12} xs={12}>
                          <TextField
                            fullWidth

                            id="standard-basic"
                            label="Numero"
                            variant="filled"
                            value={numero}
                            size="small"
                            onChange={e => setNumero(e.target.value)}
                          />
                        </Grid>
                        <Grid item md={2} sm={8} xs={8}>
                          <TextField
                            fullWidth
                            variant="filled"

                            id="standard-basic"
                            label="Complemento"
                            value={complemento}
                            size="small"
                            onChange={e => setComplemento(e.target.value)}
                          />
                        </Grid>
                        <Grid item md={3} sm={8} xs={8}>
                          <TextField
                            fullWidth
                            variant="filled"

                            id="standard-basic"
                            label="Cidade"
                            value={cidade}
                            size="small"
                            onChange={e => setCidade(e.target.value)}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={4}>
                          <TextField
                            fullWidth
                            variant="filled"

                            id="standard-basic"
                            label="Estado"
                            size="small"
                            value={estado}
                            onChange={e => setEstado(e.target.value)}
                          />
                        </Grid>
                      </Grid>



                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={addressIsCob}
                            onChange={handleChangeAddressIsCob}
                            name="checkedB"
                            color="primary"
                          />
                        }
                        label="O Endereço de entrega é mesmo endereço de cobrança"
                      />

                      <Grid container style={{ marginTop: 20 }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography style={{ marginBottom: 15, marginTop: 15 }} variant="h6">Endereço de Cobrança</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} >
                          <TextField
                            fullWidth
                            variant="filled"
                            id="standard-basic"
                            label="CEP"
                            value={cepCob}
                            size="small"
                            onChange={e => setCepCob(e.target.value)}
                            onBlur={e => {
                              if (cepCob.length === 8) {
                                buscaCep(cepCob).then(res => {
                                  setBairroCob(res.bairro);
                                  setRuaCob(res.logradouro);
                                  setEstadoCob(res.uf);
                                  setCidadeCob(res.localidade);
                                  setComplementoCob("");
                                  setNumeroCob("");
                                }).catch(e => console.log(e))
                              }
                            }
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid container style={{ marginTop: 20 }} spacing={2}>
                        <Grid item md={6} sm={12} xs={12}>
                          <TextField
                            fullWidth
                            variant="filled"

                            id="standard-basic"
                            label="Logradouro"
                            value={ruaCob}
                            size="small"
                            onChange={e => setRuaCob(e.target.value)}
                          />
                        </Grid>
                        <Grid item md={4} sm={12} xs={12}>
                          <TextField
                            fullWidth
                            variant="filled"

                            id="standard-basic"
                            label="Bairro"
                            value={bairroCob}
                            size="small"
                            onChange={e => setBairroCob(e.target.value)}
                          />
                        </Grid>
                        <Grid item md={2} sm={4} xs={4}>
                          <TextField
                            fullWidth

                            id="standard-basic"
                            label="Numero"
                            variant="filled"
                            value={numeroCob}
                            size="small"
                            onChange={e => setNumeroCob(e.target.value)}
                          />
                        </Grid>
                        <Grid item md={2} sm={8} xs={8}>
                          <TextField
                            fullWidth
                            variant="filled"
                            id="standard-basic"
                            label="Complemento"
                            value={complementoCob}
                            size="small"
                            onChange={e => setComplementoCob(e.target.value)}
                          />
                        </Grid>
                        <Grid item md={3} sm={8} xs={8}>
                          <TextField
                            fullWidth
                            variant="filled"
                            id="standard-basic"
                            label="Cidade"
                            value={cidadeCob}
                            size="small"
                            onChange={e => setCidade(e.target.value)}
                          />
                        </Grid>
                        <Grid item md={3} sm={4} xs={4}>
                          <TextField
                            fullWidth
                            variant="filled"

                            id="standard-basic"
                            label="Estado"
                            size="small"
                            value={estadoCob}
                            onChange={e => setEstadoCob(e.target.value)}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Stack>
              </Card>

            </Box>

            <Box sx={{ width: {xs:"100%", sm:"100%", md:"100%", lg:"40%"} }}>
              <Stack spacing={1} direction={"column"}>
                {cpfBuscado === 'Buscado' && (
                  <Card sx={{ p: 2 }}>
                    <Typography variant='h6'>Forma de entrega</Typography>
                 
                    <Stack>
                  <CheckoutShipping />
                </Stack>
                  </Card>
                )}

             


                <Card>
                  
                  {shippingId && cpf !== "" && cep !== "" && rua !== "" && numero !== "" && telefone && nome !== "" && sobrenome !== ""  && (
                    <>
                      <Card sx={{ padding: 2 }}>
                        <Grid container>
                        <Divider />

                          <Grid md={12} item>
                            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                              <Tab label="Pagamento na Loja" {...a11yProps(0)} />
                              <Tab label="Pagamento Online" {...a11yProps(1)} />
                            </Tabs>
                            <TabPanel value={value} index={0}>
                              <Stack sx={{ p: 0 }} spacing={2} direction={"row"}>
                                <Stack direction={"row"} spacing={2}>
                                  {getIsMaquininhaUser() === "true" && (
                                    <ItemPaymentType
                                      title="Crédito"
                                      onClick={handleCartaoCredito}
                                      background={tipoPagamento == 'Cartão Crédito' ? '#b6ffca' : ''}
                                    />
                                  )}
                                  {getIsMaquininhaUser() === "false" && (
                                    <Alert severity="info">Usuário(a), sua loja não possui ainda a MAQUININHA FÍSICA, utilize o Link de Pagamento para vendas no Cartão de Crédito ou/ a opção PIX que esta aparecendo abaixo.</Alert>
                                  )}
                                </Stack>
                                <Stack>
                                  {getIsMaquininhaUser() === "true" && (
                                    <ItemPaymentType
                                      title="Débito"
                                      onClick={handleCartaoDebito}
                                      background={tipoPagamento == 'Cartão Débito' ? '#b6ffca' : ''}
                                    />
                                  )}
                                </Stack>
                                <ItemPaymentType
                                  title="Pix"
                                  onClick={handlePixCheckout}
                                  background={tipoPagamento == 'Pix Checkout' ? '#b6ffca' : ''}
                                />
                                
                                <ItemPaymentType
                                  title="MoovPay"
                                  onClick={handleMoovpay}
                                  background={tipoPagamento == 'Moovpay' ? '#b6ffca' : ''}
                                />

                              </Stack>
                            </TabPanel>

                            <TabPanel value={value} index={1}>
                              <ItemPaymentType
                                title="Link Pagamento"
                                onClick={handleLinkPagamento}
                                background={tipoPagamento == 'Link Pagamento' ? '#b6ffca' : ''}
                              />
                            </TabPanel>

                          </Grid>
                        </Grid>


                        {tipoPagamento === 'Cartão Crédito' && (
                          <Box sx={{ my: 4 }}>
                            {parcelas && (
                              <FormControl fullWidth>
                                <InputLabel>Selecione as parcelas</InputLabel>
                                <Select
                                  size="small"
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={parcelaSelecionada}
                                  onChange={handleChangeParcelas}
                                  label="Selecione as parcelas"
                                >
                                  {parcelas.parcelas.map((parcelas) => (
                                    <MenuItem key={parcelas.quantidade} value={parcelas.quantidade}>
                                      {parcelas.quantidade}x de R${parcelas.valor} sem juros
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </Box>
                        )}

                        {tipoPagamento === 'Moovpay' && (
                          <Box sx={{ my: 4 }}>
                            {loginMoovpay?.parcelamentos && (
                              <FormControl fullWidth>
                                <InputLabel>Selecione as parcelas</InputLabel>
                                <Select
                                  size="small"
                                  labelId="demo-simple-select-outlined-label"
                                  id="demo-simple-select-outlined"
                                  value={parcelaSelecionada}
                                  onChange={handleChangeParcelas}
                                  label="Selecione as parcelas"
                                >
                                    {loginMoovpay?.parcelamentos.map((parcelas) => (
                                    <MenuItem key={parcelas.QuantidadeParcelas} value={parcelas.QuantidadeParcelas}>
                                      {parcelas.QuantidadeParcelas}x de R${parcelas.ValorParcela} {parcelas.IndicadorJuros ? "com juros" : "sem juros"}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </Box>
                        )}

                        {tipoPagamento === 'Link Pagamento' && (
                          <Grid container style={{ marginBottom: 20 }}>
                            <Grid item md={12}>
                              <Alert severity="info"> Após a confirmação do pedido, será gerado um link de pagamento Katuxa Pay. Este link pode ser compatilhado com o cliente para que ele possa efetuar o pagamento de um modo seguro</Alert>
                            </Grid>
                          </Grid>
                        )}
                        {cpf !== "" && cep !== "" && tipoPagamento !== "" && rua !== "" && numero !== "" && telefone && nome !== "" && sobrenome !== ""  &&  (
                          <Button sx={{ mt: 3 }} disabled={loadingCriarPedido ? true : false} fullWidth variant='contained' color="primary" onClick={fazerPedido} > {loadingCriarPedido ? <CircularProgress /> : "CONTINUAR"}  </Button>
                        )}
                      </Card>
                    </>
                  )}
                </Card>
              </Stack>






            </Box>

            <Box sx={{ width: {xs:"100%", sm:"100%", md:"100%", lg:"20%"} }}>
              <Card style={{ paddding: 10 }}>
                <CartResumo />
              </Card>
            </Box>





          </Stack>
        </Container>
      </Stack>

    </>
  )
}

export default ResumoPage;
