import axios from "axios";
import Config from "../../config/config"

const actionVerificarPagamentoMoovpay = orderId => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.api_dev : Config.api_prod}/api/v1/apps/pdv/pedidos/moovpay/${orderId}/verificar-pagamento`
    });
};

export default actionVerificarPagamentoMoovpay;
 