import React from 'react'
import { Typography, Stack, Box } from '@mui/material';

const ItemCardOpcao = ({ selected, value, onClick }) => {
    return (
        <Box onClick={onClick} sx={{border:'1px solid #ccc', cursor:'pointer', background:selected, borderRadius:1, p:1}}>
          <Typography sx={{fontSize:12, fontWeight:'bold'}}>{value}</Typography>
        </Box>
    )
}

export default ItemCardOpcao;
