import axios from "axios";
import Config from "../config/config";

import download from 'downloadjs'






const emitirEtiqueta = data => {

  const zpl = `^XA

  ^FO10,45^GFA,4186,4186,23,,::::::::::::::::::N0gOF8,M07gPF8,L01gQFE,L07gRF,L0gSFC,K01gSFE,K03gSFE,K03gTF,K07gTF8,:K0gUF8,K0gUFC,:J01gUFC,::::::::J01gKF3OFC,J01gJFE0OFC,J01MF8I03RFC07NFC,J01MFJ01RF803NFC,J01MFJ01RFI0NFC,J01MFJ01QFEI07MFC,J01MFJ01QFEI01MFC,J01MFJ01QFCJ0MFC,J01MFJ01QF8J0MFC,J01MFJ01QFK0MFC,J01MFJ01PFEJ01MFC,J01MFJ01PFCJ03MFC,J01MFJ01PF8J07MFC,J01MFJ01PFK0NFC,J01MFJ01OFEJ01NFC,J01MFJ01OFEJ03NFC,J01MFJ01OFCJ03NFC,J01MFJ01OF8J07NFC,J01MFJ01OFK0OFC,J01MFJ01NFEJ01OFC,J01MFJ01NFCJ03OFC,J01MFJ01NF8J07OFC,J01MFJ01NFK0PFC,J01MFJ01NFJ01PFC,J01MFJ01MFEJ01PFC,J01MFJ01MFCJ03PFC,J01MFJ01MF8J07PFC,J01MFJ01MFK0QFC,J01MFJ01LFEJ01QFC,J01MFJ01LFCJ03QFC,J01MFJ01LF8J07QFC,J01MFJ01LF8J0RFC,J01MFJ01LFJ01RFC,J01MFJ01KFEJ03RFC,J01MFJ01KFCJ03RFC,J01MFJ01KF8J07RFC,J01MFJ01KFK0SFC,J01MFJ01JFEJ01SFC,J01MFJ01JFCJ03SFC,J01MFJ01JF8J07SFC,J01MFJ01JF8J0TFC,J01MFJ01JFJ01TFC,J01MFJ01IFEJ03TFC,J01MFJ01IFCJ03TFC,J01MFJ01IF8J07TFC,J01MFJ01IF8J0UFC,J01MFJ01IF8J07TFC,J01MFJ01IFCJ03TFC,J01MFJ01IFEJ01TFC,J01MFJ01JFJ01TFC,J01MFJ01JF8J0TFC,J01MFJ01JF8J07SFC,J01MFJ01JFCJ03SFC,J01MFJ01JFEJ01SFC,J01MFJ01KFK0SFC,:J01MFJ01KF8J07RFC,J01MFJ01KFCJ03RFC,J01MFJ01KFEJ01RFC,J01MFJ01LFK0RFC,J01MFJ01LF8J0RFC,J01MFJ01LF8J07QFC,J01MFJ01LFCJ03QFC,J01MFJ01LFEJ01QFC,J01MFJ01MFK0QFC,J01MFJ01MF8J07PFC,J01MFJ01MFCJ07PFC,J01MFJ01MFCJ03PFC,J01MFJ01MFEJ01PFC,J01MFJ01NFK0PFC,J01MFJ01NF8J07OFC,J01MFJ01NFCJ07OFC,J01MFJ01NFCJ03OFC,J01MFJ01NFEJ01OFC,J01MFJ01OFK0OFC,J01MFJ01OF8J07NFC,J01MFJ01OFCJ03NFC,:J01MFJ01OFEJ01NFC,J01MFJ01PFK0NFC,J01MFJ01PF8J07MFC,J01MFJ01PFCJ03MFC,J01MFJ01PFEJ03MFC,J01MFJ01PFEJ01MFC,J01MFJ01QFK0MFC,J01MFJ01QF8J07LFC,J01MFJ01QFCJ07LFC,J01MFJ01QFEJ03LFC,J01MFJ01QFEJ07LFC,J01MFJ01RFJ0MFC,J01MFJ01RF8001MFC,J01MFJ01RFC007MFC,J01MFJ01RFE00NFC,J01MFJ01RFE01NFC,J01MF8I03SF03NFC,J01gKF8OFC,J01gUFC,::::::::::::K0gUFC,:K0gUF8,K07gTF8,K07gTF,K03gTF,K03gSFE,K01gSFC,L0gSF8,L07gRF,L01gQFE,M07gPF,N07gNF8,,::::::::::::::::::^FS
  
  ^CFA,17
  ^FO210,75^FDREMETENTE:^FS
  ^FO210,100^FD${data.remetente.logradouro}^FS
  ^FO210,125^FD${data.remetente.numero} - ${data.remetente.complemento}^FS
  ^FO210,150^FD${data.remetente.bairro}^FS
  ^FO210,175^FD${data.remetente.cep}^FS
  ^FO210,195^FD${data.remetente.localidade}-${data.remetente.uf}^FS
  
  ^FO30,280^GB700,3,3^FS
  
  ^FO30,300^FD #${data.pedido.numero} ---> ${data.pedido.filial}^FS

  ^CFA,18
  ^FO30,320^FD^FS
  ^FO30,380^FD DESTINATARIO^FS
  

  ^CFA,32
  ^FO30,400^FD^FS
  ^FO30,410^FD${data.destinatario.nome_completo}^FS

  ^CFA,18
  ^FO30,560^FD^FS
  ^FO30,560^FD DADOS DE ENTREGA^FS
  ^CFA,32

  ^FO30,600^FD${data.destinatario.logradouro}^FS
  ^FO30,650^FDN:${data.destinatario.numero} - ${data.destinatario.complemento}^FS
  ^FO30,700^FD${data.destinatario.bairro}^FS
  ^FO30,780^FDCEP:${data.destinatario.cep}^FS
  ^FO30,830^FD${data.destinatario.localidade}^FS
  ^FO30,880^FD${data.destinatario.uf} - BR^FS
  ^FO30,10000^GB700,3,3^FS
  
  ^XZ`;

  const bodyFormData = new FormData();
bodyFormData.append('file', zpl);

const config = {
  method: 'post',
  url: 'https://api.labelary.com/v1/printers/8dpmm/labels/4x6/0/',
  headers: { 
    'Accept': 'application/pdf', 
    //'Content-Type': 'multipart/form-data; charset=utf-8',

  },
  responseType:'blob',
  data: bodyFormData
};
 
    return axios(config)
    .then(response => {
      const content = response.headers['content-type'];
      download(response.data, "Teste", content)
   })
   .catch(error => console.log(error));
};

export default emitirEtiqueta;



// const zpl = `^XA

// ^FO30,65^GFA,4186,4186,23,,::::::::::::::::::N0gOF8,M07gPF8,L01gQFE,L07gRF,L0gSFC,K01gSFE,K03gSFE,K03gTF,K07gTF8,:K0gUF8,K0gUFC,:J01gUFC,::::::::J01gKF3OFC,J01gJFE0OFC,J01MF8I03RFC07NFC,J01MFJ01RF803NFC,J01MFJ01RFI0NFC,J01MFJ01QFEI07MFC,J01MFJ01QFEI01MFC,J01MFJ01QFCJ0MFC,J01MFJ01QF8J0MFC,J01MFJ01QFK0MFC,J01MFJ01PFEJ01MFC,J01MFJ01PFCJ03MFC,J01MFJ01PF8J07MFC,J01MFJ01PFK0NFC,J01MFJ01OFEJ01NFC,J01MFJ01OFEJ03NFC,J01MFJ01OFCJ03NFC,J01MFJ01OF8J07NFC,J01MFJ01OFK0OFC,J01MFJ01NFEJ01OFC,J01MFJ01NFCJ03OFC,J01MFJ01NF8J07OFC,J01MFJ01NFK0PFC,J01MFJ01NFJ01PFC,J01MFJ01MFEJ01PFC,J01MFJ01MFCJ03PFC,J01MFJ01MF8J07PFC,J01MFJ01MFK0QFC,J01MFJ01LFEJ01QFC,J01MFJ01LFCJ03QFC,J01MFJ01LF8J07QFC,J01MFJ01LF8J0RFC,J01MFJ01LFJ01RFC,J01MFJ01KFEJ03RFC,J01MFJ01KFCJ03RFC,J01MFJ01KF8J07RFC,J01MFJ01KFK0SFC,J01MFJ01JFEJ01SFC,J01MFJ01JFCJ03SFC,J01MFJ01JF8J07SFC,J01MFJ01JF8J0TFC,J01MFJ01JFJ01TFC,J01MFJ01IFEJ03TFC,J01MFJ01IFCJ03TFC,J01MFJ01IF8J07TFC,J01MFJ01IF8J0UFC,J01MFJ01IF8J07TFC,J01MFJ01IFCJ03TFC,J01MFJ01IFEJ01TFC,J01MFJ01JFJ01TFC,J01MFJ01JF8J0TFC,J01MFJ01JF8J07SFC,J01MFJ01JFCJ03SFC,J01MFJ01JFEJ01SFC,J01MFJ01KFK0SFC,:J01MFJ01KF8J07RFC,J01MFJ01KFCJ03RFC,J01MFJ01KFEJ01RFC,J01MFJ01LFK0RFC,J01MFJ01LF8J0RFC,J01MFJ01LF8J07QFC,J01MFJ01LFCJ03QFC,J01MFJ01LFEJ01QFC,J01MFJ01MFK0QFC,J01MFJ01MF8J07PFC,J01MFJ01MFCJ07PFC,J01MFJ01MFCJ03PFC,J01MFJ01MFEJ01PFC,J01MFJ01NFK0PFC,J01MFJ01NF8J07OFC,J01MFJ01NFCJ07OFC,J01MFJ01NFCJ03OFC,J01MFJ01NFEJ01OFC,J01MFJ01OFK0OFC,J01MFJ01OF8J07NFC,J01MFJ01OFCJ03NFC,:J01MFJ01OFEJ01NFC,J01MFJ01PFK0NFC,J01MFJ01PF8J07MFC,J01MFJ01PFCJ03MFC,J01MFJ01PFEJ03MFC,J01MFJ01PFEJ01MFC,J01MFJ01QFK0MFC,J01MFJ01QF8J07LFC,J01MFJ01QFCJ07LFC,J01MFJ01QFEJ03LFC,J01MFJ01QFEJ07LFC,J01MFJ01RFJ0MFC,J01MFJ01RF8001MFC,J01MFJ01RFC007MFC,J01MFJ01RFE00NFC,J01MFJ01RFE01NFC,J01MF8I03SF03NFC,J01gKF8OFC,J01gUFC,::::::::::::K0gUFC,:K0gUF8,K07gTF8,K07gTF,K03gTF,K03gSFE,K01gSFC,L0gSF8,L07gRF,L01gQFE,M07gPF,N07gNF8,,::::::::::::::::::^FS

// ^FX Top section with logo, name and address.
// ^CF0,55
// ^FO215,70^FDKatuxa Calcados^FS
// ^CFA,30
// ^FO220,120^FDRua ${data.remetente.logradouro}^FS
// ^FO220,145^FD${data.remetente.numero}, ${data.remetente.complemento}^FS
// ^FO220,170^FD${data.remetente.bairro} - ${data.remetente.cep}^FS
// ^FO220,195^FD${data.remetente.localidade}-${data.remetente.uf}^FS
// ^FO220,220^FDBrasil^FS
// ^FO50,280^GB700,3,3^FS

// ^FX Second section with recipient address and permit information.
// ^CFA,18
// ^FO50,320^FD^FS
// ^FO50,380^FD DESTINATARIO^FS


// ^FX Second section with recipient address and permit information.
// ^CFA,32
// ^FO50,420^FD^FS
// ^FO50,450^FD${data.destinatario.nome_completo.trim()}^FS
// ^FO50,500^FD${data.destinatario.logradouro.trim()}^FS
// ^FO50,550^FD${data.destinatario.numero.trim()}^FS
// ^FO50,650^FD ${data.destinatario.bairro.trim()}^FS
// ^FO50,700^FD${data.destinatario.cep.trim()}^FS
// ^FO50,750^FD${data.destinatario.localidade.trim()}^FS
// ^FO50,800^FDBrasil^FS
// ^FO50,9000^GB700,3,3^FS

// ^CFA,30
// ^FO300,900^GB250,200,3^FS
// ^FO370,950^FDPedido^FS
// ^FO370,1010^FD${data.pedido.numero}^FS


// ^XZ`;
