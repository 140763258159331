export const TOKEN_KEY = "dataUser";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const dataUser = () => localStorage.getItem(TOKEN_KEY);
export const dataUsuario = () => localStorage.getItem("dataUsuario");

export const getCepUser = () => {
    if (dataUser() !== null) {
        console.log('teste de dataUser', dataUser());
        console.log('teste de dataUser 2', JSON.parse(dataUser()))
        const {cep} = JSON.parse(dataUser());
        console.log('get sesion', cep);
        return cep;
    }
};

// export const login = token => {
//     localStorage.setItem(TOKEN_KEY, token);
// };

// export const cepUser = () => {
//     localStorage.setItem(TOKEN_KEY, token);
// };

// export const logout = () => {
//     localStorage.removeItem(TOKEN_KEY);
// };

// export const getSession = () => {
//     if (defaultKey() !== null) {
//         const {session} = JSON.parse(dataUser());
//         console.log('get sesion', session)
//         return session;
//     }
// };

export const getDataUser = () => {
    if (dataUser() !== null) {
        const { codigo_tab_preco } =  JSON.parse(dataUser());
        console.log('codigo_tab_preco', codigo_tab_preco)
        return codigo_tab_preco;
    }
};

export const getTabPreco = () => {
    if (dataUser() !== null) {
        const { codigo_tab_preco } =  JSON.parse(dataUser());
        console.log('codigo_tab_preco1', codigo_tab_preco)
       return codigo_tab_preco;
    }
};

export const getFilialUser = () => {
    if (dataUser() !== null) {
        const {filial} = JSON.parse(dataUser());
        console.log('get filial', filial);
        return filial;
    }
};
export const getIsMaquininhaUser = () => {
    if (dataUser() !== null) {
        const {isMaquininha} = JSON.parse(dataUser());
        return isMaquininha;
    }
};

export const getDataUsuario = () => {
    if (dataUsuario() !== null) {
        const {nome, sobrenome, filial, cpf, codigo, isMaquininha} = JSON.parse(dataUsuario());
        return {nome, sobrenome, filial, cpf, codigo, isMaquininha};
    }
};


export const getDefaultImage = () => {
    return "https://www.promoview.com.br/uploads/images/unnamed%2819%29.png";
};