import axios from "axios";

const actionIsConfereEstoqueLoja = () => {
    return axios({
        method: "get",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/config/is-confere-estoque-loja"
    });
};

export default actionIsConfereEstoqueLoja;
