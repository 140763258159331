import axios from "axios";
import Config from "../../config/config";

const actionPesquisarProdutos = data => {
    return axios({
        method: "post",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/produtos/pesquisar",
        data
    });
};

export default actionPesquisarProdutos;
