import React, { Fragment, useState, useEffect, useRef } from 'react'

import { useSelector } from 'react-redux'
import { FiCopy } from 'react-icons/fi';
import { useHistory } from "react-router-dom";
// copy to clipboard
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Icon } from '@iconify/react';
import CloseIcon from '@material-ui/icons/Close';
import copyFill from '@iconify/icons-eva/copy-fill';
// import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Grid,
  Card,
  Typography,
  Box,
  Divider,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  InputAdornment,
  IconButton,
  Tooltip,
  Hidden,
  Container,
  AppBar,
  Toolbar,
  LinearProgress

} from "@mui/material";

import { Alert } from '@material-ui/lab'
//qrcode
import QRCode from 'react-qr-code'

//utils
import { getValorTotal, getValorTotal2, getValorSubTotal, soNumeros } from '../../utils/functions';

import splitZoop from '../../actions/zoop/splitZoop';
import transactionZoop from '../../actions/zoop/transactionZoop';
// import getDataFilial from '../../actions/zoop/getDataFilial';
import editAfterSplit from '../../actions/zoop/editAfterSplit';
import afterSaleZoop from '../../actions/zoop/afterSaleZoop';
//images 
import Happy from '../../images/happy-01.png';
// actions
import buscarFilialPorCodigoFilial from '../../actions/cadastro/buscar-filial-por-codigo-filial';
import getPedidoByNumber from '../../actions/pedidos/pedido-by-number'
import { useLocation } from 'react-router-dom';
import PaymentModalMoovpay from '../../sections/payment/PaymentModalMoovpay';
import actionLoginMoovpay from '../../actions/moovpay/actionLoginMoovpay';
import actionGerarQrcodeMoovpay from '../../actions/moovpay/actionGerarQrcodeMoovpay';
import actionVerificarPagamentoMoovpay from '../../actions/moovpay/actionVerificarPagamentoMoovpay';


const ConclusaoPage = () => {
  const history = useHistory();
  const [infoPedido, setInfoPedido] = useState('');
  const [items, setItems] = useState([]);
  const [numeroWhatsapp, setNumeroWhatsapp] = useState('');
  const [dddWhatsapp, setDddWhatsapp] = useState('');
  const [idWhatsapp, setIdWhatsapp] = useState('');
  const [copiedLink, setCopiedLink] = useState('');
  const [dialogResultPayment, setDialogResultPayment] = useState(false);
  const [copiedBoleto, setCopiedBoleto] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [dadosFilial, setDadosFilial] = useState('');
  const [alertTransaction, setAlertTransaction] = useState('');
  const [dataAlertTransactionError, setDataAlertTransactionError] = useState('');
  const [dataResultPix, setDataResultPix] = useState('');
  const orderNumber = useSelector(state => state.orderNumber);
  //const orderNumber = '10903';
  const [dialogEnviarWhatsapp, setDialogEnviarWhatsapp] = useState(false);
  const location = useLocation();
  // Extrai o parâmetro de consulta "channel" da string de busca (search) da URL
  const params = new URLSearchParams(location.search);
  const order = params.get('order');

  const [qrcodeMoovpay, setQrcodeMoovpay] = useState(null);
  const [openModalMoovpay, setOpenModalMoovpay] = useState(false);

  const intervalIdRef = useRef(null);



  const handleClickCloseModalEnviarWhatsapp = () => {
    setDialogEnviarWhatsapp(false);
  };

  const BoxDescriptionItem = ({ label, value }) => {
    return (
      <Box style={{ flexDirection: 'column', background: "#f0f0f0", borderRadius: 5, padding: 10 }}>
        <Typography variant='caption'>{label}</Typography>
        <Typography variant='h6'>{value}</Typography>
      </Box>
    );
  }

  const onCopy = () => {
    setCopiedLink(true);

  };

  const onCopyPix = () => {
    setCopiedBoleto(true);
    // enqueueSnackbar('Copiado código Pix', { variant: 'success' });

  };

  const handleChangeCopyBoleto = (event) => {
    setCopiedBoleto(false);
  };



  const handleChangeCopyLink = () => {
    setCopiedLink(false);
  };

  async function handleVerificarPagamentoMoovpay() {
    try {

      const response = await actionVerificarPagamentoMoovpay(infoPedido?._id);
      if (response.status === 200) {
        console.log('response', response.data)
        if (response.data.response === "ok") {
          setTimeout(() => {
            window.location.href = "/pdv/conclusao?order="+infoPedido.number;
          }, 5000);
        } 
      }

    } catch (error) {
      console.log('error', error)
    }
  }



  const handleCobrarMoovpay = async () => {

    try {
      let data = {
        "scope": {
          "valor": Math.round(getValorTotal2(infoPedido)),
          "orderNumber": infoPedido.number
        }

      }
      const response = await actionGerarQrcodeMoovpay(data);
      if (response.status === 200) {
        setQrcodeMoovpay(response.data.response);

        intervalIdRef.current = setInterval(() => {
          handleVerificarPagamentoMoovpay()
                }, 10000);
      }
    } catch (error) {

    }

  }

  const cobrarPix = async () => {
    setLoadingButton(true);

    try {
      const res = await transactionZoop({
        //on_behalf_of:dadosFilial.sellerZoop,
        on_behalf_of: "4e712d55199d4429bca7d59fc8b4939c",
        payment_type: "pix",
        description: `${infoPedido.number}_P`,
        currency: "BRL",
        amount: Math.round(getValorTotal2(infoPedido) * 100)
      })

      afterSaleZoop({
        number: infoPedido.number,
        responseZoop: res.data,
        status_id: "60774fa684e6861cb41a472f",
        isPaid: false
      }).catch(error => console.log(error));

      if (res.data.status === "pending") {
        setDataResultPix(res.data);
        setDialogResultPayment(true);

        let dataSplit = {
          id: res.data.id,
          sellerId: dadosFilial.sellerZoop,
          valorInCents: Math.round(getValorTotal2(infoPedido) * 100)
        }

        splitZoop(dataSplit).then(res => {
          console.log('Split, deu certo', res.data)
          editAfterSplit({ number: infoPedido.number, isSplitSuccess: true }).then(() => { }).catch(() => console.log('Erro ao gravar isSplitSuccess como Ok o split'));
        }).catch(err => {
          console.log('Split, deu errado', err)
          editAfterSplit({ number: infoPedido.number, isSplitSuccess: false }).then(() => { }).catch(() => console.log('Erro ao gravar isSplitSuccess como Erro o split'));
        });

      }

    } catch (e) {
      console.log('erro', e);
      if (e.response) {
        console.log('error repsonse', e.response.data.error);

        setAlertTransaction('failed')
        setLoadingButton(false);
        // scrollToBottom();
        setTimeout(() => {
          setAlertTransaction('');

        }, 15000);
      } else {
        setAlertTransaction('failed', e.response)
        setDataAlertTransactionError("Verifique os dados do Cartão e tente novamente, 1");
        setLoadingButton(false);
        //scrollToBottom();
        setTimeout(() => {
          setAlertTransaction('');
        }, 15000);
      }
    }


  }



  const voltarPdv = () => {
    history.push('frete')
  }

  function validaStatus(string) {
    if (string === "60774fa684e6861cb41a472f") {
      return "Aguardando Pagamento";
    } else if (string === "6143439585ee0369a5dfacbc") {
      return "Cancelado";
    } else if (string === "60774f9c84e6861cb41a472e") {
      return "Pedido Realizado";
    } else if (string === "6143412285ee0369a5dfacb4") {
      return "Pedido Aceito";
    } else if (string === "60774fb684e6861cb41a4730") {
      return "Pagamento Aprovado";
    } else if (string === "6143414c85ee0369a5dfacb5") {
      return "Expedição";
    }

  }

  function validaEntrega(string) {
    if (string === "61532d990d253dd8a450bcde") {
      return "Correios - Pac";
    } else if (string === "615602fdeaf72f4fe7f5ee24") {
      return "Correios - Sedex";
    } else if (string === "61560313eaf72f4fe7f5ee25") {
      return "Patrus";
    } else if (string === "6156031beaf72f4fe7f5ee26") {
      return "Retirada em Loja";
    } else if (string === "61560325eaf72f4fe7f5ee27") {
      return "Pagamento TotalExpress";
    }
  }

  function validaPagamento(string) {
    if (string === "6168e68dbec438dc351a3065") {
      return "Cartão Crédito - Zoop";
    } else if (string === "6168e69ebec438dc351a3066") {
      return "Cartão Débito - Zoop";
    } else if (string === "6168e6afbec438dc351a3067") {
      return "Link Pagamento";
    } else if (string === "6168e6b7bec438dc351a3068") {
      return "Dinheiro";
    } else if (string === "62c753914e8a07e77586dc82") {
      return "Pix Checkout";
    } else if (string === "65ef574e94335d158c264b32") {
      return "Moovpay";
    }

  }

  const abrirLinkNovaAba = id => {
    let url = `https://pag.vkholding.com.br/pedido/${id}`;
    window.open(url, '_blank');
  }


  const abrirTerminaldePagamentos = () => {
    let url = `https://pagamento.katuxa.com.br`;
    window.open(url, '_blank');
  }


  const abrirModalWhatsappWeb = (phone, id) => {
    setIdWhatsapp(id);
    setDialogEnviarWhatsapp(true);
    //let url = `https://api.whatsapp.com/send?phone=${phone}&text=Aqui está o link de pagamento \n  https://pag.katuxa.com.br/payment/${id}`;
    //window.open(url, '_blank');
  }

  const abrirWhatsappWeb = () => {
    let whatsapp = dddWhatsapp + numeroWhatsapp;
    let url = `https://api.whatsapp.com/send?phone=55${whatsapp}&text=Aqui está o link de pagamento \n  https://pag.vkholding.com.br/pedido/${idWhatsapp}`;
    window.open(url, '_blank');
  }

  useEffect(() => {
    // Cleanup interval on component unmount
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  const ResultPix = ({ dataResultPix }) => {
    return (
      <Grid maxWidth={"md"} item >
        <Container style={{ textAlign: 'center', alignContent: 'center' }}>
          <Typography variant="h6" paragraph>
            Escaneie o QRCode
          </Typography>
          <Grid item>
            <QRCode value={dataResultPix.payment_method.qr_code.emv} />
          </Grid>

          <Typography style={{ marginTop: 20 }} variant="h3" paragraph>
            ou
          </Typography>

          <Typography variant="h6" paragraph>
            copie o código para pagamento em sua Instituição Financeira
          </Typography>
          {dataResultPix && (
            <Grid style={{ marginTop: 20, marginBottom: 20 }} item md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                disabled
                value={dataResultPix.payment_method.qr_code.emv}
                onChange={handleChangeCopyBoleto}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CopyToClipboard text={dataResultPix.payment_method.qr_code.emv} onCopy={onCopyPix}>
                        <Tooltip title="Copiar Pix Copia e Cola">
                          <IconButton>
                            <Icon icon={copyFill} width={24} height={24} />
                          </IconButton>
                        </Tooltip>
                      </CopyToClipboard>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          )}
          <Alert style={{ marginTop: 2 }} severity="warning">Efetue o pagamento em até 5 minutos, após esse tempo, o Pix não poderá ser pago.</Alert>
          <Grid container spacing={3} justifyContent="center" alignContent="center" alignItems="center" style={{ marginTop: 20 }}>
          </Grid>
        </Container>
      </Grid>
    );
  }



  useEffect(() => {

    getPedidoByNumber(order).then(res => {
      console.log('res', res.data)
      setInfoPedido(res.data)
      setItems(res.data.items);
      buscarFilialPorCodigoFilial(res.data.items[0].filial).then(dataFilial => {
        setDadosFilial(dataFilial.data);
      }).catch(err => console.log('err', err))


    }).catch(err => {
      //window.location.href = "/pdv";
      console.log(err)
    });

  }, []);





  return (
    <>

      <PaymentModalMoovpay
        open={openModalMoovpay}
        onClose={() => {
          if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
          }
          setOpenModalMoovpay(false);
        }}
        qrcode={qrcodeMoovpay}
      />

      <Dialog fullScreen open={dialogResultPayment} >

        <AppBar style={{ background: '#333' }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => setDialogResultPayment(false)} style={{ background: '#f03' }} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100%' }}>

          <Box sx={{ marginTop: 10 }}>
            {infoPedido.payment_method_id === '62c753914e8a07e77586dc82' && <ResultPix dataResultPix={dataResultPix} />}
          </Box>
        </Grid>
      </Dialog>


      <Dialog open={dialogEnviarWhatsapp} onClose={handleClickCloseModalEnviarWhatsapp} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Abrir Whatsapp Web</DialogTitle>
        <DialogContent>
          <Grid spacing={2} container>
            <Grid item md={4}>
              <TextField
                autoFocus
                maxLength={2}
                label="DDD"
                variant='outlined'
                fullWidth
                onChange={event => setDddWhatsapp(event.target.value)}
              />
            </Grid>
            <Grid item md={8}>
              <TextField
                autoFocus
                variant='outlined'
                label="Número"
                fullWidth
                onChange={event => setNumeroWhatsapp(event.target.value)}
              />
            </Grid>
          </Grid>
          <Typography style={{ marginTop: 10 }}>Por favor, digite somente NÚMEROS</Typography>


        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseModalEnviarWhatsapp} color="primary">
            Cancelar
          </Button>
          <Button disabled={dddWhatsapp && numeroWhatsapp ? false : true} onClick={abrirWhatsappWeb} color="primary">
            Abrir Whatsapp
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>


        <Container>
          <Box style={{ padding: 20 }}>

            <Grid item md={12}>
              <Typography sx={{ fontSize: 30 }} align='center' >Pedido <strong>#{infoPedido.number}</strong> foi realizado com sucesso!</Typography>
            </Grid>
            <Grid style={{ marginTop: 30 }} item md={10}>
              <Grid spacing={2} container>
                <Grid item md xs={6}>
                  <BoxDescriptionItem label="Frete" value={"R$ " + infoPedido.shipping_price} />
                </Grid>
                <Grid item md xs={6}>
                  <BoxDescriptionItem label="Subtotal" value={"R$ " + getValorSubTotal(items)} />
                </Grid>
                <Grid item md xs={6}>
                  <BoxDescriptionItem label="Total do Pedido" value={"R$ " + getValorTotal(items, infoPedido.shipping_price, infoPedido.desconto, infoPedido.ajuste)} />

                </Grid>
                <Grid item md xs={6}>
                  <BoxDescriptionItem label="Forma de Pagamento" value={validaPagamento(infoPedido.payment_method_id)} />
                </Grid>

              </Grid>
            </Grid>



            <Grid style={{ marginTop: 30 }} item md={12}>
              <Divider />
              <Grid container>
                <Grid item md={12}>

                  {infoPedido.payment_method_id === '6168e68dbec438dc351a3065' && (
                    <Stack alignItems={"center"}>
                      <Typography style={{ marginTop: 30, marginBottom: 30 }} align='center' variant="h6">Foi selecionado a forma de pagamento Cartão na Loja, abra o <Button variant='outlined' color="primary" onClick={abrirTerminaldePagamentos}>Terminal de Pagamentos</Button> e complete o pagamento por lá usando o número do pedido abaixo</Typography>
                      <Box sx={{background:'#f0f0f0', borderRadius:1, px:5}}>
                      <Typography variant='h3'>{infoPedido.number}</Typography>

                      </Box>
                    </Stack>
                  )}


                  {infoPedido.payment_method_id === '6168e69ebec438dc351a3066' && (
                    <Stack alignItems={"center"}>
                      <Typography style={{ marginTop: 30, marginBottom: 30 }} align='center' variant="h6">Foi selecionado a forma de pagamento Cartão na Loja, abra o <Button variant='outlined' color="primary" onClick={abrirTerminaldePagamentos}>Terminal de Pagamentos</Button> e complete o pagamento por lá usando o número do pedido abaixo</Typography>
                      <Typography variant='h3'>{infoPedido.number}</Typography>
                      </Stack>
                    )}


                  {infoPedido.payment_method_id === '6168e6b7bec438dc351a3068' && (
                    <div className='col-sm-12 col-lg-12'>
                      <div className="mb-4">Foi selecionado a forma de pagamento Dinheiro, Clique no link abaixo e retire o Boleto</div>
                      <div className="card-boleto-pedido">
                        {infoPedido.link_pagamento}
                      </div>

                    </div>
                  )}



                  {infoPedido.payment_method_id === '62c753914e8a07e77586dc82' && (
                    <Grid style={{ justifyContent: 'center', marginTop: 30 }} container>
                      <Grid item md={8}>
                        {loadingButton === true ?
                          (
                            <LinearProgress />
                          )
                          :
                          (
                            <Button onClick={cobrarPix} style={{ height: 70 }} color='primary' variant='contained' fullWidth>Gerar PIX</Button>
                          )

                        }

                        <Alert style={{ marginTop: 15 }} severity="warning">Após clicar em "Gerar Pix", o PIX ficará disponível por 5 minutos. </Alert>
                      </Grid>

                    </Grid>

                  )}


                  {infoPedido.payment_method_id === '65ef574e94335d158c264b32' && (
                    <Grid style={{ justifyContent: 'center', marginTop: 30 }} container>
                      <Grid item md={8}>
                        {!qrcodeMoovpay && (
                          loadingButton === true ?
                            (
                              <LinearProgress />
                            )
                            :
                            <>
                              {infoPedido.status_id === '60774fb684e6861cb41a4730' ? (
                                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt: 2 }}>
                                  <Stack spacing={2} direction={"column"} alignItems={"center"} justifyContent={"center"}>
                                    <Icon icon={"flat-color-icons:ok"} style={{ fontSize: 100 }} />
                                    <Typography textAlign={"center"}>
                                      Pagamento Relizado com sucesso
                                    </Typography>

                                  </Stack>

                                </Box>
                              ) : (
                                <Button onClick={handleCobrarMoovpay} style={{ height: 50 }} color='primary' variant='contained' fullWidth>Gerar QRCODE Moovpay !</Button>
                              )}
                            </>

                        )}
                        {qrcodeMoovpay && (
                          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt: 2 }}>
                            <Stack spacing={2} direction={"column"} justifyContent={"center"}>
                              <Typography textAlign={"center"}>
                                Escaneie o QRCode
                              </Typography>
                              <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: 200, width: 200 }}
                                value={qrcodeMoovpay}
                                viewBox={`0 0 256 256`}
                              />
                            </Stack>
                          </Box>
                        )}
                        <Alert sx={{ marginTop: 5 }} severity="warning">Após clicar em "Gerar Qrcode", efetue o pagamento imediatamente</Alert>
                      </Grid>

                    </Grid>

                  )}

                  {infoPedido.payment_method_id === '6168e6afbec438dc351a3067' && (
                    <Grid spacing={5} style={{ marginTop: 30 }} container>
                      <Grid item md={12}>
                        <Typography variant="h6" >Confira as opções para compartilhar o link de Pagamento</Typography>
                      </Grid>

                      <Grid md={4} item>
                        <Typography variant="h6">Opção 1</Typography>
                        <Typography>Abra o link em um nova aba ou envie por Whatsapp</Typography>
                        <Grid container style={{ marginTop: 30 }} spacing={2}>
                          <Grid item md><Button fullWidth onClick={() => abrirLinkNovaAba(infoPedido._id)} variant='contained' color="primary">Abrir em nova aba</Button></Grid>
                          {infoPedido.shipping_address && (
                            <Grid item md><Button fullWidth onClick={() => abrirModalWhatsappWeb(infoPedido.shipping_address.phone, infoPedido._id)} variant='contained' style={{ background: 'green', color: '#fff' }}>Abrir no Whatsapp Web</Button></Grid>
                          )}
                        </Grid>
                      </Grid>


                      <Grid md={4} item>
                        <Typography variant="h6">Opção 2</Typography>
                        <Typography>Copie o link clicando no botao abaixo</Typography>
                        <TextField
                          style={{ marginTop: 30 }}
                          fullWidth
                          disabled
                          value={`https://pag.vkholding.com.br/pedido/${infoPedido._id}`}
                          onChange={handleChangeCopyLink}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <CopyToClipboard text={`https://pag.vkholding.com.br/pedido/${infoPedido._id}`} onCopy={onCopy}>
                                  <Tooltip title="Copiar Boleto">
                                    <IconButton>
                                      <FiCopy size={24} />

                                    </IconButton>
                                  </Tooltip>
                                </CopyToClipboard>
                              </InputAdornment>
                            )
                          }}
                        />
                      </Grid>

                      <Grid md={4} item>
                        <Typography variant="h6">Opção 3</Typography>
                        <Typography>Abra a câmera ou App Leitor de QrCode de um SmartPhone e envie facilmente o LINK para o seu cliente</Typography>
                        <div style={{ textAlign: 'center', marginTop: 20 }}>
                          <QRCode size={150} value={`https://pag.vkholding.com.br/pedido/${infoPedido._id}`} />
                        </div>
                      </Grid>
                    </Grid>

                  )}

                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Container>

      </Box>

    </>
  )
}

export default ConclusaoPage;
