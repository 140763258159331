import axios from "axios";
import Config from "../../config/config";

const criarUsuario = data => {
    return axios({
        method: "post",
        url: "https://api.katuxa.com.br/api/v1/backoffice/cadastro/usuarios/criar",
        data
    });
};

export default criarUsuario;
