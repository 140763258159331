

export default function Select() {
  return {
    MuiSelect: {
      // defaultProps: {
      //   IconComponent: <Iconify icon="eva:arrow-ios-downward-fill" />,
      // },

      styleOverrides: {
        root: {}
      }
    }
  };
}
