import axios from "axios";
import Config from "../../config/config";

const verificarUsuario = cpf => {
    return axios({
        method: "get",
        url: "https://api.katuxa.com.br/api/v1/backoffice/cadastro/usuarios/verificar-usuario/"+cpf
    });
};

export default verificarUsuario;
