import React from 'react'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import FavoriteIcon from '@material-ui/icons/Favorite';
import Config from '../config/config';



const CartItemPedidos = ({ cartItem, removeFromCart, addItemQty, removeItemQty }) => {
    return (
        <div className="row mt-3 no-gutters">
            <div className="col-md-3">
                {cartItem.images.length > 1 ? (
                    <img width={80} height={80} src={`${Config.base_fotos}/fotos/${cartItem.produto}_${cartItem.cor_produto}_02.jpg`} class="card-img-top" />
                ) : (
                    <img width={80} height={80} src={`https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg`} class="card-img-top" style={{ padding: 10 }} />
                )}

            </div>
            <div className="col-md-9">
                <div style={{ fontSize: 12, paddingBottom: 5, fontWeight: 'bold' }} className="mt-2">{cartItem.name}</div>
            </div>
            <div className="col-md-12">
                <div className="mt-2">Cod:<span style={{ fontWeight: 'bold', fontSize: 15 }}>{cartItem.produto}</span>    |  R$ <strong>{(cartItem.regular_price * 0.9).toFixed(2)} ({cartItem.regular_price.toFixed(2)}) | Tam:<span style={{ fontWeight: 'bold', fontSize: 15 }}>{cartItem.grade}</span> </strong></div>
                <div style={{ fontSize: 12, paddingTop: 5, paddingBottom: 5, fontWeight: 'bold' }} className="mt-2"></div>
                <div className="mt-1">    Filial:<span style={{ fontWeight: 'bold', fontSize: 15 }}>{cartItem.filial}</span>   |   Cor:<span style={{ fontWeight: 'bold', fontSize: 12 }}>{cartItem.desc_cor_produto}</span> </div>
            </div>
        </div>

    )
}

export default CartItemPedidos;
