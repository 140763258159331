import { withRouter, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import React, { useEffect, useState, Fragment } from 'react';

import {
  Grid,
  Typography,
  LinearProgress,
  Box
} from '@mui/material';


import listaProdutosExclusivos from "../../actions/produtos/lista-produtos-exclusivos";
import Products from "../../components/Products";
import { useSelector, useDispatch } from 'react-redux';
import Actions from "../../constants/Actions";
import useResponsive from '../../utils/useResponsive';

// actions
import listaFiltrarPorLinha from '../../actions/produtos/lista-filtrar-por-linha'
import listaPesquisarPorLinha from '../../actions/produtos/lista-pesquisar-por-linha'
import listaAtributosPorLinha from "../../actions/produtos/lista-atributos-por-linha"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function ExclusivoPage() {


  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
   const loading = useSelector(state => state.loading);
  const [value, setValue] = React.useState(0);

  const [grupoInput, setGrupoInput] = useState([]);
  const [grupo, setGrupo] = useState([]);

  const [grade, setGrade] = useState([]);
  const [gradeInput, setGradeInput] = useState([]);


  const [griffe, setGriffe] = useState([]);
  const [griffeInput, setGriffeInput] = useState([]);

  const [filial, setFilial] = useState([]);

  const [subgrupo, setSubgrupo] = useState([]);
  const [subgrupoInput, setSubgrupoInput] = useState([]);

  const [categoria, setCategoria] = useState([]);
  const [categoriaInput, setCategoriaInput] = useState([]);

  const [slidePreco, setSlidePreco] = useState([0, 2000]);
  const [inputValue, setInputValue] = React.useState('');

  const [grupos, setGrupos] = useState([]);
  const [subgrupos, setSubgrupos] = useState([]);
  const [griffes, setGriffes] = useState([]);
  
  const [pathOld, setPathOld] = useState("");

  const isDesktop = useResponsive('up', 'md');

  


  //params
  const { exclusivo } = useParams();

  const handleChangeSlidePreco = (event, newValue) => {
    setSlidePreco(newValue);
  };

  function valuetext(value) {
    return `${value}°C`;
  }


  const handleGrupo = (array) => {
    setPathOld(exclusivo);
    setGrupoInput(array);
    if (array !== null) {
      setGrupo(array.map((item) => item.value))
    }
  };

  const handleSubgrupo = (array) => {
    setPathOld(exclusivo);
    setSubgrupoInput(array);
    if (grupo !== null) {
      setSubgrupo(array.map((item) => item.value))
    }
  };

  const handleGriffe = (array) => {
    setPathOld(exclusivo);
    setGriffeInput(array);
    if (grupo !== null) {
      setGriffe(array.map((item) => item.value))
    }
  };

  const handleGrade = (array) => {
    setPathOld(exclusivo);
    setGradeInput(array);
    if (grupo !== null) {
      setGrade(array.map((item) => item.value))
    }
  };

  const handleCategoria = (array) => {
    setPathOld(exclusivo);
    setCategoriaInput(array);
    if (grupo !== null) {
      setCategoria(array.map((item) => item.value))
    }
  };



  const handleFiltrarProdutos = () => {
    dispatch({ type: Actions.LOADING, loading: true })
    listaFiltrarPorLinha({ grupo, subgrupo, grade, filial, griffe, linha:exclusivo, preco:slidePreco }).then(res => {
      dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: res.data.produtos });
      setOpen(false);
      dispatch({ type: Actions.LOADING, loading: false })
    }).catch(err => console.log(err));
  };


  const handleBuscarAtributosPorLinha = atributo => {
  
    listaAtributosPorLinha({ atributo:atributo, linha:exclusivo, }).then(res => {
      if(atributo === 'grupo_produto') {
        setGrupos(res.data.atributos);
      } else if(atributo === 'subgrupo_produto') {
        setSubgrupos(res.data.atributos);
      } else if(atributo === 'griffe') {
        setGriffes(res.data.atributos);
      }

    }).catch(err => console.log(err));

  };

  useEffect(() => {
    if(pathOld !== exclusivo) {
      setSubgrupo([]);
      setSubgrupoInput([]);
      setGriffe([]);
      setGriffeInput([]);
      setGrupo([]);
      setGrupoInput([]);
    }
  
  }, [exclusivo]);


  useEffect(() => {
    dispatch({ type: Actions.LOADING, loading: true })
    listaProdutosExclusivos({ atributo: exclusivo }).then(res => {
      if (res.data.produtos.length > 0) {
        dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: res.data.produtos });
        dispatch({ type: Actions.LOADING, loading: false })
      }

      dispatch({ type: Actions.LOADING, loading: false })
    }).catch(err => console.log(err));

  }, []);

  return (
    <div>


      {loading === true ? (
        // <BackdropSincronizando open={loading} />
        <  LinearProgress />
      ) :
        (
          <>

           
              {isDesktop && (
                 <Grid style={{ paddingTop: 50, paddingBottom: 50 }} container justifyContent="center">
                         <Grid item md={11}>
                         <Typography variant="h4">
                          exclusivos pdv: <span style={{fontWeight:'bold'}}>{exclusivo}</span>
                         </Typography>
         
                       </Grid>
                       </Grid>
              )}
        
       

            <Grid container>
              {/* <Grid item md={2}>
                
                  <Grid container style={{ marginTop: 15 }} spacing={1}>
                    <Grid item md={12} sm={12} xs={12}>
                    <Box style={{ }}>  
                      <Grid container spacing={2}>

                      <Grid item md={12} xs={12} sm={12}>
                        <Typography>Filtrar em <b>{exclusivo}</b></Typography>
                        </Grid>


                        <Grid item md={12} xs={12} sm={12}>
                          <Autocomplete
                                  multiple
                                
                                  onOpen={() => {
                                   
                                    handleBuscarAtributosPorLinha('grupo_produto');
                                  }}
                                  onClose={() => {
                                    setOpen(false);
                                  }}
                                  id="tags-outlined"
                                  options={grupos}
                                  getOptionLabel={(option) => option.label}
                                  value={grupoInput}
                                  onChange={(event, newValue) => handleGrupo(newValue)}
                                  inputValue={inputValue}
                                  onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                  }}
                                  filterSelectedOptions
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Grupo"
                                      placeholder="Grupo"
                                      size="small"
                                    />
                                  )}
                          />
                        </Grid>
                        <Grid item md={12} xs={12} sm={12}>
                        <Autocomplete
                                  multiple

                             
                                  onOpen={() => {
                              
                                    handleBuscarAtributosPorLinha('subgrupo_produto');
                                  }}
                                  onClose={() => {
                                    setOpen(false);
                                  }}
                                  id="tags-outlined"
                                  options={subgrupos}
                                  getOptionLabel={(option) => option.label}
                                  value={subgrupoInput}
                                  onChange={(event, newValue) => handleSubgrupo(newValue)}
                                  inputValue={inputValue}
                                  onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                  }}
                                  filterSelectedOptions
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="SubGrupo"
                                      placeholder="SubGrupo"
                                      size="small"
                                    />
                                  )}
                          />
                        </Grid>

                        <Grid item md={12} xs={12} sm={12}>
                        <Autocomplete
                                  multiple

                                  onOpen={() => {
                              
                                    handleBuscarAtributosPorLinha('griffe');
                                  }}
                                 
                                  id="tags-outlined"
                                  options={griffes}
                                  getOptionLabel={(option) => option.label}
                                  value={griffeInput}
                                  onChange={(event, newValue) => handleGriffe(newValue)}
                                  inputValue={inputValue}
                                  onInputChange={(event, newInputValue) => {
                                    setInputValue(newInputValue);
                                  }}
                                  filterSelectedOptions
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Marca"
                                      placeholder="Marca"
                                      size="small"
                                    />
                                  )}
                          />
                        </Grid>

          
                        <Grid item xs={12} sm={12} md={12}>
                          <div>Faixa de Preço</div>
                          <Slider
                            value={slidePreco}
                            onChange={handleChangeSlidePreco}
                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            max={2000}
                            getAriaValueText={valuetext}
                          />
                        </Grid>

                        <Grid item md xs={12} sm={12}>
                          <Grid container style={{ marginTop: 14 }} spacing={1}>
               
                            <Grid item>
                              <Button variant="contained" color="primary" onClick={handleFiltrarProdutos}>Pesquisar</Button>
                            </Grid>

                          </Grid>
                        </Grid>
                      </Grid>
                        </Box>
                    </Grid>
                  </Grid>
            
              </Grid>
 */}


              <Grid item md={12}>

                <Products style={{ marginTop: 50 }} />
              </Grid>

            </Grid>

          </>
        )}


      {/*      

        <div className="dashboard-panel-container">

          <Grid container className={classes.grid} spacing={0}>
            <Grid item xs={11}>
              <Grid container justify="center" spacing={2}>
              {viewMainProduct.length !== 0 ?
              viewMainProduct.map((data, index) => {
                return(
                  <Grid md={3} xs={6}  key={index} item justify="center">
                  <Card onClick={() => handleClickOpen(data)}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        alt="Imagem"
                        height="200"
                        style={{padding:20}}
                        image={`data:image/jpeg;base64, ${data.foto_base64}`}
                       
                      />
                      <CardContent>
                      <Typography gutterBottom variant="h6" component="h6">
                        {data.desc_produto}
                        </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                       CUK:#{data.cuk}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                        Estoque:{data.estoque}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                        Cidade:{data.cidade}
                        </Typography>
                       
                        <Typography variant="body2" color="textSecondary" component="p">
                          Filial: {data.cod_filial}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p">
                          Cor: {data.desc_cor_produto}
                        </Typography>
                        <Grid container direction="row" alignItems="center">
                          <Grid item>
                            <h3 className={classes.h3}><NumberFormat value={data.PRECO1} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></h3>
                          </Grid>
                      
                        </Grid>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              )
            }) : <Avatar alt="Remy Sharp" src="https://cdn.dribbble.com/users/159377/screenshots/3846578/searchemptystate_2x.png" className={classes.bigAvatar} />}
            </Grid>
            </Grid>
          </Grid>
        </div> */}
    </div>
  )
}
export default withRouter(ExclusivoPage)
