import React, { useEffect } from 'react'
import {Container} from '@mui/material';
// páginas
import LinhaPage from '../Page/LinhaPage'



const Lojas = ({search}) => {

  return(
    <div style={{background:"#f9f9f9"}}> 
    <Container maxWidth="xl">
    <LinhaPage />
    </Container>
           
    </div>
  )
}

export default Lojas;
