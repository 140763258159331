import React from 'react'
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import FavoriteIcon from '@material-ui/icons/Favorite';
import {Grid} from "@material-ui/core";
import Config from '../config/config'

const CartItem = ({ cartItem, removeFromCart }) => {
    const trasnformPrice = parseFloat(cartItem.regular_price) * 0.9
    return (
        
            <div className="row mt-3 no-gutters">
                 <Grid container>

                    <Grid item md={1}>
                      <Grid container justifyContent="center" alignContent="center" alignItems="center"> 
                        <Button
                        variant="text"
                        style={{color: "grey"}}
                        startIcon={<DeleteIcon size={80} />}
                        onClick={() => removeFromCart(cartItem)}
                        />
                      </Grid>
                    </Grid>   
                    <Grid item md={3}>
                       <img className="rounded" style={{ maxWidth: "100%", padding:8 }} src={cartItem.images.length > 0 ? `${Config.base_fotos}/thumb/${cartItem.produto}_${cartItem.cor_produto}_02.jpg` : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg'} alt="" />
                    </Grid>

                    <Grid item md={8}>
                        <Grid container spacing={2}>
                            <Grid item md={12} sm={12}>
                               <div style={{fontSize:15,paddingBottom:5, fontWeight:'bold'}} className="mt-2">{cartItem.name}</div>
                             </Grid>   
                             <Grid item md={4} sm>
                                <div className="mt-2">produto:<span style={{fontWeight:'bold', fontSize:15}}>{cartItem.produto}</span> </div>
                             </Grid> 
                             <Grid item md={4} sm>
                                <div className="mt-2">Filial:<span style={{fontWeight:'bold', fontSize:15}}>{cartItem.filial}</span> </div>
                             </Grid> 
                             <Grid item md={4} sm>
                                <div className="mt-2">Cod Cor:<span style={{fontWeight:'bold', fontSize:15}}>{cartItem.cor_produto}</span> </div>
                             </Grid> 
                             <Grid item md sm>
                                <div className="mt-2">Cor:<span style={{fontWeight:'bold', fontSize:15}}>{cartItem.desc_cor_produto}</span> </div>
                             </Grid>
                             <Grid item md sm>
                                <div className="mt-2">Tamanho:<span style={{fontWeight:'bold', fontSize:15}}>{cartItem.grade}</span> </div>
                             </Grid> 

                             <Grid item md sm>
                                <div className="mt-2">Preço:<span style={{fontWeight:'bold', fontSize:15}}>{trasnformPrice.toFixed(2)}</span> </div>
                             </Grid> 

                        </Grid>

                    </Grid>

                </Grid>

                 <div class="row">
                 <div className="col-md-2">

                        
                        </div>
                {/* <div className="col-md-10">
                    <div class="input-group mt-3 mb-5">
                        <div class="input-group-prepend">
                            <button class="btn btn-outline-secondary font-weight-bold" disabled={cartItem.produto.quantity_inc === 1} onClick={() => removeItemQty(cartItem)}> -- </button>
                        </div>
                        <input type="text" readOnly class="form-control text-center" value={1} aria-label="Example text with button addon" aria-describedby="button-addon1" />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary font-weight-bold" onClick={() => addItemQty(cartItem)}>+</button>
                        </div>
                    </div>
                    
                </div> */}
                </div>
            </div>
      
    )
}

export default CartItem
