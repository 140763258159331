import axios from "axios";
import Config from "../../config/config";

const listaProdutosPorLinha = data => {
    return axios({
        method: "post",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/produtos/lista/linha",
        data
    });
};

export default listaProdutosPorLinha;
