import React from "react"
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Card, Typography, Grid, Button } from '@material-ui/core';

import moment from "moment";

import { formatDistanceToNow, format } from 'date-fns';



// utils
import { getValorTotal } from "../../utils/functions"

// ----------------------------------------------------------------------

const PERCENT = 2.6;
const TOTAL_SOLD = 765;
const CHART_DATA = [{ data: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43] }];

export default function CardListaPedidos({ dataPedido, pedido, valor, status, pago, data, cliente, frete }) {


  const abrirLinkNovaAba = id => {
    let url = `https://pag.vkholding.com.br/pedido/${dataPedido._id}/detalhes`;
    window.open(url, '_blank');
  }


  return (
    <Card style={{ display: 'flex', alignItems: 'center', padding: 3, marginTop: 10 }}>
      <Box style={{ flexGrow: 1, padding: 10 }}>
        <Grid container>
          <Grid md xs={6} sm={6} item>
            <Typography variant="caption" >
              Pedido
            </Typography>
            <Typography variant="subtitle2" >
              #{pedido}
            </Typography>
          </Grid>
          <Grid md xs={6} sm={6} item>
            <Typography variant="caption" >
              Valor
            </Typography>
            <Typography variant="subtitle2" >
              R$ {getValorTotal(valor, frete, dataPedido.desconto, dataPedido.ajuste)}
            </Typography>
          </Grid>
          <Grid md xs={12} sm={12} item>
            <Typography variant="caption" >
              Cliente
            </Typography>
            <Typography variant="subtitle2" >
              {cliente.substr(0, 10)}  {cliente.length > 10 && '...'}
            </Typography>
          </Grid>
          <Grid md xs={6} sm={6} item>
            <Typography variant="caption" >
              Data
            </Typography>
            <Typography variant="subtitle2" >
              {moment(data).format('DD/MM/YYYY')}

            </Typography>
          </Grid>
          <Grid md xs={6} sm={6} item>
            <Typography variant="caption" >
              Status
            </Typography>
            <Typography variant="subtitle2" >
              {status}
            </Typography>
          </Grid>
          {dataPedido.status_id !== "60774fa684e6861cb41a472f" && (
            <Grid md xs={6} sm={6} item>
              <Button fullWidth onClick={() => abrirLinkNovaAba(dataPedido._id)} variant='contained' color="primary">Detalhes</Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Card>
  );
}
