import React, {useEffect} from 'react'
import clsx from 'clsx'
import { Container, Grid, Paper } from '@material-ui/core';

import { useStyles } from '../Style/StyleAdmin';
import ShippingPage from '../Page/ShippingPage'
import FilterPage from '../Page/FilterPage'
import NoActivity from '../Page/NoActivity'
import Cart from '../../components/Cart'
import { useHistory } from 'react-router-dom';
import CartItem from '../../components/CartItem';

import { connect, useDispatch, useSelector } from 'react-redux';



function Shipping() {
  const classes = useStyles();
  const history = useHistory();
  const cartItems = useSelector(state => state.cartItems);

  const irCadastro = () => {
    history.push('resumo');
    console.log('foi')
    
  };


  return(
    <ShippingPage proximoPasso={irCadastro} />
  )
}

export default Shipping;
