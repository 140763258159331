import React, { Fragment } from 'react'
//import DropdownMultiselect from 'react-multiselect-dropdown-bootstrap'
import { makeStyles } from '@mui/styles';
import {BiShareAlt, BiBarcodeReader} from 'react-icons/bi';
import {FiArrowLeftCircle} from 'react-icons/fi';
import {BsCreditCard} from 'react-icons/bs';
import {RiMoneyDollarCircleLine} from 'react-icons/ri';
import {useHistory} from "react-router-dom";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Stepper from "../../components/Stepper";



const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 220,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const state = {
 
  session: '',

  sender: {
      name: 'Willy Chagas',
      email: 'chagaswc89@gmail.com',
      phone: {
          areaCode: '48',
          number: '991510980',
      },
      document: {
          type: 'CPF',
          value: '71783955082'
      },
  },

  shipping: {
      type: 3,
      cost: 10.00,
      street: 'Av Campeche',
      number: 1111,
      complement: 'Casa',
      district: 'Campeche',
      city: 'Florianópolis',
      state: 'SC',
      country: 'BRA',
      postalCode: '88063789'
  },

  billing: {
      street: 'Av Campeche',
      number: 1111,
      complement: 'Casa',
      district: 'Campeche',
      city: 'Florianópolis',
      state: 'SC',
      country: 'BRA',
      postalCode: '88063789'
  },

  items: [
      {
          id: 1,
          description: 'Produto 1',
          quantity: 2,
          amount: 2,
      },  
      {
          id: 2,
          description: 'Produto 2',
          quantity: 1,
          amount: 60.00,
      },  
      {
          id: 3,
          description: 'Produto 3',
          quantity: 2,
          amount: 20.00,
      }

  ],

  creditCard: {
      maxInstallmentNoInterest: 5 // parcelas com desconto
  },
  extraAmount: 10.00,
  reference: 'Teste Pagseguro React'
}



const PagamentoPage = () => {

  const classes = useStyles();
  const [tipoPagamento, setTipoPagamento] = React.useState('');
  const [bandeira, setBandeira] = React.useState('');
  const history = useHistory();
 
  
  const handleChange = (event) => {
    setBandeira(event.target.value);
  };

    
  const handleCartaoDebito = () => {
    setTipoPagamento('Cartão Débito')
   }

   const handleCartaoCredito = () => {
    setTipoPagamento('Cartão Crédito')
   }

   const handleBoleto = () => {
    setTipoPagamento('Boleto')
   }

   const handleDinheiro = () => {
    setTipoPagamento('Dinheiro')
   }

   const handleLinkPagamento = () => {
    setTipoPagamento('Link Pagamento')
   }


  const voltarPdv = () => {
    history.push('cadastro')
  }

  // const CardTransportadora = ({click, img, tipoEntrega, titulo, valorFrete, dataMaxima}) => {
  //   return(
  //   <div style={{background:transportadora == tipoEntrega ? '#ccc' : ''}} onClick={click} className='card'>
        
  //   <div className='card-body  border-left'>
  //     <div className='d-flex mb-3 align-items-center'>
     
  //      <img width={60} src={img}/>
  
  //       <p className='text-black'>{titulo}</p>
  //        </div>

  //        <div style={{padding:15}} className='mb-12 '>
     
  //       <p>R$ <strong style={{fontSize:30}}>{valorFrete}</strong></p>
  //       <p className=''>Chega até <strong>{dataMaxima}</strong></p>
  //        </div>
  //   </div>
  // </div>
  // )
  // }




  return (
    <Fragment>
           <div className='container'>
                   
           <div className='row' style={{paddingBottom:30, paddingTop:10, borderRadius:10, alignContent:'center', alignItems:'center' }}>
           <div className='col-xl-2 col-xxl-2'>
           <FiArrowLeftCircle onClick={voltarPdv} size={40} /> Voltar
             </div>
             <div className='col-xl-10 col-xxl-10'>
           <Stepper
           valorStep={3}
           />
             </div>
             </div>


      <div className='row'>
        <div className='col-xl-12 col-xxl-12'>
          <h4 className="mb-4">Selecione a forma de pagamento</h4>
          <div className='row'>

            <div className='col-xl-2 col-lg-2'>
              <div style={{background:tipoPagamento == 'Cartão Crédito' ? '#b6ffca' : '', color:tipoPagamento == 'Cartão Crédito' ? '#fff' : ''}} onClick={handleCartaoCredito} className='card '>
        
                <div className='card-body  border-left'>
                  <div className='d-flex mb-3 align-items-center'>
                    <a className='mr-3' href='#'>
                    <BsCreditCard size={20}/>
                  
                    </a>
                    <span className='text-black'>Cartão Crédito </span>
                  </div>
                </div>
      
              </div>
            </div>
            <div className='col-xl-2 col-lg-2'>
              <div style={{background:tipoPagamento == 'Cartão Débito' ? '#b6ffca' : ''}} onClick={handleCartaoDebito} className='card '>
        
                <div className='card-body  border-left'>
                  <div className='d-flex mb-3 align-items-center'>
                    <a className='mr-3' href='#'>
                    <BsCreditCard size={20}/>
                  
                    </a>
                    <span className='text-black'>Cartão Débito </span>
                  </div>
                </div>
      
              </div>
            </div>

            <div className='col-xl-2 col-lg-2'>
              <div style={{background:tipoPagamento == 'Link Pagamento' ? '#b6ffca' : ''}} onClick={handleLinkPagamento} className='card '>
        
                <div className='card-body  border-left'>
                  <div className='d-flex mb-3 align-items-center'>
                    <a className='mr-3' href='#'>
                    <BiShareAlt size={20}/>
                  
                    </a>
                    <span className='text-black'>Link Pagamento </span>
                  </div>
                </div>
      
              </div>
            </div>

            <div className='col-xl-2 col-lg-2'>
              <div style={{background:tipoPagamento == 'Boleto' ? '#b6ffca' : ''}} onClick={handleBoleto} className='card '>
        
                <div className='card-body  border-left'>
                  <div className='d-flex mb-3 align-items-center'>
                    <a className='mr-3' href='#'>
                    <BiBarcodeReader size={20}/>
                  
                    </a>
                    <span className='text-black'>Boleto Bancário </span>
                  </div>
                </div>
      
              </div>
            </div>

            <div className='col-xl-2 col-lg-2'>
              <div style={{background:tipoPagamento == 'Dinheiro' ? '#b6ffca' : ''}} onClick={handleDinheiro} className='card '>
        
                <div className='card-body  border-left'>
                  <div className='d-flex mb-3 align-items-center'>
                    <a className='mr-3' href='#'>
                    <RiMoneyDollarCircleLine size={20}/>
                  
                    </a>
                    <span className='text-black'>Dinheiro </span>
                  </div>
                </div>
      
              </div>
            </div>


          </div>
        </div>
        <div className='col-xl-12 col-xxl-12'>

        <div className="row">
            <div className="col-xl-12">
               <div className="">
                  <div className="card-body">
                     <div className="row">





               {tipoPagamento === 'Cartão Débito' && (    
                 <div className="card-body card">
                            <FormControl variant="outlined" className={classes.formControl}>
               <h5 className="mb-4">Escolha a badeira do Cartão</h5>
                 <Select
                   labelId="demo-simple-select-outlined-label"
                   id="demo-simple-select-outlined"
                   value={bandeira}
                   onChange={handleChange}
                   label=""
                 >
                   <MenuItem value="">
                     <em></em>
                   </MenuItem>
                   <MenuItem value={10}>Visa</MenuItem>
                   <MenuItem value={20}>Mastercard</MenuItem>
                   <MenuItem value={30}>Elo</MenuItem>
                   <MenuItem value={30}>American Express</MenuItem>
                   <MenuItem value={30}>Diners</MenuItem>
                 </Select>
               </FormControl>

        {bandeira && (
          
          <div style={{marginTop:20}} className="col-md-8 order-md-1">
          <h2 className="mb-2">Forma de Pagamento: <strong>{tipoPagamento}</strong> </h2>
          <form className="needs-validation" noValidate="">

       <p> Após a confirmação do pedido, será gerado um código para ser inserido no App Katuxa Pay para que o pagamento com o cliente seja concluído</p>     
         
             {/* <button
                className="btn btn-primary btn-lg btn-block"
                type="submit"
             >
                Continuar
             </button> */}
          </form>
       </div>


        )

        }
                  

                     </div>
                 
                      ) }


{tipoPagamento === 'Cartão Crédito' && (    
                 <div className="card-body card">
                            <FormControl variant="outlined" className={classes.formControl}>
               <h5 className="mb-4">Escolha a badeira do Cartão</h5>
                 <Select
                   labelId="demo-simple-select-outlined-label"
                   id="demo-simple-select-outlined"
                   value={bandeira}
                   onChange={handleChange}
                   label=""
                 >
                   <MenuItem value="">
                     <em></em>
                   </MenuItem>
                   <MenuItem value={10}>Visa</MenuItem>
                   <MenuItem value={20}>Mastercard</MenuItem>
                   <MenuItem value={30}>Elo</MenuItem>
                   <MenuItem value={30}>American Express</MenuItem>
                   <MenuItem value={30}>Diners</MenuItem>
                 </Select>
               </FormControl>

        {bandeira && (
          
          <div style={{marginTop:20}} className="col-md-8 order-md-1">
          <h2 className="mb-2">Forma de Pagamento: <strong>{tipoPagamento}</strong> </h2>
          <form className="needs-validation" noValidate="">

       <p> Após a confirmação do pedido, será gerado um código para ser inserido no App Katuxa Pay para que o pagamento com o cliente seja concluído</p>     
         
             {/* <button
                className="btn btn-primary btn-lg btn-block"
                type="submit"
             >
                Continuar
             </button> */}
          </form>
       </div>


        )

        }
                  

                     </div>
                 
                      ) }






{tipoPagamento === 'Boleto' && (    
                 <div className="card-body card">
                   
          
          <div style={{marginTop:20}} className="col-md-8 order-md-1">
          <h2 className="mb-2">Forma de Pagamento: <strong>{tipoPagamento}</strong> </h2>
          <form className="needs-validation" noValidate="">

       <p> Após a confirmação do pedido, será gerado um boleto para pagamento</p>     
         
             {/* <button
                className="btn btn-primary btn-lg btn-block"
                type="submit"
             >
                Continuar
             </button> */}
          </form>
       </div>                

                     </div>
                 
                      ) }



{tipoPagamento === 'Link Pagamento' && (    
                 <div className="card-body card">
                   
          
          <div style={{marginTop:20}} className="col-md-8 order-md-1">
          <h2 className="mb-2">Forma de Pagamento: <strong>{tipoPagamento}</strong> </h2>
          <form className="needs-validation" noValidate="">

       <p> Após a confirmação do pedido, será gerado um link de pagamento da Yapay. Este link pode ser compatilhado com o cliente para que ele possa efetuar o pagamento de um modo seguro</p>     
         
             {/* <button
                className="btn btn-primary btn-lg btn-block"
                type="submit"
             >
                Continuar
             </button> */}
          </form>
       </div>                

                     </div>
                 
                      ) }


                      
{tipoPagamento === 'Dinheiro' && (    
                 <div className="card-body card">
                   
          
          <div style={{marginTop:20}} className="col-md-8 order-md-1">
          <h2 className="mb-2">Forma de Pagamento: <strong>{tipoPagamento}</strong> </h2>
          <form className="needs-validation" noValidate="">

       <p> Após a confirmação do pedido, dirija-se com o cliente até o caixa para pagamento do pedido.</p>     
         
             {/* <button
                className="btn btn-primary btn-lg btn-block"
                type="submit"
             >
                Continuar
             </button> */}
          </form>
       </div>                

                     </div>
                 
                      ) }
                      
                      
            

                     </div>
                  </div>
               </div>
            </div>
         </div>




        </div>
      </div>
      </div>
    </Fragment>
  )
}

export default PagamentoPage;
