import { withRouter } from "react-router-dom";

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import NumberFormat from 'react-number-format';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import SearchIcon from '@material-ui/icons/Search';

import {  Card, CardActionArea, CardContent, CardMedia,
  Typography, IconButton, Grid, InputBase, Avatar  } from '@material-ui/core';

import RestaurantIcon from '@material-ui/icons/Restaurant';
import AppsRoundedIcon from '@material-ui/icons/AppsRounded';
import FiberNewRoundedIcon from '@material-ui/icons/FiberNewRounded';

import {Fab} from '@material-ui/core';
import { useStyles } from '../Style/StyleOrder';

function FilterPage (props){
  const [viewMainProduct, setViewMainProduct] = useState([]);
  const [order, setOrder] = useState('')
  const [sorting, setSorting] = useState('')
  const [search, setSearch] = useState('')
  const [token] = useState(localStorage.getItem("jwt"))
  const apiMainProduct='http://localhost:8080/api/product/produtos'

  const fetchMainProduct = async () => {
    const result = await axios.post(apiMainProduct)
    .then((res) => {
      console.log(res.data)
           
          setViewMainProduct(res.data.produtos)
    })
  }

  const funSearch = (e) => {
    e.target.name = e.target.value
    setSearch(e.target.name, console.log(search))
  }

  const submitSearch = () => {
    setSearch(search)
      fetchMainProduct()
  }

  const changeSort = (checkSort) => {
    setSorting(checkSort)
      fetchMainProduct()
  }

  const changeOrder = (checkOrder) => {
    setOrder(checkOrder)
      fetchMainProduct()
  }

  useEffect(() => {
    fetchMainProduct();
  }, []);

  const classes = useStyles();
    return(
      <div>
        <div>
          <Grid container alignContent="center" alignItems="center" justify="center">
            <Grid xs={12} md={12} >
              <Fab className={classes.fabColor}onClick={() => changeOrder("name")} variant="extended" color="primary" aria-label="add">
                <RestaurantIcon />&nbsp;
                  Filtrar por Nome
              </Fab> &emsp;&emsp;

              </Grid>

              <Grid xs={12} md={12} >

              <Fab className={classes.fabColor} onClick={() => changeOrder("category")} variant="extended" color="primary" aria-label="add">
                <AppsRoundedIcon />&nbsp;
                Filtrar por Categoria
              </Fab> &emsp;&emsp;

              </Grid>

              <Grid xs={12} md={12} >

              <Fab className={classes.fabColor} onClick={() => changeOrder("date_update")} variant="extended" color="primary" aria-label="add">
                <FiberNewRoundedIcon />&nbsp;
                Filtrar por Novos Produtos
              </Fab>
              </Grid>
            </Grid>
        </div><br/>
        <div className="dashboard-panel-container">
          <Grid container justify="center">
            <Grid direction="row" xs={12} md={12} xs={12}>
            <IconButton onClick={submitSearch} fontSize="medium">
              <SearchIcon />
            </IconButton> 

            <InputBase
               placeholder = " Procurar Produto..."
               inputProps={{ 'aria-label': 'naked' }}
               name= "search"
               onChange={funSearch}
             /> &emsp;


            </Grid>
     
             <IconButton onClick={() => changeSort("DESC")} size="large">
               <ArrowUpwardIcon />
             </IconButton>

             <IconButton onClick={() => changeSort("ASC")} size="large">
               <ArrowDownwardIcon />
             </IconButton>
          </Grid><br />

        </div>
      </div>
    )
  }
export default withRouter(FilterPage)
