import clsx from 'clsx';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useStyles } from '../Style/StyleLogin';
import { Redirect, Link, withRouter } from 'react-router-dom';
import { Divider, Avatar, Button, CssBaseline, TextField, Grid, Box, Typography, Container, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, RadioGroup, FormControlLabel, Radio, Select, MenuItem} from '@material-ui/core';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';

import { toast } from 'react-toastify';

import loginByUser from '../../actions/loginByUser';

// {/* --- IMPORT REDUCE --- */}
import { connect } from 'react-redux';

// actions
import buscarFilialPorCodigoFilial from '../../actions/cadastro/buscar-filial-por-codigo-filial';
import listaCodigoFiliais from '../../actions/cadastro/lista-codigo-filiais'
import criarUsuario from '../../actions/cadastro/criar-usuario';
import verificarUsuario from '../../actions/cadastro/verificar-usuario';
import editarSenha from '../../actions/cadastro/editar-senha';
// functions 
import { maskCPF, mascararTel, soNumeros } from '../../utils/functions';
import { Alert } from 'react-bootstrap';
import Logo from '../../images/logo3d.png';




const variantIcon = {
  success: CheckCircleIcon,
  error: ErrorIcon
}

function SnackbarAlert(props) {
  const classes = useStyles();
  const { className, message, variant } = props;
  const Icon = variantIcon[variant];



  return (
    <SnackbarContent
      className={clsx(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={clsx(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
    />
  )
}

function Login(props) {
  const classes = useStyles();
  const [loginKey, setLoginKey] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [validate, setValidate] = useState('');
  const [success, setSuccess] = useState('error');

  const [cpf, setCpf] = useState('');
  const [cpfCadastro, setCpfcadastro] = useState('');
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [password, setPassword] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [telefone, setTelefone] = useState('');
  const [dataUserTemp, setDataUserTemp] = useState('');

  const [passwordRedefinir, setPasswordRedefinir] = useState('');
  const [confirmarSenhaRedefinir, setConfirmarSenhaRedefinir] = useState('');

  const [openDialogCadastrar, setOpenDialogCadastrar] = useState(false);
  const [openDialogRedefinirSenha, setOpenRedefinirSenha] = useState(false);

  const [isReforco, setIsReforco] = useState(null);
  const [isTreinamento, setIsTreinamento] = useState(null);
  const [cargo, setCargo] = useState('');
  const [filial, setFilial] = useState('');

  const [filiais, setFiliais] = useState([]);


  const [openCargoSelect, setOpenCargoSelect] = useState(false);
  const [openFilialSelect, setOpenFilialSelect] = useState(false);

  const [step, setStep] = useState(1);



  useEffect(() => {
    if (localStorage.getItem("jwt") !== null) {
      props.history.push('/pdv');
    }


  }, []);

  {/* --- SIGNUP REDUX --- */ }
  const initialFormState = { username: "", password: "" };
  const [saveLogin, setSaveLogin] = useState(initialFormState);


  const handleBuscarCodigoFiliais = () => {
  
    listaCodigoFiliais().then(res => {
      setFiliais(res.data)
      setOpenFilialSelect(true)
    }).catch(err => console.log(err));

  };

  // const login = (e) => {
  //   e.preventDefault();
  //   props.dispatch(postLogin(saveLogin))
  //   .then((result) => {
  //     console.log(result);
  //     if (result.value.data.status !== 400){
  //       setValidate(result.value.data.status.login)
  //       setSuccess("success")
  //       setShowStatus(true)
  //       localStorage.setItem("jwt", result.value.data.status.token)
  //       setLoginKey(true)
  //     } else {
  //       setValidate(result.value.data.data)
  //       setShowStatus(true)
  //     }
  //   })
  //   .catch((error) => setShowStatus(false))
  // }

  


  const notifySuccess = (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };


  const notifyError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };


  const login = (e) => {
    e.preventDefault();

    loginByUser({ cpf: soNumeros(cpf), password: password }).then(res => {
      console.log('res.data.usaurio', res.data.user);
      setDataUserTemp(res.data.user);
      if (res.data.status === "sucesso") {
        notifySuccess("Login feito com sucesso");
        localStorage.setItem("Usuario", res.data.user.nome);
        localStorage.setItem("dataUsuario", `{"nome":"${res.data.user.nome}", "sobrenome":"${res.data.user.sobrenome}", "cpf":"${res.data.user.cpf}", "filial":"${res.data.user.filial}", "codigo":"${res.data.user.codigo}"}`);

        buscarFilialPorCodigoFilial(res.data.user.filial).then(res => {
          let data = {
            filial: res.data.codigo_filial,
            cep: res.data.cep,
            rua: res.data.logradouro,
            bairro: res.data.bairro,
            cidade: res.data.localidade,
            estado: res.data.uf,
            isMaquininha:res.data.isMaquininha

          }
          localStorage.setItem("dataUser", `{"filial":"${res.data.codigo_filial}", "cep":"${res.data.cep}", "rua":"${res.data.logradouro}", "bairro":"${res.data.bairro}", "cidade":"${res.data.localidade}", "estado":"${res.data.uf}", "codigo_tab_preco":"${res.data.codigo_tab_preco}", "isMaquininha":"${res.data.isMaquininha}"}`);
          // localStorage.setItem("dataUser", data);

        });
        localStorage.setItem("jwt", res.data.token);
        props.history.push('/pdv/');
      } else if (res.data.message === "Senha Incorreta") {
        notifyError("Senha incorreta");
      } else if (res.data.message === "Usuário Não Encontrado!") {
        notifyError("Usuário não Encontrado");
      }

    });



    //localStorage.setItem("dataUser", `{"usuario":"f000002", "filial":"000009", "cep":"35500043",     "lat":"-44.891064", "long":"-20.146291000103695",     "rua":"Goiás",    "bairro":"Centro",     "cidade":"Divinópolis", "estado":"MG"}`);
    //props.history.push('/dashboard/pdv')

  }

  const handleEditarSenha = () => { 
    editarSenha({cpf:soNumeros(cpfCadastro), password:passwordRedefinir}).then(res => {
      if(res.data.status === 'success') {
        setStep(1);
        setOpenRedefinirSenha(false);
      }
    })
  }

  const cadastrarUsuario = () => {
    const dataCadastro ={
      nome: nome,
      sobrenome:sobrenome,
      telefone: soNumeros(telefone),
      password: password,
      reforco: isReforco,
      treinamento: isTreinamento,
      filial: filial,
      cargo: cargo,
      cpf: soNumeros(cpfCadastro) 
  }
 
       if(!filial || !cargo || !cpfCadastro || !telefone || !nome || !sobrenome ) {
        console.log('teste 12', filial, cargo, cpfCadastro, isReforco, isTreinamento, telefone)
        notifyError("Preencha todos os campos antes de continuar");
       } else  {
        criarUsuario(dataCadastro).then(res => {
          alert('Usuário Cadastrado, entre em contato com o Setor de Inovação para liberar o cadastro.')
          setOpenDialogCadastrar(false);
        }).catch(err => console.log(err => console.log('erro ao fazer cadstro', err)))
       }

   
    
  }

  const onChangeCpf = (event) => {
    setCpf(event.target.value)
  }

  const onChangePassword = (event) => {
    setPassword(event.target.value)
  }

  const handleCloseSnackbar = () => {
    setShowStatus(false)
  }

  const verificaCpfExiste = async (action) => {

    if(action === 'edit') {
      if(soNumeros(cpfCadastro).length === 11) {
        verificarUsuario(soNumeros(cpfCadastro)).then(res => {
          if(res.data.isUsuario === true) {
            setStep(6);
            
          } else if(res.data.isUsuario === false) {
            setStep(2);
            alert('CPF não encontrado. Realize o cadastro!');
            setOpenDialogCadastrar(true);
            setOpenRedefinirSenha(false);
          }
        })
    }

    } else if(action === 'add') {
      if(soNumeros(cpfCadastro).length === 11) {
        verificarUsuario(soNumeros(cpfCadastro)).then(res => {
          if(res.data.isUsuario === true) {
            alert('Usuario tem cadastro')
            
          } else if(res.data.isUsuario === false) {
            setStep(2);
          
          }
        })
    }
    }
  

  }

  // step 2 = Não existe cadstro, prosseguir para cadastro
  // step 3 = Existe cadastro, validar senha gerente
  // step 4 = Existe cadastro, senha validada
  // step 5 = Redefinir senha
  // step 6 = Existe cadastro, redefinir senha

  const changeCpf = (event) => {
      setCpfcadastro(event.target.value)

  };


  const handleChangeCargo = (event) => {
    console.log('event', event.target.value);
    setOpenCargoSelect(false);
    setCargo(event.target.value);
  };

  const handleChangeFilial = (event) => {
    console.log('event', event.target.value);
    setOpenFilialSelect(false);
    setFilial(event.target.value);
  };


  return (
    <div style={{height:'100vh', display:'flex', alignItems:'center'}}>
      {loginKey && <Redirect to='/dashboard' />}
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={showStatus}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <SnackbarAlert
          variant={success}
          message={validate}
        />
      </Snackbar>

      
      <Dialog maxWidth={'sm'} fullWidth open={openDialogRedefinirSenha} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">REDEFINIR SENHA</DialogTitle>
        <Divider />
        <DialogContent>

        <Grid style={{ marginTop: 15, maxHeigth:400, alignItems:'center' }} container spacing={1}>
  
            <Grid item md={4}>
              <TextField
                size="medium"
                autoFocus
                variant="outlined"
                margin="dense"
                label="CPF"
                type="text"
                disabled={step === 2 ? true : false}
                fullWidth
                onChange={changeCpf}
                value={maskCPF(cpfCadastro) }
              />
            </Grid>
            {step === 6 ? 
            ( 
            <Grid item><Button onClick={() => setStep(1)} color="primary" variant='outlined' >Editar CPF</Button> </Grid>
            ) 
            : 
            (
              <Grid item><Button disabled={step !== 2 ? false : true} onClick={() => verificaCpfExiste('edit')} color="primary" variant='outlined'>Continuar</Button> </Grid> 
            )}
          </Grid>


 {step === 6 && (
     <>

          <Grid style={{ marginTop: 15 }} container spacing={1}>
            <Grid item md={12}>
              <Typography variant="caption">Senha</Typography>
            </Grid>
            <Grid item md={6}>
              <TextField
                size="medium"
                autoFocus
                variant="outlined"
                margin="dense"
                label="Senha"
                type="password"
                fullWidth
                value={passwordRedefinir}
                onChange={e => setPasswordRedefinir(e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                size="medium"
                autoFocus
                variant="outlined"
                margin="dense"
                id="name"
                label="Confirmar Senha"
                type="password"
                fullWidth
                value={confirmarSenhaRedefinir}
                onChange={e => setConfirmarSenhaRedefinir(e.target.value)}
              />
            </Grid>
            {confirmarSenhaRedefinir.length > 0 && confirmarSenhaRedefinir !== passwordRedefinir && (
                     <Grid item md={12}>
                     <Alert severinty="error" >Senhas não conferem!</Alert>
                   </Grid>
            )}
     
          </Grid>

     </>

 )}
       
        </DialogContent>
        <DialogActions style={{marginTop:20}}>
          <Button onClick={() => setOpenRedefinirSenha(false)} color="primary">
            Cancelar
          </Button>
        {step === 6 && <Button disabled={passwordRedefinir && confirmarSenhaRedefinir ? false : true} variant='contained' onClick={handleEditarSenha} color="primary">Redefinir</Button>}  
        </DialogActions>
      </Dialog>


      <Dialog maxWidth={'sm'} fullWidth open={openDialogCadastrar} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">CADASTRO VK ID</DialogTitle>
        <Divider />
        <DialogContent>

        <Grid style={{ marginTop: 15, maxHeigth:400, alignItems:'center' }} container spacing={1}>
  
            <Grid item md={4}>
              <TextField
                size="medium"
                autoFocus
                variant="outlined"
                margin="dense"
                label="CPF"
                type="text"
                disabled={step === 2 ? true : false}
                fullWidth
                onChange={changeCpf}
                value={maskCPF(cpfCadastro) }
              />
            </Grid>
            {step === 2 ? 
            ( 
            <Grid item><Button onClick={() => setStep(1)} color="primary" variant='outlined' >Editar CPF</Button> </Grid>
            ) 
            : 
            (
              <Grid item><Button disabled={step !== 2 ? false : true} onClick={() => verificaCpfExiste('add')} color="primary" variant='outlined'>Continuar</Button> </Grid> 
            )}

            

          </Grid>
 {step === 2 && cpfCadastro.length === 14 && (
     <>
      <Grid style={{ marginTop: 15, maxHeigth:400 }} container spacing={1}>
            <Grid item md={12}>
              <Typography variant="caption">Dados Pessoais</Typography>
            </Grid>
            <Grid item md={6}>
              <TextField
                size="medium"
                autoFocus
                variant="outlined"
                margin="dense"
                label="Nome"
                type="text"
                fullWidth
                value={nome}
                onChange={e => setNome(e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                size="medium"
                autoFocus
                variant="outlined"
                margin="dense"
                id="name"
                label="Sobrenome"
                type="text"
                fullWidth
                value={sobrenome}
                onChange={e => setSobrenome(e.target.value)}
              />
            </Grid>
    
            <Grid item md={6}>
              <TextField
                size="medium"
                autoFocus
                variant="outlined"
                margin="dense"
                id="name"
                label="Telefone"
                type="text"
                onChange={event => setTelefone(event.target.value)}
                fullWidth
                value={mascararTel(telefone)}
              />
            </Grid>
          </Grid>

          <Grid style={{ marginTop: 15 }} container spacing={1}>
            <Grid item md={12}>
              <Typography variant="caption">Senha</Typography>
            </Grid>
            <Grid item md={6}>
              <TextField
                size="medium"
                autoFocus
                variant="outlined"
                margin="dense"
                label="Senha"
                type="password"
                fullWidth
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item md={6}>
              <TextField
                size="medium"
                autoFocus
                variant="outlined"
                margin="dense"
                id="name"
                label="Confirmar Senha"
                type="password"
                fullWidth
                value={confirmarSenha}
                onChange={e => setConfirmarSenha(e.target.value)}
              />
            </Grid>
            {confirmarSenha.length > 0 && confirmarSenha !== password && (
                     <Grid item md={12}>
                     <Alert severinty="error" >Senhas não conferem!</Alert>
                   </Grid>
            )}
     
          </Grid>

          <Grid style={{ marginTop: 35 }} container spacing={1}>
          <Grid item md={6}>
            <Grid container>
               <Typography variant="subtitle">Você trabalha como reforço?</Typography>
            </Grid>
            <Grid item md={12}>
            <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                <FormControlLabel control={<Radio onChange={event => setIsReforco(true)} checked={isReforco === true} />} label="Sim" />
                    <FormControlLabel  control={<Radio onChange={event => setIsReforco(false)} checked={isReforco === false} />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
         
          
            </Grid>
               
            <Grid item md={6}>
              <Grid container>
              <Typography variant="subtitle">Você está em treinamento?</Typography>
              <Grid  item md={12}>
            <FormControl component="fieldset">
                <RadioGroup row aria-label="position" name="position" defaultValue="top">
                <FormControlLabel control={<Radio onChange={event => setIsTreinamento(true)} checked={isTreinamento === true} />} label="Sim" />
                    <FormControlLabel  control={<Radio onChange={event => setIsTreinamento(false)} checked={isTreinamento === false} />} label="Não" />
                </RadioGroup>
              </FormControl>
            </Grid>
         

              </Grid>
      
            </Grid>
       
          </Grid>

    

          
          <Grid style={{ marginTop: 20 }} container spacing={1}>

            <Grid item md={6}>
            <Typography variant="subtitle">Filial</Typography>
            <FormControl  fullWidth component="fieldset">
            <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          open={openFilialSelect}
          variant="outlined"
          placeholder="Selcione a filial"
          onClose={() => setOpenFilialSelect(false)}
          onOpen={handleBuscarCodigoFiliais}
          style={{height:50}}
          value={filial}
          size="small"
          onChange={handleChangeFilial}
         
        >
           
            { filiais.map( (item) => {
              return <MenuItem key={item.codigo_filial} value={item.codigo_filial}>{item.codigo_filial}</MenuItem>;
            }) }
        

        </Select>
              </FormControl>
            </Grid>
            <Grid item md={6}>
            <Typography variant="subtitle">Cargo</Typography>
            <FormControl  fullWidth component="fieldset">
            <Select
          labelId="demo-controlled-open-select-label"
          id="demo-controlled-open-select"
          variant="outlined"
          open={openCargoSelect}
          placeholder="Selcione o cargo"
          onClose={() => setOpenCargoSelect(false)}
          onOpen={() => setOpenCargoSelect(true)}
          value={cargo}
          style={{height:50}}
          onChange={handleChangeCargo}
         
        >
          <MenuItem value="">
            <em>Selecione o cargo</em>
          </MenuItem>
          <MenuItem value="Vendedor">Vendedor</MenuItem>
          <MenuItem value="Crediarista">Crediarista</MenuItem>
          <MenuItem value="Subgerente">Subgerente</MenuItem>
          <MenuItem value="Gerente">Gerente</MenuItem>
          <MenuItem value="Franqueado">Franqueado</MenuItem>
          <MenuItem value="Estoquista">Estoquista</MenuItem>
        </Select>
              </FormControl>
            </Grid>
            
          </Grid>
     
     </>

 )}
       
        </DialogContent>
        <DialogActions style={{marginTop:20}}>
          <Button onClick={() => setOpenDialogCadastrar(false)} color="primary">
            Cancelar
          </Button>
        {step === 2 && <Button variant='contained' onClick={cadastrarUsuario} color="primary">Cadastrar</Button>}  
        </DialogActions>
      </Dialog>

      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paperLogin}>
          <img style={{width:150}} alt="Remy Sharp"  src={Logo} />
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <form className={classes.form} onSubmit={login} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              autoFocus
              fullWidth
              label="CPF"
              name="username"
              type="text"
              value={maskCPF(cpf) }
              onChange={onChangeCpf}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              label="Senha"
              name="password"
              type="password"
              value={password}
              onChange={onChangePassword}
            />
            <Button
              className={classes.buttonColor}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
            >
              Login
            </Button>
            <Grid container>
              <Grid item xs>
                <br />
                <Link onClick={() => setOpenDialogCadastrar(true)} className={classes.linkUnderline} to="#" variant="body2">
                  Primeiro acesso?
                </Link>
              </Grid>
              <Grid item xs>
                <br />
                <Link onClick={() => setOpenRedefinirSenha(true)} className={classes.linkUnderline} to="#" variant="body2">
                  Esqueceu a senha?
                </Link>
              </Grid>

            </Grid>
          </form>
        </div>

      </Container>
    </div>
  );
}

{/* --- RESPONSE --- */ }
const mapStateToProps = state => {
  return {
    response: "ture"
  };
};

export default withRouter(connect(mapStateToProps)(Login));
