import { makeStyles } from '@mui/styles';
export const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: "#fff",
    },'*::-webkit-scrollbar': {
        backgroundColor:'#fff',
        width:'16px'
    },
    '*::-webkit-scrollbar-track': {
        backgroundColor:'#fff'
    },
    '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: '16px',
        border:'5px solid #fff'
    },
  },
  container: {
    paddingTop: 4,
    paddingBottom: 4,
  },
  paper: {
    padding: 2,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 1000,
  },
  leftpaper:{
    background:'#f5f5fb'
  },
  paperLayout:{
    padding: 2,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    background:'#f5f5fb'
  }
}));
