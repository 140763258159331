import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {useHistory} from "react-router-dom";
import { useSelector, useDispatch  } from 'react-redux';

import {Container} from '@material-ui/core';

// páginas
import LinhaPage from '../Page/LinhaPage'


const Linha = ({search}) => {
 
  const history = useHistory();
  const dispatch = useDispatch();
  const [right, setRight] = React.useState();

  const drawerCarrinho = useSelector(state => state.drawerCarrinho);
 


  return(
    <div style={{background:"#f9f9f9"}}> 
    <Container maxWidth="xl">
    <LinhaPage />
    </Container>
           
    </div>
  )
}

export default Linha;
