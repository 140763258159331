import React from "react"
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Card, Typography, Grid } from '@material-ui/core';

// ----------------------------------------------------------------------

const PERCENT = 2.6;
const TOTAL_SOLD = 765;
const CHART_DATA = [{ data: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43] }];

export default function VendasTotalVendido({titulo, valor}) {


  return (
    <Card style={{ display: 'flex', alignItems: 'center', padding: 3 }}>
      <Box style={{ flexGrow: 1, padding:10 }}>
        <Typography variant="subtitle2" >
         {titulo}
        </Typography>
        <Typography variant="h6" >
          {valor}
          
        </Typography>
      </Box>

    </Card>
  );
}
