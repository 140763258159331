import { makeStyles } from '@mui/styles';


export const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor:'#fff',
    },'*::-webkit-scrollbar': {
        backgroundColor:'#fff',
        width:'16px'
    },
    '*::-webkit-scrollbar-track': {
        backgroundColor:'#fff'
    },
    '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: '16px',
        border:'5px solid #fff'
    },
  },
  paperLogin: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: 1
  },
  bigAvatar: {
    margin: 10,
    width: 120,
    height: 120,
  },
  buttonColor:{
    background: 'linear-gradient(45deg, #4abd89 30%, #3ac46e 90%)'
  },
  linkUnderline:{
    textDecoration: 'none'
  },
  success: {
    backgroundColor: '#4abd89',
  },
  error: {
    backgroundColor: '#000',
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: 1,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },

}));
