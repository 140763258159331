import { withRouter, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  LinearProgress,
  Link,
  Breadcrumbs,
  Box
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';

import Products from "../../components/Products";
import { useSelector, useDispatch } from 'react-redux';
import Actions from "../../constants/Actions";
import Slide from '@material-ui/core/Slide';
import useResponsive from '../../utils/useResponsive';

// actions
import listaFiltrarPorLinha from '../../actions/produtos/lista-filtrar-por-linha'
import listaProdutosPorLinha from "../../actions/produtos/lista-produtos-por-linha";
import listaAtributosPorLinha from "../../actions/produtos/lista-atributos-por-linha"

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const ITEM_HEIGHT = 68;
const ITEM_PADDING_TOP = 8;


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function LinhaPage() {


  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const loading = useSelector(state => state.loading);
  const [value, setValue] = React.useState(0);

  const [grupoInput, setGrupoInput] = useState([]);
  const [grupo, setGrupo] = useState([]);

  const [grade, setGrade] = useState([]);
  const [gradeInput, setGradeInput] = useState([]);


  const [griffe, setGriffe] = useState([]);
  const [griffeInput, setGriffeInput] = useState([]);

  const [filial, setFilial] = useState([]);

  const [subgrupo, setSubgrupo] = useState([]);
  const [subgrupoInput, setSubgrupoInput] = useState([]);

  const [categoria, setCategoria] = useState([]);
  const [categoriaInput, setCategoriaInput] = useState([]);

  const [slidePreco, setSlidePreco] = useState([0, 2000]);
  const [inputValue, setInputValue] = React.useState('');

  const [grupos, setGrupos] = useState([]);
  const [subgrupos, setSubgrupos] = useState([]);
  const [griffes, setGriffes] = useState([]);

  const [pathOld, setPathOld] = useState("");

  //params
  const { linha } = useParams();


  const handleFiltrarProdutos = () => {
    dispatch({ type: Actions.LOADING, loading: true })
    listaFiltrarPorLinha({ grupo, subgrupo, grade, filial, griffe, linha: linha, preco: slidePreco }).then(res => {
      dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: res.data.produtos });
      setOpen(false);
      dispatch({ type: Actions.LOADING, loading: false })
    }).catch(err => console.log(err));
  };


  const handleBuscarAtributosPorLinha = atributo => {

    listaAtributosPorLinha({ atributo: atributo, linha: linha, }).then(res => {
      if (atributo === 'grupo_produto') {
        setGrupos(res.data.atributos);
      } else if (atributo === 'subgrupo_produto') {
        setSubgrupos(res.data.atributos);
      } else if (atributo === 'griffe') {
        setGriffes(res.data.atributos);
      }

    }).catch(err => console.log(err));

  };

  useEffect(() => {
    if (pathOld !== linha) {
      setSubgrupo([]);
      setSubgrupoInput([]);
      setGriffe([]);
      setGriffeInput([]);
      setGrupo([]);
      setGrupoInput([]);
    }

  }, [linha]);


  useEffect(() => {
    dispatch({ type: Actions.LOADING, loading: true })
    listaProdutosPorLinha({ linha: linha }).then(res => {
      if (res.data.produtos.length > 0) {
        dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: res.data.produtos });
        dispatch({ type: Actions.LOADING, loading: false })
      }

      dispatch({ type: Actions.LOADING, loading: false })
    }).catch(err => console.log(err));

  }, []);

  return (
    <div>


      {loading === true ? (
        // <BackdropSincronizando open={loading} />
        <  LinearProgress />
      ) :
        (
          <>


            {/* {isDesktop && (
                 <Grid style={{ paddingTop: 50, paddingBottom: 50 }} container justifyContent="center">
                         <Grid item md={11}>
                         <Typography variant="h4">
                           {linha}
                         </Typography>
         
                       </Grid>
                       </Grid>
              )}
         */}





            <Grid style={{ paddingTop: 20, paddingBottom: 20 }} container justifyContent="center">
              <Grid item md={11}>
                <Typography style={{ textTransform: 'capitalize' }} variant="h4">
                  {linha}
                </Typography>
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                  <Link color="inherit" href="/" >
                    Página Inicial
                  </Link>
                  <Typography color="textPrimary">{linha}</Typography>
                </Breadcrumbs>

              </Grid>
            </Grid>

            <Products style={{ marginTop: 20 }} />

          </>
        )}

    </div>
  )
}
export default withRouter(LinhaPage)
