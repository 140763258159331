import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CardProduct from './CardProduct.js';
import { addToCart } from '../actions';
import { useStyles } from '../Component/Style/StyleOrder';

import './Products.css'



import { Alert, AlertTitle, LoadingButton } from '@mui/lab';
import CloseIcon from '@material-ui/icons/Close';


import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";

//config
import Config from "../config/config";

//actions
import buscarFilialPorCodigoFilial from "../actions/cadastro/buscar-filial-por-codigo-filial.js";
import listaProdutosPrecosFilial from "../actions/produtos/buscar-precos-filial.js";
import actionListaProdutosByReduzidoECorProduto from '../actions/produtos/lista-produtos-por-reduzido-e-cor-produto';
import listaOutrosTamanhos from '../actions/produtos/lista-outros-tamanhos'
import actionIsConfereEstoqueLoja from "../actions/config/actionIsConfereEstoqueLoja.js";
import listaProdutosFiliais from '../actions/produtos/lista-produtos-filiais';
import listaOutrasCores from '../actions/produtos/lista-outras-cores';

import { Toolbar, AppBar, Slide, Stack, Backdrop, LinearProgress, Box, Button, Dialog, DialogActions, DialogTitle, FormControlLabel, Checkbox, Typography, Avatar, Badge, Container, Divider, IconButton, Grid, Card } from "@mui/material";

//componets
import SemResultados from "../Component/Page/SemResultados.js";
import LoadingModal from "./LoadingModal/index";

//actions
import Actions from "../constants/Actions";

//services
import { getDataUsuario } from '../services/auth'
import ItemCardOpcao from './ItemCardOpcao.js';
import { StackedBarChartSharp } from '@material-ui/icons';

const numeral = require('numeral');


const Produtos = ({ abrirDialog, product }) => {
  const [verProduto, setVerProduto] = useState('');
  const [token] = useState(localStorage.getItem("jwt"))
  const [outrasCoresProduto, setOutrasCoresProduto] = useState([])
  const [outrosProdutos, setOutrosProdutos] = useState([])
  const [carrinho, setCarrinho] = React.useState([]);
  const [outrosTamanhos, setOutrosTamanhos] = React.useState([]);
  const [filiaisProdutos, setFiliaisProdutos] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [tamanhoProduto, SetTamanhoProduto] = React.useState('');
  const [corProduto, setCorProduto] = React.useState('');
  const [filialProduto, setFilialProduto] = React.useState('');
  const [backdrop, setBackdrop] = React.useState(!open);
  const [parcelaSelecionada, setParcelaSelecionada] = React.useState('');
  const [modalTravaCarrinho, setModalTravaCarrinho] = React.useState(false);
  const [modalMudouPreco, setModalMudouPreco] = React.useState(false);
  const [loadingModal, setLoadingModal] = React.useState(false);
  const [isProdutoApto, setIsProdutoApto] = React.useState(false);
  const [produtoAbaixoEstoque, setprodutoAbaixoEstoque] = React.useState(false);
  const [precoProduto, setPrecoProduto] = React.useState('');
  const [isConfereEstoqueLoja, setIsConfereEstoqueLoja] = React.useState(false);
  const [isEstoque, setIsEstoque] = React.useState(false);
  const dispatch = useDispatch();


  const [loadingVerificaPreco, setLoadingVerificaPreco] = useState(false);

  //stare
  const products = useSelector(state => state.products);
  const cepOrigemProduto = useSelector(state => state.cepOrigemProduto);
  const cartItems = useSelector(state => state.cartItems);


  const handleChangeParcelas = (event) => {
    setParcelaSelecionada(event.target.value);
  };

  const handleChangeIsProdutoApto = (event) => {
    setIsProdutoApto(event.target.checked);
  };

  const verificaProdutoButton = () => {
    if (isProdutoApto === false) {
      if (produtoAbaixoEstoque) {
        return false;
      } else {
        return true;
      }
    } else if (isProdutoApto === true) {
      if (produtoAbaixoEstoque) {
        return false;
      } else {
        return true;
      }
    }
  };



  const Teste = (product) => {
    let item = {
      product_image: product.images.length > 0 ? product.images[0]._id : null,
      product_id: product._id,
      variant_id: null,
      quantity: 1,
      discount_total: 0,
      name: product.name,
      regular_price: precoProduto,
      price: precoProduto,
      price_total: precoProduto,
      sku: product.sku,
      tax_class: 0,
      tax_total: 0,
      produto: product.produto,
      cor_produto: corProduto,
      grade: tamanhoProduto,
      variant_name: null,
      weight: product.weight,
      dimension: product.dimension,
      images: product.images,
      filial: filialProduto,
      desc_cor_produto: product.desc_cor_produto,
      length: product.dimensions.length,
      heigth: product.dimensions.height,
      width: product.dimensions.width
    }

    if (cartItems.length === 0) {
      dispatch(addToCart(item));
      dispatch({ type: Actions.CEP_ORIGEM_PRODUTO, cepOrigemProduto: filialProduto });
      setVerProduto('');
      setCorProduto('');
      SetTamanhoProduto('');
      setFilialProduto('');
      setFiliaisProdutos([]);
      setOutrosTamanhos([]);
      setOutrasCoresProduto([]);
      setOpen(false);
      dispatch({ type: Actions.DRAWER_CARRINHO, drawerCarrinho: true })

    } else {
      if (filialProduto === cepOrigemProduto) {
        dispatch(addToCart(item));
        dispatch({ type: Actions.CEP_ORIGEM_PRODUTO, cepOrigemProduto: filialProduto });
        setVerProduto('');
        setCorProduto('');
        SetTamanhoProduto('');
        setFilialProduto('');
        setFiliaisProdutos([]);
        setOutrosTamanhos([]);
        setOutrasCoresProduto([]);
        setOpen(false);
        dispatch({ type: Actions.DRAWER_CARRINHO, drawerCarrinho: true })
      } else {
        setModalTravaCarrinho(true)
      }
    }

  }

  const ItemHeaderStep = ({ title }) => {
    return (
      <Box sx={{ mt: 2, mb: 2, width: 300, p: 0.5, background: '#f9f9f9', borderRadius: 0.5 }}>
        <Typography sx={{ fontSize: 10, fontWeight: 'bold' }} color="#333">
          {title}
        </Typography>
      </Box>
    )
  }

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const images = [
    {
      original: 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg',
      thumbnail: 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg',
    }
  ];

  const imagensProduto = [
    {
      original: verProduto ? verProduto.images.length > 0 ? `${Config.base_fotos}/fotos/${verProduto.produto}_${verProduto.cor_produto}_01.jpg` : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg' : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg',
      thumbnail: verProduto ? verProduto.images.length > 0 ? `${Config.base_fotos}/fotos/${verProduto.produto}_${verProduto.cor_produto}_01.jpg` : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg' : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg',
    },
    {
      original: verProduto ? verProduto.images.length > 1 ? `${Config.base_fotos}/fotos/${verProduto.produto}_${verProduto.cor_produto}_02.jpg` : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg' : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg',
      thumbnail: verProduto ? verProduto.images.length > 1 ? `${Config.base_fotos}/fotos/${verProduto.produto}_${verProduto.cor_produto}_02.jpg` : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg' : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg',
    },
    {
      original: verProduto ? verProduto.images.length > 2 ? `${Config.base_fotos}/fotos/${verProduto.produto}_${verProduto.cor_produto}_03.jpg` : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg' : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg',
      thumbnail: verProduto ? verProduto.images.length > 2 ? `${Config.base_fotos}/fotos/${verProduto.produto}_${verProduto.cor_produto}_03.jpg` : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg' : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg',
    },
    {
      original: verProduto ? verProduto.images.length > 3 ? `${Config.base_fotos}/fotos/${verProduto.produto}_${verProduto.cor_produto}_04.jpg` : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg' : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg',
      thumbnail: verProduto ? verProduto.images.length > 3 ? `${Config.base_fotos}/fotos/${verProduto.produto}_${verProduto.cor_produto}_04.jpg` : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg' : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg',
    },
    {
      original: verProduto ? verProduto.images.length > 4 ? `${Config.base_fotos}/fotos/${verProduto.produto}_${verProduto.cor_produto}_05.jpg` : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg' : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg',
      thumbnail: verProduto ? verProduto.images.length > 4 ? `${Config.base_fotos}/fotos/${verProduto.produto}_${verProduto.cor_produto}_05.jpg` : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg' : 'https://katuxa.sfo3.digitaloceanspaces.com/pdv/sem-imagem.jpg',
    },
  ];

  console.log('imagens produtos', verProduto.images)


  const handleEscolheuCor = cores => {
    setOutrosTamanhos([]);
    SetTamanhoProduto('');
    setFilialProduto('');
    setLoadingModal(true);
    console.log('cores', cores)
    actionListaProdutosByReduzidoECorProduto({ produto: cores.produto, codigoCor: cores.cor_produto }).then(res => {
      setVerProduto(res.data.produto);
      setPrecoProduto(res.data.produto.regular_price);
      setCorProduto(res.data.produto.cor_produto);
      listaOutrosTamanhos({ codigoFilial: getDataUsuario().filial, produto: res.data.produto.produto, cor: res.data.produto.cor_produto }).then(res => {
        if (res.data.produtos.length === 0) {
          setLoadingModal(false);
          setIsEstoque(false)
        } else {
          setIsEstoque(true)
          setOutrosTamanhos(res.data.produtos);
          setLoadingModal(false);
        }

      })
    })

  };

  const handleEscolheuFilial = async (data) => {
    setLoadingVerificaPreco(true)
    setprodutoAbaixoEstoque(false);
    if (data.estoque === 1) {
      setprodutoAbaixoEstoque(true);
    } else if (verProduto.grupo_produto === 'BOTA' || verProduto.linha === 'FEMININO') {
      setprodutoAbaixoEstoque(true);
    } else {
      setIsProdutoApto(true);
    }


    const filialEscolhida = data.produto.cod_filial;

    buscarFilialPorCodigoFilial(filialEscolhida).then(res => {
      listaProdutosPrecosFilial({
        produto: verProduto.produto,
        corProduto: verProduto.cor_produto,
        codTabelaPreco: res.data.codigo_tab_preco,
        codFilial: data.produto.cod_filial
      }).then(res => {
        if (res.data.produto.preco !== precoProduto) {
          setModalMudouPreco(true);
          setPrecoProduto(res.data.produto.preco)
        }
        setFilialProduto(filialEscolhida);
        setLoadingVerificaPreco(false);
      }).catch(err => {
        console.log(err);
        setLoadingVerificaPreco(false);
      })
    });
  };

  const handleEscolheuTamanho = (data) => {
    setprodutoAbaixoEstoque(false);
    if (data.estoque === 1) {
      setprodutoAbaixoEstoque(true);
    } else {
      setIsProdutoApto(true);
    }
    setFilialProduto('');
    SetTamanhoProduto(data.produto.grade);
    console.log(data.produto.grade)
    listaProdutosFiliais({ produto: verProduto.produto, cor: corProduto, grade: data.produto.grade, filial: getDataUsuario().filial }).then((res) => {
      setFiliaisProdutos(res.data.produtos);

    })

  };

  const handleClickOpen = (data) => {
    setOpen(true)
    setIsProdutoApto(null);
    setLoadingModal(true);
    actionListaProdutosByReduzidoECorProduto({ produto: data.produto.produto, codigoCor: data.produto.cor_produto }).then(res => {
      setVerProduto(res.data.produto);
      setPrecoProduto(res.data.produto.regular_price);
      listaOutrasCores({ produto: data.produto.produto }).then((res) => { setOutrasCoresProduto(res.data.cores) });
      setCorProduto(res.data.produto.cor_produto);
      listaOutrosTamanhos({ codigoFilial: getDataUsuario().filial, produto: res.data.produto.produto, cor: res.data.produto.cor_produto }).then(res => {
        if (res.data.produtos.length === 0) {
          setLoadingModal(false);
          setIsEstoque(false)
        } else {
          setIsEstoque(true)
          setOutrosTamanhos(res.data.produtos);
          setLoadingModal(false);
        }

      })

    })

  }

  const handleClickClose = () => {
    setOpen(false);
    setVerProduto('');
    setPrecoProduto('')
    setCorProduto('');
    SetTamanhoProduto('');
    setFilialProduto('');
    setFiliaisProdutos([]);
    setOutrosTamanhos([]);
    setOutrasCoresProduto([]);
    setIsProdutoApto(false);
  };

  ///modals
  const handleCloseModalTravaCart = () => {
    setModalTravaCarrinho(false);
  };

  const handleCloseModalMudouPreco = () => {
    setModalMudouPreco(false);
  };



  const selectedCorBorder = (data) => {
    if (corProduto) {
      if (corProduto === data.cor_produto) {
        return '#ccc';
      }
    }
  };

  const selectedFilial = (data) => {
    console.log(filialProduto)
    if (filialProduto) {
      if (filialProduto === data.produto.cod_filial) {
        return '#2BC137 ';
      }
    }
  };

  const verificaEstoqueTamanho = (data) => {

    if (data.estoque === 1) {
      return (
        <Badge badgeContent={data.estoque} color="error">
          <Card
            onClick={() => { handleEscolheuTamanho(data) }}
            style={{
              padding: 10,
              border: 2,
              borderColor: selectedTamanho(data),
              borderStyle: 'solid',
            }} item md={1} lg={1}>
            <Typography variant="caption">{data.produto.grade}</Typography>
          </Card>

        </Badge>
      )
    } else {
      return (
        <Badge badgeContent={data.estoque} color="primary">
          <Card
            onClick={() => { handleEscolheuTamanho(data) }}
            style={{
              padding: 10,
              border: 2,
              borderColor: selectedTamanho(data),
              borderStyle: 'solid',
            }} item md={1} lg={1}>
            <Typography variant="caption">{data.produto.grade}</Typography>
          </Card>
        </Badge>

      )

    }
  }


  const verificaEstoqueFilial = (data) => {

    if (data.estoque === 1) {
      return (
        <Badge badgeContent={data.estoque} color="error">
          <Card onClick={() => { handleEscolheuFilial(data) }} style={{
            padding: 10,
            fontWeight: 'bold',
            fontSize: 12,
            color: '#333',
            margin: 10,
            borderRadius: 10,
            border: 2,
            borderColor: selectedFilial(data),
            borderStyle: 'solid',
          }} item md={1} lg={1}>
            <div style={{ fontSize: 14 }}>{data.produto.cod_filial}</div>
          </Card>

        </Badge>
      )
    } else {
      return (
        <Badge badgeContent={data.estoque} color="primary">
          <Card onClick={() => { handleEscolheuFilial(data); console.log('teste') }} style={{
            padding: 10,
            fontWeight: 'bold',
            fontSize: 12,
            color: '#333',
            margin: 10,
            borderRadius: 10,
            border: 2,
            borderColor: selectedFilial(data),
            borderStyle: 'solid',
          }} item md={1} lg={1}>
            <div style={{ fontSize: 14 }}>{data.produto.cod_filial}</div>
          </Card>

        </Badge>

      )

    }
  }

  const selectedTamanho = (data) => {
    console.log(tamanhoProduto)
    if (tamanhoProduto) {
      if (tamanhoProduto === data.produto.grade) {
        return '#2BC137';
      }
    }
  };

  useEffect(() => {
    actionIsConfereEstoqueLoja().then(res => {
      setIsConfereEstoqueLoja(res.data.data);
    }).catch(err => console.log(err))

  }, []);



  const classes = useStyles();
  return (
    <>
      {products.length > 0 ? (

        <Grid container spacing={1}>
          {products.map(data => (
            <Grid item xs={6} md={2} xl={2} sm={12} >
              {data && (
                <CardProduct
                  key={data.produto._id}
                  product={data.produto}
                  verProdutoProps={() => handleClickOpen(data)}
                />
              )}
            </Grid>
          ))}
        </Grid>


      ) : (
        <SemResultados />
      )}


      <Dialog
        open={modalTravaCarrinho}
        onClose={handleCloseModalTravaCart}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">NÃO FECHAMOS VENDA COM PRODUTOS DE OUTRAS FILIAIS.</DialogTitle>

        <DialogActions>
          <Button onClick={handleCloseModalTravaCart} color="primary">
            Fechar
          </Button>

        </DialogActions>
      </Dialog>

      <Dialog
        open={modalMudouPreco}
        onClose={handleCloseModalMudouPreco}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">ENCONTRAMOS OUTRO PREÇO PRA ESSA FILIAL!</DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseModalMudouPreco} color="primary">
            OK
          </Button>

        </DialogActions>
      </Dialog>

      <Dialog fullScreen open={open} className="dialog-ver-produto" onClose={handleClickClose}>
        <div>
          {loadingModal === true ? (
            <div className={classes.containerModalLoading}>
              <Stack alignContent="center" justify="center" alignItems="center">
                <LoadingModal />
              </Stack>
            </div>
          ) : (
            <Stack direction={"column"} spacing={4}>
              <Stack >
                <Button style={{ background: '#f03', borderRadius: 0, color: '#fff' }} onClick={handleClickClose} sx={{ width: '100%' }}>
                  Fechar
                </Button>
              </Stack>

              <Stack>

                <Grid container spacing={5} >
                  <Grid item md={7} lg={7} sm={12} xs={12}>
                    <Box >
                      {verProduto ? (
                        <>
                          {verProduto.images.length > 0 ? (

                            <ImageGallery
                              showPlayButton={false}
                              items={imagensProduto} />

                          ) : (

                            <ImageGallery
                              showPlayButton={false}
                              items={images} />

                          )}
                        </>
                      ) : (
                        <div>
                          <ImageGallery
                            showPlayButton={false}
                            sx={{ maxHeight: '50vh' }}
                            items={images} />
                        </div>

                      )}
                    </Box>
                  </Grid>

                  <Grid style={{ paddingTop: 10 }} item md={5} lg={5} sm={12} xs={12}>
                    <Box style={{ padding: 15 }}>
                      <Grid direction="row" style={{ marginTop: 5, marginBottom: 5 }} container sm={12} xs={12} md={12} lg={12}>
                        <Grid item md={12} sm={12}>
                          <Typography style={{ textAlign: 'left' }} alignCenter variant="h6" gutterBottom> {verProduto.name} </Typography>
                          <Typography style={{ textAlign: 'left' }} alignCenter variant="caption" gutterBottom> PRODUTO: {verProduto.produto}  |  COR: {verProduto.cor_produto} </Typography>
                        </Grid>
                      </Grid>

                      <Divider sx={{ borderStyle: 'dashed' }} />



                      <Divider sx={{ borderStyle: 'dashed' }} />

                      <ItemHeaderStep
                        title="Escolha a cor"
                      />


                      <Box>
                        <Grid container style={{ marginTop: 10 }} spacing={1}>
                          {outrasCoresProduto.map((cores, index) => {
                            return (
                              <Grid key={index} item md={6}>
                                <ItemCardOpcao
                                  selected={selectedCorBorder(cores)}
                                  value={cores.desc_cor_produto}
                                  onClick={() => handleEscolheuCor(cores)}
                                />

                              </Grid>
                            )
                          })}
                        </Grid>
                      </Box>



                      {isEstoque ? (
                        corProduto && (
                          <div style={{ paddingTop: 20 }} className={classes.boxSelecioneTamanho}>
                            <Divider sx={{ borderStyle: 'dashed' }} />
                            <ItemHeaderStep
                              title="Escolha o tamanho"
                            />

                            <Grid container style={{ paddingBottom: 20, paddingTop: 20, overflowY: 'auto' }} spacing={2}>
                              {outrosTamanhos.map((data, index) => {
                                return <Box style={{ margin: 5 }}> {verificaEstoqueTamanho(data)} </Box>
                              })}
                            </Grid>
                          </div>
                        )
                      ) : (
                        <Box style={{ marginTop: 20 }}>
                          <Alert severity="info">
                            <AlertTitle>PRODUTO COM ESTOQUE BAIXO</AlertTitle>
                            Entre em contato com o suporte para liberar no <strong>Venda Fácil!</strong>
                          </Alert>
                        </Box>
                      )}

                      {tamanhoProduto && (
                        <div style={{ marginTop: 5 }}>
                          <Divider style={{ marginBottom: 5 }} />
                          <ItemHeaderStep
                            title="Escolha a filial"
                          />


                          <Grid container style={{ overflowY: 'auto', paddingTop: 15 }} spacing={2}>
                            {filiaisProdutos.map((data, index) => {
                              return (<>{verificaEstoqueFilial(data)}</>)
                            })}


                          </Grid>

                          {loadingVerificaPreco && (
                            <Stack sx={{ mt: 2, mb: 1 }}>
                              <LinearProgress />
                            </Stack>
                          )}

                        </div>

                      )}



                      <Grid container>
                        <Grid item md={12}>
                          {outrosProdutos.length > 0 ? (
                            <Grid container style={{ overflowY: 'auto' }} justify="center" spacing={2}>
                              <Grid item md={12}>
                                <div className="head-secondary">
                                  <h4>Este produto, em outras filiais</h4>
                                </div>
                              </Grid>
                              {outrosProdutos.map((data, index) => {
                                return (
                                  <Grid key={index} onClick={() => handleEscolheuFilial(data)} style={{ padding: 10, fontWeight: 'bold', fontSize: 17, color: '#333', margin: 10, background: '#fff', borderRadius: 10 }} item md={4} lg={4}>
                                    <div style={{ fontSize: 12 }}>Filial: {data.cod_filial}</div>
                                    <div style={{ fontSize: 12 }}>Estoque: {data.estoque}</div>
                                  </Grid>
                                )
                              })}

                            </Grid>
                          ) : (
                            <div></div>
                          )}
                          {filialProduto &&
                            <>
                              {
                                isConfereEstoqueLoja ? (
                                  <>
                                    <Grid style={{ marginTop: 10 }} container>
                                  

                                      <Stack sx={{mb:3, mt:2}}>
                                        <Typography sx={{fontSize:10}}>Preço à vista</Typography>
                                        <Stack direction="row" alignItems={'center'} spacing={1}>
                                          <Typography style={{ textAlign: 'left' }} alignCenter variant="caption" gutterBottom>R$</Typography>
                                          <Typography style={{ textAlign: 'left' }} alignCenter variant="h4" gutterBottom><strong>{numeral(precoProduto * 0.9).format('0,0.00')}</strong></Typography>
                                        </Stack>

                                      </Stack>

                                      {produtoAbaixoEstoque && (
                                        <Grid item md={12}>
                                          <FormControlLabel
                                            control={<Checkbox checked={isProdutoApto} onChange={handleChangeIsProdutoApto} name="checkedA" />}
                                            label={`Concordo que verifiquei o produto na filial ${filialProduto} e está apto para ser vendido`}
                                          />
                                        </Grid>
                                      )}

                                      {produtoAbaixoEstoque && (
                                          <Button fullWidth disabled={isProdutoApto ? false : true} viariant="contained" sx={{ color: isProdutoApto ? '#fff' : '#333', background: isProdutoApto ? '#007F00' : '#ccc', mt:2, height:60 }} onClick={() => Teste(verProduto)}>  Adicionar no Carrinho </Button>
                                      )}



                                

                                      {!produtoAbaixoEstoque && (
                                          <Button fullWidth viariant="contained" sx={{ color: '#fff', background: '#007F00', mt:3 }} onClick={() => Teste(verProduto)}> <i className='fa fa-shopping-basket mr-2' /> Adicionar no Carrinho </Button>
                                      )}
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Button fullWidth viariant="contained" sx={{ color: '#fff', background: '#007F00', marginTop: 3 }} onClick={() => Teste(verProduto)}> Adicionar no Carrinho </Button>
                                  </>
                                )
                              }

                            </>
                          }
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>

                </Grid>
              </Stack>
            </Stack>
          )}
        </div>
      </Dialog>
    </>
  )

}


export default Produtos
