import axios from "axios";
import Config from "../../config/config";

const getListaPedidosByStatus = data => {
    return axios({
        method: "post",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/relatorios/vendas/getListaPedidosByStatus",
        data
    });
};

export default getListaPedidosByStatus;
