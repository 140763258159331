import axios from "axios";
import Config from "../../config/config";

const criarCliente = data => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.api_dev : Config.api_prod}/api/v1/apps/pdv/clientes/cadastrar`,
        data
    });
};

export default criarCliente;
