import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(theme => ({
  '@global': {
    '*::-webkit-scrollbar': {
        backgroundColor:'#fff',
        width:'16px'
    },
    '*::-webkit-scrollbar-track': {
        backgroundColor:'#fff'
    },
    '*::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: '16px',
        border:'5px solid #fff'
    },
  },
  card: {
    maxWidth: 190,
  },
  boxSelecioneTamanho:{

  },
  container: {
   margin:10
  },
  grid: {
    justifyContent: 'center'
  },
  h3: {
    color: "#192a56"
  },
  circleIconColor: {
    marginLeft:"1em",
    color: 'green'
  },
  linkCustom: {
    textDecoration: 'none'
  },
  fabColor: {
    background: '#02aefe'
  },
  textField: {
    width: 200,
  },
  buttonColor:{
    background: 'linear-gradient(120deg, #fe924e 30%, #f96504 90%)'
  },
  labelSmall: {
 fontSize:11  
  },
  valueMedium: {
    fontSize:18
     },
  boxInfoLeft: {

  },
  imageProductMain: {
   width:'50%',
   objectFit:  'cover',
   backgroundSize: 'cover',
   backgroundRepeat: 'no-repeat',
   backgroundPosition: 'center'

  },

  inputBusca:{
    width:'100%'

  },

  ImagemPrincipal:{
    width: '80%',
    objectFit:  'cover',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    ['@media (max-width:1180px)']: { // eslint-disable-line no-useless-computed-key
      width: '50%'
    }  
  },

  imageProductThumb: {
    maxHeight:100,
    maxWidth:100,
    padding:20
   },

   imageProduct: {
    width:'100%',
    height:'100%',
    padding:20,
    objectFit:'cover'
   },
  boxInfoRight: {
    //background:'#f5f5fb',
   // paddingLeft:20,
    },
  bigAvatar: {
    margin: 10,
    width: 300,
    height: 300,
  },
  containerModalLoading:{
    width:'100vw',
height: '100vh',
display: 'flex',
flexDirection: 'row',
justifyContent: 'center',
alignItems: 'center'
  }
}));
