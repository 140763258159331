import axios from "axios";
import Config from "../../config/config";

const listaCodigoFiliais = () => {
    return axios({
        method: "get",
        url: "https://api.katuxa.com.br/api/v1/backoffice/cadastro/filiais/filial/lista-codigo-filiais"
    });
};

export default listaCodigoFiliais;
