import axios from "axios";
import config from "../../config/config";

const CriarPedido = data => {
    return axios({
        method: "post",
        url: `${config.dev ? config.api_dev : config.api_prod}/api/v1/apps/pdv/pedidos/pedido/criar`,
        data
    });
};

export default CriarPedido;
