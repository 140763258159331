import React from 'react'
import { Container, Grid, Paper, Typography } from '@mui/material';
import HomePage from '../Page/HomePage';

function Introduction() {
  return (
    <>
      <HomePage />
    </>
  )
}

export default Introduction
