import React from "react";

import { makeStyles } from '@mui/styles';

import {StepConnector, StepLabel, Step, Stepper} from "@mui/material";

// const ColorlibConnector = makeStyles({
//   alternativeLabel: {
//     top: 18
//   },
//   active: {
//     "& $line": {
//       backgroundImage:
//         "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)"
//     }
//   },
//   completed: {
//     "& $line": {
//         backgroundImage: 'linear-gradient( 136deg, rgb(4, 233, 104) 0%, rgb(37, 183, 94) 50%, rgb(35, 138, 80) 100%)'
//     },
//     background:"#f03"
//   },
//   line: {
//     height: 2,
//     border: 0,
//     backgroundColor: "#eaeaf0",
//     borderRadius: 1
//   },
// })(StepConnector);




function getSteps() {
  return ["Produto", "Frete", "Checkout", "Conclusão"];
}

export default function CustomizedSteppers({valorStep}) {
  const steps = getSteps();

  return (

      <Stepper
        alternativeLabel
        activeStep={valorStep}
        //connector={<ColorlibConnector />}
        style={{paddingTop:0, paddingBottom:0, width:'100%'}}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

  );
}
