import axios from "axios";
import Config from "../../config/config";

const editAfterSplit = data => {
    return axios({
        method: "post",
        url:`https://api.katuxa.com.br/api/v1/apps/pdv/pedidos/editAfterSplit`,
        data
    });
};

export default editAfterSplit;





