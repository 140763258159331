import axios from "axios";

const actionConfigVendaFacil = () => {
    return axios({
        method: "get",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/config/vendafacil"
    });
};

export default actionConfigVendaFacil;
