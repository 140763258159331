import React from 'react'
import OrderPage from '../Page/OrderPage'

const Order = ({search}) => {

  return(
    <div style={{background:"#f9f9f9"}}> 
            <OrderPage search={search} />
    </div>
  )
}

export default Order
