import axios from "axios";
import Config from "../../config/config";

const editarCliente = (clienteId, data) => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.api_dev : Config.api_prod}/api/v1/apps/pdv/clientes/${clienteId}/editar`,
        data
    });
};

export default editarCliente;
