import { withRouter, Link } from "react-router-dom";

import PropTypes from 'prop-types';
import Slider from 'react-slick';

import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import NumberFormat from 'react-number-format';

import {
  Grid,
  Avatar,
  Typography,
  LinearProgress,
  Paper,
  Box
} from '@material-ui/core';

import CircularProgress from '@material-ui/core/CircularProgress';

import useResponsive from '../../utils/useResponsive';

import Products from "../../components/Products";
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};



const ITEM_HEIGHT = 68;
const ITEM_PADDING_TOP = 8;


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function OrderPage({ props }) {

  const [open, setOpen] = React.useState(false);


  const [grade, setGrade] = useState([]);
  const [griffe, setGriffe] = useState([]);
  const [filial, setFilial] = useState([]);
  const [grupo, setGrupo] = useState([]);
  const [subgrupo, setSubgrupo] = useState([]);

  const [dataUser, setDataUser] = useState('');
  const loading = useSelector(state => state.loading);
  const search = useSelector(state => state.search);

  const isDesktop = useResponsive('up', 'md');

  const carouselRef = useRef(null);

  const settings = {
    dots: false,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    //  rtl: Boolean(theme.direction === 'rtl'),
    responsive: [
      {
        //breakpoint: theme.breakpoints.values.lg,
        settings: {
          slidesToShow: 3
        }
      },
      {
        //  breakpoint: theme.breakpoints.values.md,
        settings: {
          slidesToShow: 2
        }
      },
      {
        //  breakpoint: theme.breakpoints.values.sm,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  function BookingItem({ item }) {
    const { avatar, name, roomNumber, bookdAt, person, cover, roomType } = item;

    return (
      <Paper style={{ borderRadius: 2 }}>
        {/* <Stack spacing={2.5} sx={{ p: 3, pb: 2.5 }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Avatar alt={name} src={avatar} />
            <div>
              <Typography variant="subtitle2">{name}</Typography>
              <Typography variant="caption" sx={{ color: 'text.disabled', mt: 0.5, display: 'block' }}>
                {fDateTime(bookdAt)}
              </Typography>
            </div>
          </Stack>
  
          <Stack direction="row" alignItems="center" spacing={3} sx={{ color: 'text.secondary' }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Icon icon={roundVpnKey} width={16} height={16} />
              <Typography variant="caption">Room {roomNumber}</Typography>
            </Stack>
  
            <Stack direction="row" alignItems="center" spacing={1}>
              <Icon icon={peopleFill} width={16} height={16} />
              <Typography variant="caption">{person} Person</Typography>
            </Stack>
          </Stack>
        </Stack> */}

        <Box style={{ padding: 1, position: 'relative' }}>

          <Box component="img" src={cover} style={{ borderRadius: 1.5, width: 100 }} />
        </Box>
      </Paper>
    );
  }




  const handleGriffe = (griffe) => {
    if (griffe !== null) {
      setGriffe(griffe.map((item) => item.value));

    }
  };

  const handleFilial = (filial) => {
    setFilial(filial ? filial.map((item) => item.value) : []);
  };

  const handleGrupo = (grupo) => {
    if (grupo !== null) {
      setGrupo(grupo.map((item) => item.value))
    }
  };

  const handleSubgrupo = (subgrupo) => {
    if (subgrupo !== null) {
      setSubgrupo(subgrupo.map((item) => item.value));
    }
  };

  const handleGrade = (grade) => {
    if (grade !== null) {
      setGrade(grade.map((item) => item.value));

    }
  };


  const handleClose = () => {
    setOpen(false);
  };




  return (
    <div>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <div style={{ background: '#f0f0f0', height: '100vh' }}>
          <AppBar style={{ backgound: '#333' }}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                <CloseIcon />
              </IconButton>

            </Toolbar>
          </AppBar>

        </div>
      </Dialog>

      {loading === true ? (
        <LinearProgress />
      ) :
        (
          <>

            <Grid style={{ padding: search.length > 0 ? 50 : 10 }} container justifyContent="center">
              {search.length > 0 && (
                <Grid item md={11}>
                  <Typography variant={isDesktop ? "h3" : "caption"}>
                    Resultados encontados para <span style={{ fontWeight: 'bold' }}>"{search}"</span>
                  </Typography>

                </Grid>

              )}

            </Grid>

            <Grid container>
              <Grid item md={12}>
                <Products style={{ marginTop: 50 }} />
              </Grid>
            </Grid>

          </>
        )}
    </div>
  )
}
export default withRouter(OrderPage)
