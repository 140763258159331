import axios from "axios";
import Config from "../../config/config";

const listaProdutosPrecosFilial = data => {
    return axios({
        method: "post",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/produtos/precos",
        data
    });
};

export default listaProdutosPrecosFilial;
