import axios from "axios";
import Config from "../../config/config";

const getListaPedidosByStatus2 = data => {
    return axios({
        method: "post",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/relatorios/vendas/getListaPedidosByStatus2",
        data
    });
};

export default getListaPedidosByStatus2;
