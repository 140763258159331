export const actionTypes = {
    addToCart: "ADD_TO_CART",
    removeFromCart: "REMOVE_FROM_CART",
    addItemQty: "ADD_ITEM_QTY",
    removeItemQty: "REMOVE_ITEM_QTY",
    addWishlist: "ADD_TO_WISHLIST",
    carregarProdutos: "TODOS_PRODUTOS",
    dadosPessoais: "DADOS_PESSOAIS",
    dadosEntrega: "DADOS_ENTREGA",
    dadosFrete: "DADOS_FRETE",
    carrinho: "CARRINHO",
}