import axios from "axios";

const cotarCorreios = data => {
    return axios({
        method: "post",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/global/correios/cotar",
        data
    });
};

export default cotarCorreios;
