import React, { Component } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux';
import {

    Grid,
    Box,
    Divider,
    Typography,
    Stack
} from "@mui/material";
//constants
import Actions from '../constants/Actions';
//actions
import { removeFromCart, addItemQty, removeItemQty } from '../actions/index';
import Iconify from '../components/Iconify';
import "./Cart.css";
//components
import CartItemResumo from './CartItemResumo';
import { validaTotalGeral } from '../utils/functions';


const ItemSumary = ({ title, value }) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography>{title}</Typography>
            <Typography sx={{ fontWeight: '700' }}>{value}</Typography>
        </Stack>

    )
}

const CartResumo = ({ IrFrete }) => {
    const cartItems = useSelector(state => state.cartItems)
    const valorFrete = useSelector(state => state.valorFrete);
    const valorDesconto = useSelector(state => state.valorDesconto);
    const dispatch = useDispatch();

    const removerProdutoCarrinho = (product) => {
        dispatch(removeFromCart(product))
    }

    // const removerProdutoCarrinho = (product) => {
    //     window.location.reload();
    // }


    const adicionarQtdItemCarrinho = (product) => {
        dispatch(addItemQty(product))
    }

    const removerQtdItemCarrinho = (product) => {
        dispatch(removeItemQty(product))
    }

    return (
        <div style={{ margin: 10 }}>
            <Grid container>
                <Grid item md={12} sm={12}>
                    <Grid container spacing={1}>
                        {
                            cartItems.length > 0 ? cartItems.map((cartItem) => (
                                <CartItemResumo key={cartItem._id} cartItem={cartItem} removeFromCart={removerProdutoCarrinho} addItemQty={adicionarQtdItemCarrinho} removeItemQty={removerQtdItemCarrinho} />

                            ))
                                :
                                (
                                    <Stack alignItems={"center"} justifyContent={"center"}>
                                        <Iconify icon="mdi:cart-remove" color="#000" height="100" />
                                        <Stack direction={'row'} sx={{ justifyContent: 'center', p: 3 }}><Typography textAlign={'center'}>Sem Produtos no carrinho</Typography></Stack>

                                    </Stack>
                                )}
                    </Grid>
                    <Divider />
                </Grid>

                <Box sx={{ width: '100%', mt: 3 }}>
                    <Stack direction={"column"} spacing={2}>
                        <ItemSumary
                            title={'Subtotal'}
                            value={`R$${cartItems.reduce((acc, { regular_price, quantity_inc }) => {
                                let qty = 1;
                                let item = parseFloat(regular_price).toFixed(2) * 0.9
                                let accumulator = parseFloat(acc).toFixed(2)
                                let res = parseFloat(item) * qty + parseFloat(accumulator)
                                return parseFloat(res).toFixed(2)
                            }, 0)}`}
                        />
                        <ItemSumary
                            title={'Subtotal'}
                            value={`R$ ${valorDesconto}`}
                        />
                        <ItemSumary
                            title={'Frete'}
                            value={`R$ ${valorFrete}`}
                        />
                        <ItemSumary
                            title={'Frete'}
                            value={`R$ ${validaTotalGeral(cartItems, valorFrete, valorDesconto)}`}
                        />

                    </Stack>
                </Box>



            </Grid>
        </div>
    )

}

export default CartResumo;
