import axios from "axios";
import Config from "../config/config";

const loginByUser = data => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.api_dev : Config.api_prod}/api/v1/auth/login`,
         data
    });
};

export default loginByUser;
