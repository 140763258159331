import React from 'react';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import { createStore } from "redux";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Login from './Component/Main/Login'
import Dashboard from './Component/Main/Dashboard'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {Provider} from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import "./css/style.css";

import reducer from "./reducers_dev";
import ThemeConfig from './theme';
import ThemePrimaryColor from './components/ThemePrimaryColor';
const store = createStore(reducer);

const AppRouter = () => (
  <ThemeConfig>
  <ThemePrimaryColor>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
  <Provider store={store}>
  <Router>
    <ToastContainer />
    <div>
      <Route exact path="/" component={Login} />
      <Route path="/login" component={Login} />
      <Route path="/pdv" component={Dashboard} />
    </div>
  </Router>
  </Provider>
</LocalizationProvider>
  </ThemePrimaryColor>
    </ThemeConfig>
)

export default AppRouter;
