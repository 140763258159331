import axios from "axios";

const actionBuscarFiliaisCidades = (filial) => {
    return axios({
        method: "get",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/config/buscar-filiais-cidades?filial="+filial
    });
};

export default actionBuscarFiliaisCidades;
