import axios from "axios";
import Config from "../../config/config";

const afterSaleZoop = data => {
    return axios({
        method: "post",
        url:`https://api.katuxa.com.br/api/v1/apps/pdv/pedidos/afterSaleZoop`,
        data
    });
};

export default afterSaleZoop;





