import axios from "axios";

const editStatusOrder = data => {
    return axios({
        method: "post",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/pedidos/editStatusOrder",
        data
    });
};

export default editStatusOrder;
