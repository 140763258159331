import React from 'react'
import { Typography, Stack } from '@mui/material';

const RowSimple = ({ label, value }) => {
    return (
        <Stack>
            <Typography sx={{fontSize:9}}>{label}</Typography>
            <Typography sx={{fontSize:12, fontWeight:'bold'}}>{value}</Typography>
        </Stack>
    )
}

export default RowSimple;
