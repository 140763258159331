import React, { PureComponent, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { FiPower, FiTrash2, FiShoppingBag, FiCheckSquare } from 'react-icons/fi'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Box, Card, Button } from '@material-ui/core';

import { FiArrowLeft } from 'react-icons/fi';

import Divider from "@material-ui/core/Divider";

import moment from "moment";

import { formatDistanceToNow, format } from 'date-fns';


function validaStatus(string) {
  if (string === "60774fa684e6861cb41a472f") {
    return "Pendente Pagamento";
  } else if (string === "6143439585ee0369a5dfacbc") {
    return "Cancelado";
  } else if (string === "60774f9c84e6861cb41a472e") {
    return "Pedido Feito";
  } else if (string === "6143412285ee0369a5dfacb4") {
    return "Aceito";
  } else if (string === "60774fb684e6861cb41a4730") {
    return "Pag. Aprovado";
  } else if (string === "6143414c85ee0369a5dfacb5") {
    return "Expedição";
  } else if (string === "resEditarCliente") {
    return "Backoffice";
  }
}

export const CardPedidosPendentes = ({ item, onAction, click }) => (
  <Card sx={{ p: 2, width: '100%' }}>
    <div onClick={click} style={{ backgroundColor: item.prioridade == 'urgente' ? '#ffc8bc' : 'white' }} >
      <Box style={{ padding: 15 }}>
        <Typography><strong>#{item.number}</strong> -  <span style={{ fontSize: 12 }}>{moment(item.date_created).format('DD/MM')}</span></Typography>
        <Typography>{item.trans_type == 'delivery' ? "Entrega" : "Retirada"}</Typography>
      </Box>

      <Divider variant="middle" />
      <Grid container style={{ padding: 10 }} alignContent={'center'} alignItems={'center'} justify={'center'} >
        <Grid item xs={12} sm={12}>
          <Typography style={{ fontWeight: 'bold' }} variant="subtitle2"> {item.first_name.substr(0, 12)}{item.first_name.length > 12 && <strong style={{ fontSize: 20 }}>....</strong>}</Typography>
        </Grid>
      </Grid>

      <Divider variant="middle" />

      <Grid container style={{ paddingTop: 10 }} alignContent={'center'} alignItems={'center'} justify={'center'} >
        <Grid item xs={10} sm={10}>
          <Typography variant="caption">{validaStatus(item.status_id)}</Typography>
        </Grid>
      </Grid>
    </div>

    <Grid container>
      <div className="seperador-top"></div>
      <Grid item xs={12} sm={12}>

        <div className="margin-top-10"> </div>
        <Button variant='contained' sx={{ mt: 2 }} startIcon={<FiCheckSquare />} onClick={onAction}>
        Separar Pedido
        </Button>
      </Grid>
    </Grid>
  </Card>

)









