import React from 'react'

import { Box, Divider, Typography, Grid, Card } from '@material-ui/core'

import moment from "moment";
import { validaStatus } from '../../utils/functions';

export const CardPedidosAceitos = ({ item, onAceitarPedido, onCancelarPedido, click })=> (
   <Card sx={{ p: 2, width: '100%' }}>
   <div onClick={click} style={{ backgroundColor: item.prioridade == 'urgente' ? '#ffc8bc' : 'white' }} >
   <Box style={{padding:10}}>
            <Typography><strong>#{item.number}</strong> -  <span style={{fontSize:12}}>{moment(item.date_created).format('DD/MM')}</span></Typography>
            <Typography>{ item.trans_type == 'delivery' ? "Entrega" : "Retirada"}</Typography>
         </Box>       
   
         <Divider variant="middle" />
         <Grid container style={{paddingTop:10, paddingBottom:10}} alignContent={'center'} alignItems={'center'} justify={'center'} >
            <Grid item xs={10} sm={10}>
            <Typography style={{fontWeight:'bold'}} variant="subtitle2"> {item.first_name.substr(0, 12)}{item.first_name.length > 12 && <strong style={{fontSize:20}}>....</strong>}</Typography>
            </Grid>
         </Grid>
         <Divider variant="middle" />
         <Grid container style={{paddingTop:10}} alignContent={'center'} alignItems={'center'} justify={'center'} >
            <Grid item xs={10} sm={10}>
            <Typography variant="caption">{validaStatus(item.status_id)}</Typography>
            </Grid>
         </Grid>
</div> 
</Card>

)









