import React from 'react'
import QRCode from 'react-qr-code'

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Button,
    Grid,
    Typography,
    Container, Alert

} from "@mui/material";


const PaymentModalMoovpay = ({ open, onClose, qrcode }) => {


    return (

        <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title"></DialogTitle>
            <DialogContent>
                <Grid maxWidth={"md"} item >
                    <Container style={{ textAlign: 'center', alignContent: 'center' }}>
                        <Typography variant="h6" paragraph>
                            Escaneie o QRCode
                        </Typography>
                        <Grid item>
                            <QRCode
                                size={256}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                value={qrcode}
                                viewBox={`0 0 256 256`}
                            />

                        </Grid>

                        <Alert style={{ marginTop: 2 }} severity="warning">Efetue o pagamento o quanto antes</Alert>
                        <Grid container spacing={3} justifyContent="center" alignContent="center" alignItems="center" style={{ marginTop: 20 }}>
                        </Grid>
                    </Container>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>

    )

}

export default PaymentModalMoovpay;
