import React from 'react';
import { Typography, Box } from '@material-ui/core';


const ItemWithLabel = ({ label, value }) => {
    return (
        
     <Box>
        <Typography style={{fontSize:8}}>{label}</Typography>
        <Typography style={{fontWeight:'bold', fontSize:12}}>{value}</Typography>
     </Box>
      
    )
}

export default ItemWithLabel;
