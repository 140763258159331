import axios from "axios";
import Config from "../../config/config";

const adicionarProdutoBySync = data => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.api_dev : Config.api_prod}/api/v1/sync/vendafacil/inserir-produto`,
        data
    });
};

export default adicionarProdutoBySync;
