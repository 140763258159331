import React from 'react'
import clsx from 'clsx'
import { Container, Grid, Paper } from '@material-ui/core';

import { useStyles } from '../Style/StyleAdmin';
import ShippingPage from '../Page/ShippingPage'
import FilterPage from '../Page/FilterPage'
import NoActivity from '../Page/NoActivity'
import Cart from '../../components/Cart'
import CadastroPage from '../Page/CadastroPage';
import { useHistory } from 'react-router-dom';



function Cadastro() {
  const classes = useStyles();
  const history = useHistory();

  const irPagamento = () => {
    history.push('pagamento');
    console.log('foi')
    
  };

  return(
    <div style={{background:'#f0f0f0', margin:10}}> 

      <Grid container spacing={3}>
        {/* Product */}
        <Grid item xs={12} md={9} lg={9}>
        
            <CadastroPage />
    
        </Grid>
 
        {/* Activity */}
        <Grid item xs={12} md={3} lg={3}>
          <Paper className={clsx(classes.paper, classes.fixedHeight)}>
          <Cart IrFrete={irPagamento}  />

          {/* <NoActivity/> */}
          </Paper>
        </Grid>
      </Grid>

    </div>
  )
}

export default Cadastro;
