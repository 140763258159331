import React, { Fragment, useEffect, useState } from 'react'

import { BiStore } from 'react-icons/bi';
import { FaShippingFast } from 'react-icons/fa';
import { FiArrowLeftCircle } from 'react-icons/fi';

import { useHistory } from "react-router-dom";
import { Card, Typography, Stack, Box, Container, IconButton, Button, Radio, Grid, TextField } from '@mui/material';

import { format } from 'date-fns';
import { useDispatch, useSelector } from "react-redux";
import Actions from "../../constants/Actions";


import { getCepUser, dataUser, getDataUsuario } from "../../services/auth";
// actions
import buscaCep from "../../actions/buscaCep";
import cotarCorreios from '../../actions/global/cotarCorreios'

//components
import Stepper from "../../components/Stepper";
import Skeleton from '@material-ui/lab/Skeleton';
import { getValorSubTotal, pesoCalculado, maiorLarguraItems, maiorComprimentoItems, alturaCalculado } from '../../utils/functions';

// actions
import buscarFilialPorCodigoFilial from '../../actions/cadastro/buscar-filial-por-codigo-filial';
import actionConfigVendaFacil from '../../actions/config/actionConfigVendaFacil';
import actionBuscarFiliaisCidades from '../../actions/config/actionBuscarFiliaisCidades';


const ShippingPage = ({ proximoPasso }) => {

  const [tipoEntrega, setTipoEntrega] = useState('');
  const [transportadora, setTransportadora] = useState(null);

  const [cepProduto, setCepProduto] = useState('');

  const [fretePatrus, setFretePatrus] = useState('19.90');
  const [freteCorreios, setFreteCorreios] = useState("");
  const [freteCorreiosSedex, setFreteCorreiosSedex] = useState("");
  const [mostrarFretesEntregas, setMostrarFretesEntregas] = useState(false);
  const [isLoadingFrete, setIsLoadingFrete] = useState(false);
  const [cepDigitado, setCepDigitado] = useState('');
  const [diasEntrega, setDiasEntrega] = useState('');
  const [valorFreteRetirada, setValorFreteRetirada] = useState(0);
  const [config, setConfig] = useState(null);
  const [entregaBloqueada, setEntregaBloqueada] = useState(false);
  const [retiradaGratis, setRetiradaGratis] = useState(false);
  // cep
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');

  const cepOrigemProduto = useSelector(state => state.cepOrigemProduto);
  const cartItems = useSelector(state => state.cartItems);
  const valorFrete = useSelector(state => state.valorFrete);
  const filialUsuario = getDataUsuario().filial


  const [subtotal, setSubtotal] = React.useState(0)
  const date = new Date();

  const dispatch = useDispatch();
  const history = useHistory();

  async function buscarConfig() {
    try {
      const res = await actionConfigVendaFacil();
      setConfig(res.data.data.vendafacil);
      return res.data.data.vendafacil;

    } catch (err) {
      console.log('Erro ao buscar config', err)
    }
  }


  async function validaEntregaFranquia() {
    const res_filiais_cidades = await actionBuscarFiliaisCidades(filialUsuario);
    console.log('res_filiais_cidades', res_filiais_cidades.data.data)
    if (!res_filiais_cidades.data.data) return;
    console.log('apos res_filiais_cidades', res_filiais_cidades)
    const res_config = await buscarConfig();
    const filiaisCidades = res_filiais_cidades.data.data.filiais;
    let isFilialImpedida = res_config.filais_impedidas_entrega.includes(filialUsuario) // verifica sa loja do usuario esta impedida de fazer envios na cidade

    if (!isFilialImpedida) return;

    if (!cartItems) return;
    if (cartItems.length === 0) return;
    if (!res_config) return;
    let filiaisCarrinho = cartItems.map(i => i.filial);
    if (filiaisCidades.some(valor => filiaisCarrinho.includes(valor))) {
      console.log('filiaisCarrinho.includes(filiaisCidades)', filiaisCarrinho.includes(filiaisCidades))
      setEntregaBloqueada(true);
      setRetiradaGratis(true)
      setValorFreteRetirada(0)
    } else {
      console.log('erro', filiaisCarrinho, filiaisCidades)
    }
  }




  useEffect(() => {
    if (cartItems.length == 0) {
      alert('Seu Carrinho está vazio, você será redirecionado para a página inicial')
      history.push('/');
    }
    validaEntregaFranquia()

    buscarFilialPorCodigoFilial(cepOrigemProduto).then(res => {
      setCepProduto(res.data.cep)
    })

  }, []);








  const handleBuscaCep = async (zipCode) => {
    try {
      const address = await buscaCep(zipCode)
      if (address.erro) {
        alert('CEP ano encontrado!')
      } else {
        setCidade(address.localidade);
        setEstado(address.uf);
        return address;
      }
    } catch (address) {
      alert('CEP ano encontrado!');
    }
  }



  const handleBuscarFreteEntrega = async (e) => {
    e.preventDefault();
    setIsLoadingFrete(true);
    setMostrarFretesEntregas(true)

    const response = await handleBuscaCep(cepDigitado);


    cotarCorreios({
      altura: alturaCalculado(cartItems),
      comprimento: maiorComprimentoItems(cartItems),
      largura: maiorLarguraItems(cartItems),
      peso: pesoCalculado(cartItems),
      cepOrigem: cepProduto,
      cepDestino: cepDigitado
    }
    ).then(res => {
      const freteInt = parseInt(res.data.result.sedex.Valor);
      if (getValorSubTotal(cartItems) > 199) {
        if (response.uf === 'MG') {

          setFreteCorreios(0);
          setFreteCorreiosSedex(0);
          setIsLoadingFrete(false);




        } else {
          if (res.data.result.sedex.Valor < 1) {
            setFreteCorreios(20);
            setFreteCorreiosSedex(20);
            setIsLoadingFrete(false);
          } else {

            setFreteCorreios(res.data.result.sedex.Valor.replace(',', '.'));
            setFreteCorreiosSedex(res.data.result.pac.Valor.replace(',', '.'));
            setIsLoadingFrete(false);
          }



        }
      } else {

        if (freteInt === 0) {
          setFreteCorreios(20);
          setFreteCorreiosSedex(20);
          setIsLoadingFrete(false);
        } else {

          setFreteCorreios(res.data.result.sedex.Valor.replace(',', '.'));
          setFreteCorreiosSedex(res.data.result.pac.Valor.replace(',', '.'));
          setIsLoadingFrete(false);
        }

      }
    }).catch(e => {
      setFreteCorreios(20);
      setFreteCorreiosSedex(20);
      setIsLoadingFrete(false);
      alert('Erro ao calcular o frete, entre em contato com o suporte para averiguar o erro ou tente novamente.')
    })

  }

  const handleRetirada = () => {
    if (retiradaGratis) {
      setValorFreteRetirada(0);
      dispatch({ type: Actions.TIPO_ENTREGA, tipoEntrega: "Retirada" });
      setTipoEntrega('Retirada');
      setTransportadora('')
      return;
    }
    if (getCepUser() >= 35500000 & getCepUser() <= 35505000) {
      setValorFreteRetirada(0)
    } else {
      if (getValorSubTotal(cartItems) < 149) {
        setValorFreteRetirada(20)
      } else {
        setValorFreteRetirada(0)
      }
    }
    dispatch({ type: Actions.TIPO_ENTREGA, tipoEntrega: "Retirada" });
    setTipoEntrega('Retirada');
    setTransportadora('')
  }

  const handleEntrega = () => {
    setTipoEntrega('Entrega');
    dispatch({ type: Actions.TIPO_ENTREGA, tipoEntrega: "Entrega" });
    setTransportadora('')
  }





  const handleEscolhePac = () => {
    setTransportadora('Pac');
    dispatch({ type: Actions.valorFrete, valorFrete: Number.parseFloat(freteCorreios) });
    dispatch({ type: Actions.SHIPPING_ID, shippingId: "61532d990d253dd8a450bcde" });


  }


  const handleEscolhePatrus = () => {
    setTransportadora('Patrus');
    dispatch({ type: Actions.SHIPPING_ID, shippingId: "61560313eaf72f4fe7f5ee25" });
    dispatch({ type: Actions.valorFrete, valorFrete: fretePatrus.ValorFrete });

  }


  const handleEscolhePropria = () => {

    if (retiradaGratis) {
      setValorFreteRetirada(0);
      dispatch({ type: Actions.TIPO_ENTREGA, tipoEntrega: "Retirada" });
      setTipoEntrega('Retirada');
      setTransportadora('Total');
      dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
      dispatch({ type: Actions.SHIPPING_ID, shippingId: "61560325eaf72f4fe7f5ee27" });
      return;
    }
    setTransportadora('Total');
    dispatch({ type: Actions.SHIPPING_ID, shippingId: "61560325eaf72f4fe7f5ee27" });
    dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })

    if (tipoEntrega === "Retirada") {
      if (getCepUser() >= 35500000 & getCepUser() <= 35505000) {
        dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
      } else {
        if (getValorSubTotal(cartItems) < 149) {
          dispatch({ type: Actions.valorFrete, valorFrete: 20.00 })
        } else {
          dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
        }
      }
    }


    if (tipoEntrega === 'Entrega') {
      if (cepDigitado >= 35500000 & cepDigitado <= 35505000) {
        if (getValorSubTotal(cartItems) < 199) {
          dispatch({ type: Actions.valorFrete, valorFrete: 14.00 })
        } else {
          dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
        }

      } else {
        if (getValorSubTotal(cartItems) < 199) {
          dispatch({ type: Actions.valorFrete, valorFrete: 20.00 })
        } else {
          dispatch({ type: Actions.valorFrete, valorFrete: 0.00 })
        }
      }

    }

  }

  const validaEntregaPropria = () => {
    if (cepDigitado >= 35500000 & cepDigitado <= 35505000) {
      if (getValorSubTotal(cartItems) < 199) {
        return 14.00;
      } else {
        return 0.00
      }

    } else {
      if (getValorSubTotal(cartItems) < 199) {
        return 18.00;
      } else {
        return 0.00
      }
    }
  }


  const voltarPdv = () => {
    history.push('pdv');
    dispatch({ type: Actions.valorFrete, valorFrete: 0 });
  }

  const CardTransportadora = ({ click, img, tipoEntrega, titulo, valorFrete, dataMaxima, valorItens }) => {

    function valorRestante() {
      if (valorItens < 149) {
        let total = 150 - valorItens;
        return total.toFixed(2);
      }
    }

    return (
      <Card sx={{ p: 3 }}>


        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <Radio
              checked={transportadora === 'Total'}
              value={transportadora}
              name="radio-button-demo"

            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={8} xs={12}>
              <p className='text-black'>{titulo}</p>
            </Grid>
            <Grid item md={4} xs={12}>
              <p>R$ <strong style={{ fontSize: 20 }}>{valorFrete}</strong></p>
            </Grid>
            <Grid item md={12} xs={12}>
              {valorFreteRetirada > 0 && tipoEntrega === 'retirada' && <Typography variant='caption'>Faltam <strong>R${valorRestante(valorItens)}</strong> para o frete ser grátis</Typography>}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    )
  }

  const CardTransportadoraEntrega = ({ click, img, tipoEntrega, titulo, valorFrete, dataMaxima }) => {
    return (

      <>

        <Grid style={{ padding: 10 }} item md={12} xs={12}>
          <Grid container>
            <Grid item md={12} xs={12}>
              <p className='text-black'>{titulo}</p>
            </Grid>
            <Grid item md={12} xs={12}>
              <p>R$ <strong style={{ fontSize: 20 }}>{valorFrete}</strong></p>
            </Grid>
            <Grid item md={12} xs={12}>
              {titulo === 'Total Express' ? (
                <p className=''>Chega até <strong>2 dias úteis após o pagamento</strong></p>
              ) : (
                <p className=''>Chega até <strong>{dataMaxima ? format(new Date(dataMaxima), 'dd/MM') : dataMaxima}</strong></p>
              )}

            </Grid>
          </Grid>

        </Grid>
      </>
    )
  }


  //  format('2021-09-01T09:16:25', 'dd/MM/YYYY HH:mm');



  return (

    <>

      <Box sx={{ width: '100%', background: "#f0f0f0", py: 2 }}>
        <Container fullWidth maxWidth>
          <Stack justifyContent={"space-between"} direction={"row"}>
            <IconButton>
              <FiArrowLeftCircle onClick={voltarPdv} size={45} />
            </IconButton>
            <Stepper valorStep={2} />
          </Stack>
        </Container>
      </Box>

      <Box sx={{ p: 2 }}>

        {/* <Stack sx={{ mt: 2 }} direction="row" spacing={3}>
          <Card sx={{ background: tipoEntrega == 'Retirada' ? '#b6ffca' : '', p: 3 }} onClick={handleRetirada}>
            <Stack spacing={2} direction="row" alignItems={'center'}>
              <BiStore size={30} />
              <Typography>Retirada</Typography>
            </Stack>
          </Card>

          {!entregaBloqueada && (
            <Card sx={{ background: tipoEntrega == 'Entrega' ? '#b6ffca' : '', p: 3 }} onClick={handleEntrega} >
              <Stack spacing={2} direction="row" alignItems={'center'}>
                <FaShippingFast size={30} />
                <Typography>Entrega</Typography>
              </Stack>
            </Card>
          )}

        </Stack> */}

        <Stack>

          {tipoEntrega === 'Entrega' && (

            <>

              <Stack>


                <Stack>
                  <TextField
                    type="text"
                    value={cepDigitado}
                    id="zip"
                    onChange={e => setCepDigitado(e.target.value)}
                    required
                  />
                </Stack>
              </Stack>
              <div className='row' style={{ paddingBottom: 30 }}>
                <div className='col-xl-12 col-xxl-12'>
                  <h1>Calcular Frete para Entrega</h1>
                </div>
              </div>


              <div className="mb-12">

                <form onSubmit={handleBuscarFreteEntrega} noValidate>
                  <div className="row">
                    <div className="mb-4">
                      <div className="col-md-12 mb-12">
                        <label htmlFor="zip">CEP Destino</label>
                        <TextField
                          type="text"
                          value={cepDigitado}
                          id="zip"
                          onChange={e => setCepDigitado(e.target.value)}
                          required
                        />

                      </div>

                    </div>
                    <div className="mb-4">
                      <div className="col-md-12 mb-12">
                        <button type="submit"
                          className='btn rounded btn-danger mt-4 light mr-3 mb-0 mb-md-0 mb-sm-2'>Buscar</button>
                      </div>
                    </div>
                  </div>
                </form>

              </div>

              {mostrarFretesEntregas && (
                <Grid container spacing={1}>


                  {cidade !== "Divinópolis" && (
                    <>
                      {isLoadingFrete === false ? (
                        <Grid item xl={3} md={4} lg={3}>
                          <div style={{ background: transportadora == 'Pac' ? '#ccc' : '' }} onClick={handleEscolhePac} className='card'>
                            <CardTransportadoraEntrega
                              click={handleEscolhePac}
                              img={'https://katuxa.sfo3.digitaloceanspaces.com/logos/correios.png'}
                              tipoEntrega={'correios'}
                              valorFrete={freteCorreios}
                              dataMaxima={(new Date()).setDate(date.getDate() + 8)}
                              titulo={"PAC"}
                            />
                          </div>
                        </Grid>
                      ) : (
                        <Grid item xl={3} md={4} lg={3}>
                          <div style={{ background: '#fff' }} className='card card-body'>
                            <Skeleton variant="circle" width={40} height={40} />
                            <Skeleton variant="text" width={200} height={100} />
                            <Skeleton variant="text" width={200} height={30} />
                            <Skeleton variant="text" width={200} height={30} />
                          </div>
                        </Grid>
                      )}
                    </>
                  )}

                  {cidade === "Divinópolis" && (
                    <>

                      <div style={{ background: transportadora == 'Total' ? '#ccc' : '' }} onClick={handleEscolhePropria} className='card'>
                        <CardTransportadoraEntrega
                          click={() => handleEscolhePatrus()}
                          img={'https://katuxa.sfo3.digitaloceanspaces.com/logos/katuxa.png'}
                          tipoEntrega={'katuxa'}
                          valorFrete={validaEntregaPropria()}
                          dataMaxima={date.setDate(date.getDate() + 2)}
                          titulo={"Entrega Própria"}
                        />
                      </div>

                    </>
                  )}

                  {transportadora && (
                    <Button onClick={proximoPasso}>CONTINUAR</Button>
                  )}

                </Grid>
              )

              }


            </>


          )}

          {tipoEntrega === 'Retirada' && (

            <div className="col-md-12 order-md-12">
              {/* <h2 className="mb-2">Retirada</h2> */}
              <form className="needs-validation" style={{ width: '100%' }} noValidate="">

                <div className="mb-12">
                  <div>

                    <Grid container spacing={2}>
                      <Grid item md={10}>
                        <Grid container>

                          <Grid container>
                            <Grid item md={4} xs={12}>
                              <div style={{ background: transportadora == 'Total' ? '#ccc' : '', border: 1, borderColor: "#f0f0f0", borderWidth: 1, borderStyle: 'solid' }} onClick={handleEscolhePropria} className='card'>
                                <CardTransportadora
                                  click={() => handleEscolhePropria()}
                                  img={'https://katuxa.sfo3.digitaloceanspaces.com/logos/katuxa.png'}
                                  tipoEntrega={'retirada'}
                                  valorFrete={valorFreteRetirada}
                                  dataMaxima={(new Date()).setDate(date.getDate() + 8)}
                                  titulo={"Entrega Katuxa"}
                                  valorItens={getValorSubTotal(cartItems)}
                                />
                              </div>

                            </Grid>
                          </Grid>

                          <Grid container>
                            <Grid item md={4} xs={12}>
                              {transportadora && (
                                <Button
                                  variant="contained"
                                  fullWidth
                                  onClick={proximoPasso}
                                  style={{ background: "#24a247", color: "#fff" }}
                                >
                                  CONTINUAR
                                </Button>
                              )}
                            </Grid>
                          </Grid>

                        </Grid>
                      </Grid>



                    </Grid>

                  </div>
                </div>

              </form>
            </div>
          )}

        </Stack>


      </Box>
    </>

  )
}

export default ShippingPage;
