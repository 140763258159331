import React, {useEffect, useState, useRef} from 'react'
import { withRouter, useParams } from "react-router-dom";
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  LinearProgress,
  Box
} from '@mui/material';
import listaProdutosExclusivos from "../../actions/produtos/lista-produtos-exclusivos";
import Products from "../../components/Products";
import { useSelector, useDispatch } from 'react-redux';
import Actions from "../../constants/Actions";
import Slide from '@material-ui/core/Slide';

import useResponsive from '../../utils/useResponsive';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const ITEM_HEIGHT = 68;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};



// const grupos = [
//   // { value: ["ADVENTURE", "RUNNING", "PERFORMANCE"], label: "Teste" },
//   { value: "ADVENTURE", label: "ADVENTURE" },
//   { value: "BOTA", label: "BOTA" },
//   { value: "CASUAL", label: "CASUAL" },
//   { value: "CHINELO", label: "CHINELO" },
//   { value: "CLOG", label: "CLOG" },
//   { value: "COMPLEMENTO", label: "COMPLEMENTO" },
//   { value: "ESCOLAR", label: "ESCOLAR" },
//   { value: "LIFESTYLE", label: "LIFESTYLE" },
//   { value: "JOGGING", label: "JOGGING" },
//   { value: "MEIA", label: "MEIA" },
//   { value: "OUTROS", label: "OUTROS" },
//   { value: "PANTUFA", label: "PANTUFA" },
//   { value: "PERFORMANCE", label: "PERFORMANCE" },
//   { value: "RUNNING", label: "RUNNING" },
//   { value: "SANDALIA", label: "SANDALIA" },
//   { value: "SAPATENIS", label: "SAPATENIS" },
//   { value: "SAPATILHA", label: "SAPATILHA" },
//   { value: "SAPATO", label: "SAPATO" },
//   { value: "TAMANCO", label: "TAMANCO" },
//   { value: "TENIS", label: "TENIS" },
//   { value: "VIAGEM", label: "VIAGEM" },

// ];

const filiais = [
  { value: "000002", label: "F02" },
  { value: "000004", label: "F04" },
  { value: "000006", label: "F06" },
  { value: "000007", label: "F07" },
  { value: "000009", label: "F09" },
  { value: "000010", label: "F10" },
  { value: "000027", label: "F27" },
  { value: "000061", label: "KTX" },
  { value: "000901", label: "Chinelos.com" },
  { value: "000951", label: "New Tennis Shopping" },
  { value: "000952", label: "New Tennis Centro" },

];

const tamanhos = [
  { value: "00", label: "00" },
  { value: "01", label: "01" },
  { value: "02", label: "02" },
  { value: "03", label: "03" },
  { value: "04", label: "04" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },

];

const MOCK_BOOKINGS = [...Array(5)].map((_, index) => ({
  id: "sjfsdfuhsdbfisbfibsdf",
  name: 'teste',
  avatar: 'https://katuxa.app/images/fotos_pdv_miro/145508_9500_01.jpg',
  bookdAt: new Date(),
  roomNumber: 'A-21',
  roomType: (index === 1 && 'double') || (index === 3 && 'king') || 'single',
  person: '3-5',
  cover: `https://katuxa.app/images/fotos_pdv_miro/145508_9500_01.jpg`
}));




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function HomePage() {


  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const loading = useSelector(state => state.loading);

  const [grupoInput, setGrupoInput] = useState([]);
  const [grupo, setGrupo] = useState([]);

  const [griffe, setGriffe] = useState([]);
  const [griffeInput, setGriffeInput] = useState([]);

  const [filial, setFilial] = useState([]);

  const [subgrupo, setSubgrupo] = useState([]);
  const [subgrupoInput, setSubgrupoInput] = useState([]);

  const [pathOld, setPathOld] = useState("");
  const exclusivo = 'chuteiras';
  const isDesktop = useResponsive('up', 'md');


  const carouselRef = useRef(null);

  const settings = {
    dots: true,
    arrows: true,
    slidesToShow: 4,
    slidesToScroll: 1
  };

    const renderSlides = () =>
    [1, 2, 3, 4, 5, 6, 7, 8].map(num => (
      <div>
        <h3>Slide {num}</h3>
      </div>
    ));





  //params


  useEffect(() => {
    if (pathOld !== exclusivo) {
      setSubgrupo([]);
      setSubgrupoInput([]);
      setGriffe([]);
      setGriffeInput([]);
      setGrupo([]);
      setGrupoInput([]);
    }

  }, [exclusivo]);


  useEffect(() => {
    dispatch({ type: Actions.LOADING, loading: true })
    listaProdutosExclusivos({ atributo: exclusivo }).then(res => {
      if (res.data.produtos.length > 0) {
        dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: res.data.produtos });
        dispatch({ type: Actions.LOADING, loading: false })
      }

      dispatch({ type: Actions.LOADING, loading: false })
    }).catch(err => console.log(err));

  }, []);



  return (
    <div>


      {loading === true ? (
        // <BackdropSincronizando open={loading} />
        <  LinearProgress />
      ) :
        (
          <>

            {isDesktop && (
              <Grid style={{ paddingTop: 50, paddingBottom: 50 }} container justifyContent="center">
                <Grid item md={11}>
                  <Typography variant="h4">
                    exclusivos <span style={{ fontWeight: 'bold', color:"#3a8585" }}>venda fácil</span>
                    {/* <span style={{ fontWeight: 'bold' }}>{exclusivo}</span> */}
                  </Typography>

                </Grid>
    
              </Grid>
            )}
                <Products style={{ marginTop: 50 }} />
          </>
        )}
    </div>
  )
}
export default withRouter(HomePage)
