import axios from "axios";
import Config from "../../config/config"

const actionGerarQrcodeMoovpay = data => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.api_dev : Config.api_prod}/api/v1/apps/pdv/pedidos/moovpay/qrcode`,
        data
    });
};

export default actionGerarQrcodeMoovpay;
 