import axios from "axios";
import Config from "../../config/config";

const transactionZoop = data => {
    return axios({
        method: "post",
        url: Config.baseZoop + `/v1/marketplaces/${Config.dev === true ? Config.marketplace_id_sandbox : Config.marketplace_id_prod}/transactions`,
        data,
        headers: {Accept: 'application/json', 'Content-Type': 'application/json'},
        auth: {
            username: Config.dev === true ? Config.zpk_sandbox : Config.zpk_prod,
            password: ''
        },
       

    });
};

export default transactionZoop;
