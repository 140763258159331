import axios from "axios";
import Config from "../../config/config";

const pedidosByVendedor = data => {
    return axios({
        method: "post",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/relatorios/vendas/pedidosByVendedor",
        data
    });
};

export default pedidosByVendedor;
