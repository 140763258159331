import axios from "axios";
import Config from "../../config/config";

const buscarFilialPorCodigoFilial = codigo_filial => {
    return axios({
        method: "get",
        url: "https://api.katuxa.com.br/api/v1/backoffice/cadastro/filiais/filial/buscar-filial-por-codigo-filial/"+codigo_filial
    });
};

export default buscarFilialPorCodigoFilial;
