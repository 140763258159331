import axios from "axios";
import Config from "../../config/config";

const splitZoop = dataSplit => {
    console.log('chegou no actions', dataSplit)
    return axios({
        method: "post",
        url: Config.baseZoop + `/v1/marketplaces/${Config.dev === true ? Config.marketplace_id_sandbox : Config.marketplace_id_prod}/transactions/${dataSplit.id}/split_rules`,
        data: {
                liable: true,
                charge_processing_fee: true,
                is_gross_amount: true,
                recipient: dataSplit.sellerId,
                amount: dataSplit.valorInCents
          },
        headers: {Accept: 'application/json', 'Content-Type': 'application/json'},
        auth: {
            username: Config.dev === true ? Config.zpk_sandbox : Config.zpk_prod,
            password: ''
        },
       

    });
};

export default splitZoop;
