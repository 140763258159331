import React, { Component } from 'react'

import CartItem from './CartItem'
import { connect, useDispatch, useSelector } from 'react-redux';

import {

    Grid,
    Button,
    IconButton,
    Divider,
    Box,
    Stack,
    Typography
} from "@mui/material";

import { validaTotalGeral } from '../utils/functions';
import CartItemResumo from './CartItemResumo';
import Actions from '../constants/Actions';
import { removeFromCart, addItemQty, removeItemQty } from '../actions/index';
import todosProdutos from "../actions/produtos/todos-produtos";



const ItemSumary = ({ title, value }) => {
    return (
        <Stack direction={"row"} justifyContent={"space-between"}>
            <Typography>{title}</Typography>
            <Typography sx={{ fontWeight: '700' }}>{value}</Typography>
        </Stack>

    )
}


const ItemContinue = ({ cartItems, IrFrete, fecharCarrinho }) => {
    return (
        <Stack spacing={1} alignItems={"center"} direction={"row"} justifyContent={"space-between"}>
            <Button color='error'  onClick={fecharCarrinho} sx={{ height: 40 }} variant='outlined'>FECHAR</Button>
            <Button fullWidth color='primary'  disabled={cartItems.length > 0 ? false : true} sx={{ height: 40, color:"#fff" }} onClick={IrFrete} variant='contained'>CONTINUAR</Button>
        </Stack>
    )
}


const Cart = ({ IrFrete }) => {

    const cartItems = useSelector(state => state.cartItems);
    const dadosPessoais = useSelector(state => state.dadosPessoais);
    const dadosEntrega = useSelector(state => state.dadosEntrega);
    const valorFrete = useSelector(state => state.valorFrete);
    const carrinho = useSelector(state => state.carrinho);
    const dispatch = useDispatch();

    const removerProdutoCarrinho = (product) => {
        dispatch(removeFromCart(product))
    }

    // const removerProdutoCarrinho = (product) => {
    //     window.location.reload();
    // }

    const adicionarQtdItemCarrinho = (product) => {
        dispatch(addItemQty(product))
        console.log('ação add', product)
    }

    const removerQtdItemCarrinho = (product) => {
        dispatch(removeItemQty(product))
        console.log('ação excluir', product)
        console.log('cartItems', cartItems)

    }

    const fecharCarrinho = () => {
        dispatch({ type: Actions.DRAWER_CARRINHO, drawerCarrinho: false });
        todosProdutos().then(res => {
            dispatch({ type: Actions.SET_TODOS_PRODUTOS, products: res.data.produtos });
        }).catch(err => console.log(err));
    }

    return (

        <Stack sx={{ width: 600, p: 3, height: '100%' }} spacing={2} justifyContent={"space-between"} direction={"column"}>
        

            <Stack spacing={3}>
            <ItemContinue
                cartItems={cartItems}
                IrFrete={IrFrete}
                fecharCarrinho={fecharCarrinho}
            />
                            <Divider sx={{borderColor:"#f0f0f0"}} />

                <Grid container>
                    <Grid item md={12} sm={12}>
                        <Grid container spacing={1}>
                            {cartItems.length > 0 ? cartItems.map((cartItem) => (
                                <Grid item xs={12} md={12} xl={12} sm={12} >
                                    <CartItemResumo key={cartItem.sku} cartItem={cartItem} removeFromCart={removerProdutoCarrinho} />
                                </Grid>
                            )) : <p className="text-center mx-auto">Sem Produtos no carrinho</p>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Stack>

  


            <Stack spacing={3}>
                <Box sx={{ width: '100%', mt: 3 }}>
                    <Stack direction={"column"} spacing={2}>
                        <ItemSumary
                            title={'Subtotal'}
                            value={`R$${cartItems.reduce((acc, { regular_price, quantity_inc }) => {
                                let qty = 1;
                                let item = parseFloat(regular_price).toFixed(2) * 0.9
                                let accumulator = parseFloat(acc).toFixed(2)
                                let res = parseFloat(item) * qty + parseFloat(accumulator)
                                return parseFloat(res).toFixed(2)
                            }, 0)}`}
                        />

                        <ItemSumary
                            title={'Frete'}
                            value={`R$ ${valorFrete}`}
                        />
                        <ItemSumary
                            title={'Total Geral'}
                            value={`R$ ${validaTotalGeral(cartItems, valorFrete, 0)}`}
                        />

                    </Stack>
                </Box>
                {/* <Divider sx={{borderStyle:'dashed', color:"#333"}} />
                <ItemContinue
                    cartItems={cartItems}
                    IrFrete={IrFrete}
                    fecharCarrinho={fecharCarrinho}
                /> */}
            </Stack>

        </Stack>

    )

}

export default Cart;
