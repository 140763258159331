import axios from "axios";

const editHoldOrder = data => {
    return axios({
        method: "post",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/pedidos/editHoldOrder",
        data
    });
};

export default editHoldOrder;
