import axios from "axios";
import Config from "../../config/config";

const listaOutrasCores = data => {
    return axios({
        method: "post",
        url: "https://api.katuxa.com.br/api/v1/apps/pdv/produtos/outras-cores",
        data
    });
};

export default listaOutrasCores;
