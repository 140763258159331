import axios from "axios";
import Config from "../../config/config";

const editarSenha = data => {
    return axios({
        method: "post",
        url: "https://api.katuxa.com.br/api/v1/backoffice/cadastro/usuarios/editar-senha",
        data
    });
};

export default editarSenha;
