import React, { Component } from 'react'

import CartItemPedidos from './CartItemPedidos'
import { connect, useDispatch, useSelector } from 'react-redux';



import { removeFromCart, addItemQty, removeItemQty } from '../actions/index';


const CartPedidos = ({IrFrete, cartItems}) => {

    const dadosPessoais = useSelector(state => state.dadosPessoais);
    const dadosEntrega = useSelector(state => state.dadosEntrega);
    const valorFrete = useSelector(state => state.valorFrete);
    const carrinho = useSelector(state => state.carrinho);

    const dispatch = useDispatch();


    const removerProdutoCarrinho = (product) => {
        dispatch(removeFromCart(product))
    }

    const adicionarQtdItemCarrinho = (product) => {
        dispatch(addItemQty(product))

    }

    const removerQtdItemCarrinho = (product) => {
        dispatch(removeItemQty(product))

    }
    
    

     

        return (
            <div style={{padding:30}}  className="row mt-12">
                <div className="col-lg-12 col-md-12">
                    <div className="custom-card">
              
                       
                        {
                            cartItems.map((cartItem) => (
                                <CartItemPedidos key={cartItem._id} cartItem={cartItem}  />
                            ))
                        }
                    </div>
                </div>
      
  
            </div>
        )

}

export default CartPedidos;
