import { withRouter, Link } from "react-router-dom";

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Card, Divider, Slide, Typography } from '@material-ui/core';
import CartPedidos from '../../components/CartPedidos';
import { getFilialUser } from "../../services/auth";
import { FaShippingFast, FaUserCircle, FaTasks } from 'react-icons/fa';
import {  Grid, Stack } from '@material-ui/core';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { FiPrinter } from 'react-icons/fi';


//actions
import editStatusOrder from "../../actions/pedidos/editar-status-pedido";
import editHoldOrder from "../../actions/pedidos/editar-hold-pedido";
import emitirEtiqueta from "../../actions/emitirEtiqueta";
import getListaPedidosByStatus from "../../actions/relatorios/lista-pedidos-by-status";
import getListaPedidosByStatus2 from "../../actions/relatorios/lista-pedidos-by-status-2";
import getListaPedidosSeparados from "../../actions/relatorios/lista-pedidos-separados";
import getListaPedidosAprovados from "../../actions/relatorios/lista-pedidos-aprovados";
// import getOrder from "../../actions/getOrder";
import buscarFilialPorCodigoFilial from "../../actions/cadastro/buscar-filial-por-codigo-filial";

import { CardPedidosPendentes } from '../../components/Pedidos/CardPedidosPendentes';
import { CardPedidosAceitos } from '../../components/Pedidos/CardPedidosAceitos';
import { CardPedidosDespachados } from '../../components/Pedidos/CardPedidosDespachados';
import { CardPedidosAprovados } from '../../components/Pedidos/CardPedidosAprovados';
import { CardPedidosExpedicao } from '../../components/Pedidos/CardPedidosExpedicao';
import { CardPedidosBackoffice } from '../../components/Pedidos/CardPedidosBackoffice';


const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  conteudo: {
    background: '#f0f0f0',
    padding: 30,
    height: '90vh'

  },
  title: {
    marginLeft: 2,
    flex: 1,
  },
  card: {
    background: '#fff',
    padding: 30,
    borderRadius: 5
  },
  headerPedido: {
    borderBottom: '1px solid #f0f0f0',
    paddingBottom: 4
  },
  labelPedido: {
    fontSize: 10,
    color: '#999'

  },
  value_xl: {
    fontSize: 14,
    fontWeight: 700,
    color: '#333'
  },
  value_md: {
    fontSize: 10,
    fontWeight: 600,
    color: '#333'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});





function PedidosPage(props) {

  const history = useHistory();
  const classes = useStyles();
  const [detalhesPedido, setDetalhesPedido] = useState('')
  const [listaPedidos1, setListaPedidos1] = useState([])
  const [listaPedidos2, setListaPedidos2] = useState([])
  const [listaPedidos3, setListaPedidos3] = useState([])
  const [listaPedidos4, setListaPedidos4] = useState([])
  const [listaPedidos5, setListaPedidos5] = useState([])
  const [listaPedidos6, setListaPedidos6] = useState([])
  const [listaPedidos7, setListaPedidos7] = useState([])

  const [open, setOpen] = React.useState(false);
  const [dadosFilial, setDadosFilial] = React.useState('');

  const { moovpay } = detalhesPedido || {};
  const moopayResponse = moovpay?.payment?.RetornoStatusCodigo === "00" && moovpay?.payment?.RetornoStatusCompra.length > 0 
    ? moovpay?.payment?.RetornoStatusCompra[0] 
    : null;
  function validaParcelasPedido(valueDadosParcelas, valueParcelasZoop) {
    if (valueDadosParcelas) {
      return valueDadosParcelas;
    } else {
      if (valueParcelasZoop) {
        if(valueParcelasZoop.installment_plan) {
          return valueParcelasZoop.installment_plan.number_installments;
        }
        
      } else {
        return "";
      }

    }
  }



  function validaStatus(string) {
    if (string === "60774fa684e6861cb41a472f") {
      return "Pendente Pagamento";
    } else if (string === "6143439585ee0369a5dfacbc") {
      return "Cancelado";
    } else if (string === "60774f9c84e6861cb41a472e") {
      return "Pedido Feito";
    } else if (string === "6143412285ee0369a5dfacb4") {
      return "Aceito";
    } else if (string === "60774fb684e6861cb41a4730") {
      return "Pag. Aprovado";
    } else if (string === "6143414c85ee0369a5dfacb5") {
      return "Expedição";
    } else if(string === "resEditarCliente") {
     return "Backoffice";
    }
  }

  function validaEntrega(string) {
    if (string === "61532d990d253dd8a450bcde") {
      return "Correios - Pac";
    } else if (string === "615602fdeaf72f4fe7f5ee24") {
      return "Correios - Sedex";
    } else if (string === "61560313eaf72f4fe7f5ee25") {
      return "Patrus";
    } else if (string === "6156031beaf72f4fe7f5ee26") {
      return "Retirada em Loja";
    } else if (string === "61560325eaf72f4fe7f5ee27") {
      return "Entrega Katuxa";
    }

  }

  function validaTipoPagamento(string) {
    if (string === "credit") {
      return "Cartão Crédito";
    } else if (string === "debit") {
      return "Cartão Débito";
    } else if (string === "boleto") {
      return "Boleto Bancário";
    } else if (string === "pix") {
      return "Pix";
    }

  }

  const validaIsDivinopolis = item => {
    if(valida(item) === true) {
      editStatusOrderFunction({ statusId: "6143436085ee0369a5dfacbb", item, isHold: true })
    } else {
      editStatusOrderFunction({ statusId: "630d0625d8c7d3d0ea112058", item, isHold: true })
    }
  }

    function valida(item) {
      const value = Number(item.shipping_address.postal_code);
        if(value >= 35500000 & value <= 35505000){
          return true;
        } else {
          return false;
        }
    }

  const editStatusOrderFunction = ({ statusId, item, isHold }) => {
    editStatusOrder({ status_id: statusId, number: item.number, isHold: isHold }).then(res => {
      carregaLista();
    })
  };

  const editStatusHoldFunction = ({ item, isHold }) => {
    editHoldOrder({ number: item.number, isHold: isHold }).then(res => {
      carregaLista();
    })
  }


  const Lista1 = ({ List1, onAction }) => (
    <Stack direction="column" spacing={1}>

      {List1.map(item => (
        <CardPedidosPendentes
          key={item._id}
          item={item}
          onAction={() => editStatusHoldFunction({ item, isHold: true })}
          click={() => verPedido(item)}
        />
      ))}
    </Stack>
  );

  const Lista2 = ({ List2, onDespacharPedido, onPrintPedido }) => (
    <Stack direction="column" spacing={1}>
      {List2.map(item => (
        <CardPedidosAceitos
          key={item._id}
          item={item}
          onDespacharPedido={onDespacharPedido}
          click={() => verPedido(item)}
        />
      ))}
    </Stack>
  );

  const Lista3 = ({ PedidosDespachados, onFinalizarPedido }) => (
    <Stack direction="column" spacing={1}>
      {PedidosDespachados.map(item => (
        <CardPedidosAprovados
          key={item._id}
          item={item}
          onAction={() => {
            editStatusOrderFunction({ statusId: "6143414c85ee0369a5dfacb5", item, isHold: true })
          }}
          click={() => verPedido(item)}
        />
      ))}
    </Stack>
  );

  const Lista4 = ({ PedidosDespachados, onFinalizarPedido, onAction }) => (
    <Stack direction="column" spacing={1}>
      {PedidosDespachados.map(item => (
        <CardPedidosExpedicao
          key={item._id}
          item={item}
          onAction={() => editStatusOrderFunction({ statusId: "6143434b85ee0369a5dfacba", item, isHold: true })}
          click={() => verPedido(item)}
        />
      ))}
    </Stack>
  );



  const Lista5 = ({ PedidosDespachados, onFinalizarPedido }) => (
    <Stack direction="column" spacing={1}>
      {PedidosDespachados.map(item => (
        <CardPedidosDespachados
          key={item._id}
          item={item}
          onAction={() => validaIsDivinopolis(item)}
          click={() => verPedido(item)}
        />
      ))}
    </Stack>
  );

  const Lista6 = ({ PedidosDespachados, onFinalizarPedido }) => (
    <Stack direction="column" spacing={1}>
      {PedidosDespachados.map(item => (
        <CardPedidosBackoffice
          key={item._id}
          item={item}
          titleButton="Colocar em Trânsito"
          onAction={() => editStatusOrderFunction({ statusId: "6143432885ee0369a5dfacb9", item, isHold: true })}
          click={() => verPedido(item)}
        />
      ))}
    </Stack>
  );

  const Lista7 = ({ PedidosDespachados, onFinalizarPedido }) => (
    <Stack direction="column" spacing={1}>
      {PedidosDespachados.map(item => (
        <CardPedidosBackoffice
          key={item._id}
          item={item}
          onAction={() => editStatusOrderFunction({ statusId: "6143436085ee0369a5dfacbb", item, isHold: true })}
          click={() => verPedido(item)}
          titleButton="Finalizar"
        />
      ))}
    </Stack>
  );



  const verPedido = (item) => {
    setOpen(true);
    setDetalhesPedido(item);
    buscarFilialPorCodigoFilial(item.dadosOrigemPedido.filial).then(res => {
      let data = {
        filial: res.data.codigo_filial,
        cep: res.data.cep,
        rua: res.data.logradouro,
        bairro: res.data.bairro,
        cidade: res.data.localidade,
        estado: res.data.uf
      }
      setDadosFilial(res.data);
    });
    console.log(item)

  };

  const handleClose = () => {
    setOpen(false);
    setDetalhesPedido('')
  };

  async function getDataFilial(string) {
    try {
     const res = await buscarFilialPorCodigoFilial(string);
      return res;
    } catch (error) {
      console.log('error getDataFilial', error);
    }
  }



  const handleEmitirEtiqueta = async () => {

    const dataFilialFaturamento = await getDataFilial(detalhesPedido.items[0].filial);
    const dataFilialOrigemPedido = await getDataFilial(detalhesPedido.dadosOrigemPedido.filial);

    let scopeFilialFaturamento = {
      logradouro: dataFilialFaturamento.data.logradouro.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      numero: dataFilialFaturamento.data.numero.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      bairro: dataFilialFaturamento.data.bairro.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      localidade: dataFilialFaturamento.data.localidade.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      complemento: dataFilialFaturamento.data.complemento.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      uf: dataFilialFaturamento.data.uf,
      cep: dataFilialFaturamento.data.cep
    }

    let scopeFilialOrigemPedido = {
      nome_completo: detalhesPedido.shipping_address.full_name.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      logradouro: dataFilialOrigemPedido.data.logradouro.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      numero: dataFilialOrigemPedido.data.numero.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      bairro: dataFilialOrigemPedido.data.bairro.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      localidade: dataFilialOrigemPedido.data.localidade.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      complemento: dataFilialOrigemPedido.data.complemento.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      uf: dataFilialOrigemPedido.data.uf,
      cep: dataFilialOrigemPedido.data.cep
    }

    let scopeEnderecoCliente = {
      nome_completo: detalhesPedido.shipping_address.full_name.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      logradouro: detalhesPedido.shipping_address.address1.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      numero: detalhesPedido.shipping_address.number,
      bairro: detalhesPedido.shipping_address.address2.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      localidade: detalhesPedido.shipping_address.city.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      complemento: detalhesPedido.shipping_address.details.normalize('NFD').replace(/[\u0300-\u036f]/g, ""),
      uf: detalhesPedido.shipping_address.state,
      cep: detalhesPedido.shipping_address.postal_code
    }

    const dataPedido = {
      remetente: scopeFilialFaturamento,
      destinatario: detalhesPedido.tipoEntrega === "Entrega" ? scopeEnderecoCliente : scopeFilialOrigemPedido,
      pedido: { numero: detalhesPedido.number, filial: detalhesPedido.tipoEntrega === "Retirada" ? detalhesPedido.dadosOrigemPedido.filial : "" }
    };

    

    emitirEtiqueta(dataPedido)
      .then(response => {
        //   var filename = 'label.pdf'; // change file name for PNG images
        // fs.writeFile(filename, response.data, function(err) {
        //     if (err) {
        //         console.log(err);
        //     }
        // });
        console.log('data', response)
      })
      .catch(function (error) {
        console.log(error)
      });
  }

  const formataStatusZoop = (value) => {
    if (value === 'succeeded') {
      return "Aprovado";
    }
  }

  const validaBandeiraZoop = (string) => {

    if (string === "credit") {
      return "Zoop Crédito";
    } else if (string === "debit") {
      return "Zoop Débito";
    } else if (string === "boleto") {
      return "Boleto";
    } else if (string === "pix") {
      return "Zoop Pix";
    }

  }

  const validaCodigoAutorizacao = (data) => {

    if (data.payment_type === "pix") {
      return `P-${detalhesPedido.number}`;
    } else {
      if (data.payment_authorization) {
        return data.payment_authorization.authorization_code
      } else {
        return "Não autorizado";
      }
    }

  }



  async function FinalizarPedido(order_id) {
    try {
      const response = await axios.post('https://api-ideliv.ideliv.app/ideliv/loja/finalizarPedido', { id_pedido: order_id });

    } catch (error) {
      console.error(error);
    }
  }

  const carregaLista = () => {
    getListaPedidosByStatus2({ status1: "60774fb684e6861cb41a4730", status2: "60774fa684e6861cb41a472f", isHold: false, filialUsuario: getFilialUser() }).then(res => {
      setListaPedidos1(res.data);
    });

    getListaPedidosSeparados({ status1: "60774fb684e6861cb41a4730", status2: "60774fa684e6861cb41a472f", isHold: true, filialUsuario: getFilialUser() }).then(res => {
      setListaPedidos2(res.data);
    });

    getListaPedidosAprovados({ status: "60774fb684e6861cb41a4730", filialUsuario: getFilialUser(), isHold: true }).then(res => {
      setListaPedidos3(res.data);
    });

    getListaPedidosByStatus({ status: "6143414c85ee0369a5dfacb5", isHold: true, filialUsuario: getFilialUser() }).then(res => {
      setListaPedidos4(res.data);
    });

    getListaPedidosByStatus2({ status1: "6143434b85ee0369a5dfacba", status2: "614342eb85ee0369a5dfacb8", isHold: true, filialUsuario: getFilialUser() }).then(res => {
      setListaPedidos5(res.data);
    });

    getListaPedidosByStatus({ status: "630d0625d8c7d3d0ea112058", isHold: true, filialUsuario: getFilialUser() }).then(res => {
      setListaPedidos6(res.data);
    });

    
    getListaPedidosByStatus({ status: "6143432885ee0369a5dfacb9", isHold: true, filialUsuario: getFilialUser() }).then(res => {
      setListaPedidos7(res.data);
    });

    

  }


  useEffect(() => {

    carregaLista();

  }, []);

  return (

    <div>
      <Grid container spacing={2}>
        <Grid item md>
          <Grid item md={12}>
            <div style={{ background: 'linear-gradient(to right, #ed213a, #93291e)', borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10 }}>
              <h6 style={{ textAlign: 'center', color: '#fff' }}>Pendentes/Conferência</h6>
            </div>

          </Grid>
          <div className="margin-top-20">
            <Grid item xs={12} sm={12}>
              <Lista1
                List1={listaPedidos1}
              />
            </Grid>
          </div>
        </Grid>

        <Grid item md sm>
          <Grid item md={12}>
            <div style={{ background: 'linear-gradient(to right, #00b4db, #0083b0)', borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10 }}>
              <h6 style={{ textAlign: 'center', color: '#fff' }}>Pedidos Separados</h6>
            </div>
          </Grid>
          <div className="margin-top-20">
            <Grid item xs={12} sm={12}>
              <Lista2 List2={listaPedidos2} onFinalizarPedido={FinalizarPedido} />
            </Grid>
          </div>
        </Grid>

        <Grid item md>
          <Grid item md={12}>
            <div style={{ background: 'linear-gradient(to right, #06beb6, #48b1bf)', borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10 }}>
              <h6 style={{ textAlign: 'center', color: '#fff' }}>Pag. Aprovados</h6>
            </div>
          </Grid>
          <div className="margin-top-20">
            <Grid item xs={12} sm={12}>
              <Lista3 PedidosDespachados={listaPedidos3} onFinalizarPedido={FinalizarPedido} />
            </Grid>
          </div>
        </Grid>

        <Grid item md>
          <Grid item md={12}>
            <div style={{ background: 'linear-gradient(to right, #4e54c8, #8f94fb)', borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10 }}>
              <h6 style={{ textAlign: 'center', color: '#fff' }}>Expedição</h6>
            </div>
          </Grid>
          <div className="margin-top-20">
            <Grid item xs={12} sm={12}>
              <Lista4 PedidosDespachados={listaPedidos4} onFinalizarPedido={FinalizarPedido} />
            </Grid>
          </div>
        </Grid>


        <Grid item md>
          <Grid item md={12}>
            <div style={{ background: 'linear-gradient(to right, #ffafbd, #ffc3a0)', borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10 }}>
              <h6 style={{ textAlign: 'center' }}>Retirada/Coleta</h6>
            </div>
          </Grid>
          <div className="margin-top-20">
            <Grid item xs={12} sm={12}>
              <Lista5 PedidosDespachados={listaPedidos5} onFinalizarPedido={FinalizarPedido} />
            </Grid>
          </div>
        </Grid>
        
        {getFilialUser() === "000122" && (
          <>
                  <Grid item md>
          <Grid item md={12}>
            <div style={{ background: 'linear-gradient(to right, #4e54c8, #8f94fb)', borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10 }}>
              <h6 style={{ textAlign: 'center', color: '#fff' }}>Backoffice</h6>
            </div>
          </Grid>
          <div className="margin-top-20">
            <Grid item xs={12} sm={12}>
              <Lista6 PedidosDespachados={listaPedidos6} onFinalizarPedido={FinalizarPedido} />
            </Grid>
          </div>
        </Grid>

              
        <Grid item md >
          <Grid item md={12}>
            <div style={{ background: 'linear-gradient(to right, #4e54c8, #8f94fb)', borderTopLeftRadius: 10, borderTopRightRadius: 10, padding: 10 }}>
              <h6 style={{ textAlign: 'center', color: '#fff' }}>Enviado</h6>
            </div>
          </Grid>
          <div className="margin-top-20">
            <Grid item xs={12} sm={12}>
              <Lista7 PedidosDespachados={listaPedidos7} onFinalizarPedido={FinalizarPedido} />
            </Grid>
          </div>
        </Grid>
          </>
        )}

      </Grid>

      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            Pedido #{detalhesPedido.number}
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleEmitirEtiqueta}
            className={classes.button}
            startIcon={<FiPrinter />}
          >
            gerar etiqueta
          </Button>




        </Toolbar>

        <div className={classes.conteudo}>

          {detalhesPedido && (
            <div>
              <Grid container spacing={3}>
                <Grid item md={9}>
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <Grid container spacing={1}>
                        <Grid item md={12}>
                          <Card style={{ padding: 30 }}>
                            <Grid item md={12}>
                              <Grid container className={classes.headerPedido} spacing={2}>
                                <Grid item md={1} sm>
                                  <FaUserCircle color={'rgba(191, 0, 0, 0.87)'} size={20} />
                                </Grid>
                                <Grid item md sm>
                                  <h4>Informações Pessoais</h4>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid style={{ marginTop: 10 }} spacing={3} container>
                              <Grid item md={12}>
                                <span className={classes.labelPedido}>nome do cliente</span>
                                <div className={classes.value_xl}>{`${detalhesPedido.first_name} ${detalhesPedido.last_name}`}</div>
                              </Grid>
                              <Grid item md={4}>
                                <span className={classes.labelPedido}>telefone</span>
                                <div className={classes.value_xl}>{detalhesPedido.shipping_address.phone}</div>
                              </Grid>
                              <Grid item md={4}>
                                <span className={classes.labelPedido}>cpf cliente</span>
                                <div className={classes.value_xl}>{detalhesPedido.cpf ? detalhesPedido.cpf : "erro"}</div>
                              </Grid>
                            </Grid>

                            <Grid style={{ marginTop: 10 }} spacing={3} container>
                              <Grid item md={12}>
                                <span className={classes.labelPedido}>Endereço</span>
                                <div className={classes.value_xl}>{`${detalhesPedido.shipping_address.address1},  ${detalhesPedido.shipping_address.number}, ${detalhesPedido.shipping_address.address2}, (${detalhesPedido.shipping_address.details})`}</div>
                                <div className={classes.value_xl}>{`${detalhesPedido.shipping_address.postal_code} - ${detalhesPedido.shipping_address.city} / ${detalhesPedido.shipping_address.state}`}</div>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                        <Grid item md={12}>
                          <Card style={{ padding: 30 }}>
                            <Grid spacing={3} container>
                              <Grid item md={12}>
                                <Grid container className={classes.headerPedido} spacing={2}>
                                  <Grid item md={1} sm>
                                    <FaShippingFast color={'rgba(191, 0, 0, 0.87)'} size={20} />
                                  </Grid>
                                  <Grid item md={11} sm>
                                    <h4>Informações de {detalhesPedido.tipoEntrega}</h4>
                                  </Grid>


                                </Grid>

                              </Grid>

                              <Grid item md={12}>
                                <span className={classes.labelPedido}>enviar para</span>
                                {detalhesPedido.tipoEntrega === 'Entrega' ? (
                                  <div>
                                    <div className={classes.value_xl}>{`${detalhesPedido.shipping_address.address1},  ${detalhesPedido.shipping_address.number}, ${detalhesPedido.shipping_address.address2}, (${detalhesPedido.shipping_address.details})`}</div>
                                    <div className={classes.value_xl}>{`${detalhesPedido.shipping_address.postal_code} - ${detalhesPedido.shipping_address.city} / ${detalhesPedido.shipping_address.state}`}</div>
                                  </div>
                                ) : (
                                  <div>
                                    <strong>Filial: {dadosFilial.codigo_filial}</strong>: {dadosFilial.logradouro},  {dadosFilial.numero},  {dadosFilial.bairro}, {dadosFilial.complemento ? `${dadosFilial.complemento},`  : ""} {dadosFilial.localidade} - {dadosFilial.uf} - {dadosFilial.cep}
                                  </div>
                                )}

                              </Grid>

                              <Grid item md>
                                <span className={classes.labelPedido}>quem vai entregar</span>
                                <div className={classes.value_xl}>{validaEntrega(detalhesPedido.shipping_method_id)}</div>
                              </Grid>

                              <Grid item md>
                                <span className={classes.labelPedido}>valor da entrega</span>
                                <div className={classes.value_xl}>{detalhesPedido.shipping_price}</div>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      </Grid>

                    </Grid>

                    <Grid item md={6}>
                      <Grid container spacing={1}>

                        <Grid item md={12}>
                          <Card style={{ padding: 30 }}>
                            <Grid item md={12}>
                              <Grid container className={classes.headerPedido} spacing={2}>
                                <Grid item md={1} sm>
                                  <FaTasks color={'rgba(191, 0, 0, 0.87)'} size={20} />
                                </Grid>
                                <Grid item md sm>
                                  <h4>Informações do Pedido</h4>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid style={{ marginBottom: 15, marginTop: 20 }} spacing={2} container>
                              <Grid item md={4}>
                                <span className={classes.labelPedido}>filial de origem pedido</span>
                                <div className={classes.value_md}>{detalhesPedido.items[0].filial}</div>
                              </Grid>
                              <Grid item md={4}>
                                <span className={classes.labelPedido}>data</span>
                                <div className={classes.value_md}>
                                  {moment(detalhesPedido.date_created).format('DD/MM/YYYY')}</div>
                              </Grid>
                              <Grid item md={4}>
                                <span className={classes.labelPedido}>status do pedido</span>
                                <div className={classes.value_md}>{validaStatus(detalhesPedido.status_id)}</div>
                              </Grid>
                              <Grid item md={4}>
                                <span className={classes.labelPedido}>o cliente escolheu</span>
                                <div className={classes.value_md}>{detalhesPedido.tipoEntrega}</div>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>


                        {moopayResponse && (
                          <Grid item md={12}>
                            <Card style={{ padding: 30 }}>
                              <Grid item md={12}>
                                <Grid container className={classes.headerPedido} spacing={2}>
                                  <Grid item md={1} sm>
                                    <FaTasks color={'rgba(191, 0, 0, 0.87)'} size={20} />
                                  </Grid>
                                  <Grid item md sm>
                                    <h4>Informações da Transação</h4>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid style={{ marginBottom: 15, marginTop: 20 }} spacing={2} container>
                                <Grid item md={4}>
                                  <span className={classes.labelPedido}>Status</span>
                                  <div className={classes.value_md}>Aprovado</div>

                                </Grid>
                                <Grid item md={4}>
                                  <span className={classes.labelPedido}>data</span>
                                  <div className={classes.value_md}>{moment(moopayResponse.DataAutorizacao).format('DD/MM/YYYY')}</div>
                                </Grid>
                                <Grid item md={4}>
                                  <span className={classes.labelPedido}>Código autorização</span>
                                  <div className={classes.value_md}>{moopayResponse.NumeroAutorizacao}</div>
                                </Grid>

                                <Grid item md={4}>
                                  <span className={classes.labelPedido}>NSU</span>
                                  <div className={classes.value_md}>{moopayResponse.NSU}</div>
                                </Grid>

                                <Grid item md={4}>
                                  <span className={classes.labelPedido}>Administradora</span>
                                  <div className={classes.value_md}>Moovpay</div>
                                </Grid>

                                <Grid item md={4}>
                                  <span className={classes.labelPedido}>Parcelas</span>
                                  <div className={classes.value_md}>{moopayResponse.QuantidadeParcelas}</div>
                                </Grid>

                                <Grid item md={12}>
                                  <span className={classes.labelPedido}>Desc</span>
                                  <div className={classes.value_md}>{moopayResponse.ComprovantePdv}</div>
                                </Grid>

                              </Grid>
                            </Card>
                          </Grid>
                        )}



                        {detalhesPedido.responseZoop && (
                          <Grid item md={12}>
                            <Card style={{ padding: 30 }}>
                              <Grid item md={12}>
                                <Grid container className={classes.headerPedido} spacing={2}>
                                  <Grid item md={1} sm>
                                    <FaTasks color={'rgba(191, 0, 0, 0.87)'} size={20} />
                                  </Grid>
                                  <Grid item md sm>
                                    <h4>Informações da Transação</h4>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid style={{ marginBottom: 15, marginTop: 20 }} spacing={2} container>
                                <Grid item md={4}>
                                  <span className={classes.labelPedido}>Status</span>
                                  <div className={classes.value_md}>{formataStatusZoop(detalhesPedido.responseZoop.status)}</div>

                                </Grid>
                                <Grid item md={4}>
                                  <span className={classes.labelPedido}>data</span>
                                  <div className={classes.value_md}>{moment(detalhesPedido.responseZoop.created_at).format('DD/MM/YYYY')}</div>
                                </Grid>
                                <Grid item md={4}>
                                  <span className={classes.labelPedido}>Código autorização</span>
                                  <div className={classes.value_md}>{validaCodigoAutorizacao(detalhesPedido.responseZoop)}</div>
                                </Grid>

                                <Grid item md={4}>
                                  <span className={classes.labelPedido}>Tipo Pagamento</span>
                                  <div className={classes.value_md}>{validaTipoPagamento(detalhesPedido.responseZoop.payment_type)}</div>
                                </Grid>

                                <Grid item md={4}>
                                  <span className={classes.labelPedido}>Administradora</span>
                                  <div className={classes.value_md}>{validaBandeiraZoop(detalhesPedido.responseZoop.payment_type)}</div>
                                </Grid>

                                <Grid item md={4}>
                                  <span className={classes.labelPedido}>Parcelas</span>
                                  <div className={classes.value_md}>{validaParcelasPedido(detalhesPedido.parcelas, detalhesPedido.responseZoop)}</div>
                                </Grid>

                              </Grid>
                            </Card>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>


                  {/* INFORMAÇÕES DE RESPOSTA DA ZOPP */}


                </Grid>
                <Grid item md={3}>
                  <Card style={{padding:30}}> 
                    <CartPedidos cartItems={detalhesPedido.items} />
                    <div style={{ marginTop: 20 }} className="col-lg-12 col-md-12">
                      <div className="custom-card" style={{ borderRadius: "10px" }}>
                 
                        <Stack direction={"row"} justifyContent={"space-between"}>
                        <p className="text-muted">Subtotal</p>
                          <p className="text-muted">R$ {(detalhesPedido.items).reduce((acc, { price, quantity }) => {
                            let qty = quantity;
                            let item = parseFloat(price).toFixed(2) * 0.9
                            let accumulator = parseFloat(acc).toFixed(2)
                            let res = parseFloat(item) * qty + parseFloat(accumulator)
                            return parseFloat(res).toFixed(2)
                          }, 0)}</p>
                        </Stack>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                        <p className="text-muted">Frete</p>
                        <p className="text-muted"><span style={{ fontSize: 10 }}>R${detalhesPedido.subtotal}</span>{detalhesPedido.shipping_price}</p>
                        </Stack>
                        <div className="d-flex justify-content-between">
                       
                        </div>
                        <Divider sx={{ mt: 2, mb:2 }} />
                        <div className="d-flex justify-content-between align-items-center mb-4">
                          Total Geral:
                          <strong>R$ {(detalhesPedido.items).reduce((acc, { price, quantity }) => {
                            let qty = quantity;
                            let item = parseFloat(price).toFixed(2) * 0.9
                            let accumulator = parseFloat(acc).toFixed(2)
                            let res = parseFloat(item) * qty + parseFloat(accumulator)
                            return parseFloat(res).toFixed(2)
                          }, + detalhesPedido.shipping_price)}</strong>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </div>
          )}
        </div>
      </Dialog>
    </div>

  )
}
export default withRouter(PedidosPage)
