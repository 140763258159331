import axios from "axios";
import Config from "../../config/config";

const consultarClientePorCpf = cpf => {
    return axios({
        method: "get",
        url: `https://api.katuxa.com.br/api/v1/apps/pdv/clientes/consultar/${cpf}`
    });
};

export default consultarClientePorCpf;
