import React from 'react'
import clsx from 'clsx'
import { Grid } from '@mui/material';
import { useStyles } from '../Style/StyleAdmin';
import ConclusaoPage from '../Page/ConclusaoPage';
import { useHistory } from 'react-router-dom';



function Conclusao() {
  const history = useHistory();

  const irPagamento = () => {
    history.push('pagamento');
    console.log('foi')
    
  };

  return(
    <ConclusaoPage />
  )
}

export default Conclusao;
