const Actions = {
    addToCart: "ADD_TO_CART",
    removeFromCart: "REMOVE_FROM_CART",
    addItemQty: "ADD_ITEM_QTY",
    removeItemQty: "REMOVE_ITEM_QTY",
    addWishlist: "ADD_TO_WISHLIST",
    SET_TODOS_PRODUTOS:"SET_TODOS_PRODUTOS",
    dadosPessoais: "DADOS_PESSOAIS",
    dadosEntrega: "DADOS_ENTREGA",
    dadosFrete: "DADOS_FRETE",
    carrinho: "CARRINHO",
    valorFrete: "VALOR_FRETE",
    CEP_ORIGEM_PRODUTO: "CEP_ORIGEM_PRODUTO",
    DRAWER_CARRINHO:"DRAWER_CARRINHO",
    LOADING:"LOADING",
    SHIPPING_ID:"SHIPPING_ID",
    TIPO_ENTREGA:"TIPO_ENTREGA",
    PAYMENT_ID:"PAYMENT_ID",
    ORDER_NUMBER:"ORDER_NUMBER",
    TOKEN_PATRUS:"TOKEN_PATRUS",
    MEU_CARRINHO:"MEU_CARRINHO",
    SEARCH:"SEARCH",
    LOADING_PAGE:"LOADING_PAGE",
    SET_VALOR_CRM:"SET_VALOR_CRM"
};

export default Actions;
