import axios from "axios";
import Config from "../../config/config";

const buscarClientePorCpfLinx = cpf => {
    return axios({
        method: "get",
        url: "https://api.katuxa.com.br/api/v1/sync/linx/cliente/" + cpf + "/buscar"
    });
};

export default buscarClientePorCpfLinx;
