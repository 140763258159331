import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, CircularProgress } from '@material-ui/core';

//actions
import adicionarProdutoBySync from '../../actions/chatbot/adicionar-produto-by-sync';


const AdicionarProdutoBySync = ({steps})  => {

  const { digita_reduzido } = steps;

  const [okAdd, setOkAdd] = useState('initial');
  const [message, setMessage] = useState('')
  
  const InserirFoguete = async () =>{
        try {
          const res = await adicionarProdutoBySync({reduzido:digita_reduzido.value});
          if(res.data.error === false) {
            setOkAdd('sucesso');
            setMessage(`Obrigado pelas informações, o produto ${digita_reduzido.value} foi cadastrado`)
          } else {
            setOkAdd('erro')
            setMessage(res.data.mensagem)
          }
        } catch (error) {
          setOkAdd('erro'); 
          setMessage('Houve algum erro ao inserir o produto')       
        }
    }
  

  
  useEffect(() => {
   
    if(digita_reduzido.value) {
      setOkAdd('buscando');
      InserirFoguete();
    }
   


  }, [])


  return (
    <Box>
      {okAdd === 'buscando' && <Typography>Um momento, estamos verificando pra inserir... <CircularProgress color="secondary" size={15} /></Typography>}
      {okAdd === 'sucesso' && <Typography style={{color:'#fff'}}>{message}</Typography>}
      {okAdd === 'erro' && <Typography style={{color:'#fff'}}>{message}</Typography>}
      
    </Box>
    );
}


// AdicionarProdutoBySync.defaultProps = {
//   steps: undefined,
// };

export default AdicionarProdutoBySync;