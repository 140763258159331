import React from 'react';
import {Avatar, Container, Grid, Typography, Card } from '@material-ui/core';

import { useStyles } from '../Style/StyleMessage';

import ChatBot from 'react-simple-chatbot';

import ReactPlayer from 'react-player/lazy'

export default function SuportePage() {
  const classes = useStyles();

  const steps = [
    {
      id: '0',
      message: 'Como posso te ajudar?',
      trigger: '4',
    },
     {
      id: '4',
      options: [
        { value: 1, label: 'Inserir Produto', trigger: 'P01:1' },
        { value: 2, label: 'CPF Inválido', trigger: 'P02' },
        { value: 3, label: 'Alterar Pedido', trigger: 'P03' },
        { value: 4, label: 'Problema com Maquininha', trigger: 'P04' },
        { value: 5, label: 'Duvidas com Faturamento', trigger: 'P05' },
        { value: 6, label: 'Preço divergente', trigger: 'P03:2' },
        // { value: 7, label: 'Cadastrar usuário', trigger: 'P07' },
        // { value: 8, label: 'Alterar Usuário', trigger: 'P08' },
        { value: 9, label: 'Loja que está o produto não atende', trigger: 'P09' },
        // { value: 10, label: 'Outros', trigger: 'P10' },


      ],
    },
    {
      id: 'P01:1',
      message: 'Qual o reduzido?',
      trigger: 'P01:2',
    },
    {
        id: 'P01:2',
        user: true,
        inputAttributes: {
        keyboardType: 'default'
      },
      trigger: 'P01:3',
      },
      {
        id: 'P01:3',
        message: 'Selecione a cor',
        trigger: 'P01:3:1',
      },
      {
        id: 'P01:3:1',
        options: [
          { value: 1, label: 'Preto', trigger: 'produto_inserido_com_sucesso' },
          { value: 2, label: 'Branco', trigger: 'produto_inserido_com_sucesso' },

  
        ],
      },
      {
        id: 'produto_inserido_com_sucesso',
        message: 'Produto Inserido com sucesso',
        trigger: 'final',
      },


      /////// P01 ENCERRADO
      {
        id: 'P02',
        message: 'O Sistema tem uma validação de CPF para que possa ser feita a venda. Se aparecer a mensagem de CPF INVÁLIDO, esse cpf nao existe',
        trigger: 'final',
      },
         /////// P02 ENCERRADO

         {
            id: 'P03',
            options: [
              { value: 1, label: 'Alterar Produto', trigger: 'P03:1' },
              { value: 2, label: 'Alterar Preço de produto', trigger: 'P03:2' },
              { value: 3, label: 'Alterar cor do pedido', trigger: 'P03:3' },
              { value: 4, label: 'Alterar numeração', trigger: 'P03:3' }
            ],
          },  
          {
            id: 'P03:1',
            message: 'O painel de gerencimento de pedidos ainda está em desenvolvimento',
            trigger: 'P03:1:1',
          },
          {
            id: 'P03:1:1',
            message: 'Refaça o pedido novamente',
            trigger: 'P03:2',
          },
          {
            id: 'P03:2',
            message: 'O preço no Venda Fácil está mais caro que na sua loja?',
            trigger: 'P03:2:1',
          },
          {
            id: 'P03:2:1',
            options: [
              { value: 1, label: 'Sim', trigger: 'P03:2:1:1' },
              { value: 2, label: 'Não', trigger: 'P03:2:1:2' },      
            ],
          },
          {
            id: 'P03:2:1:1',
            message: 'O painel de gerencimento de pedidos ainda está em desenvolvimento',
            trigger: 'P03:2:1:1:1',
          },
          {
            id: 'P03:2:1:1:1',
            message: 'Entre em contato com o setor para alteração do preço e prosseguimento da venda',
            trigger: 'final',
          },
          {
            id: 'P03:2:1:2',
            message: 'Conforme alianhado com a diretoria, foi definido que manterá o preço mais barato na filial que irá faturar',
            trigger: 'final',
          },

          {
            id: 'P03:3',
            message: 'O painel de gerencimento de pedidos ainda está em desenvolvimento',
            trigger: 'P03:3:1',
          },
          {
            id: 'P03:3:1',
            message: 'Refaça o pedido novamente',
            trigger: 'final',
          },
            /////// P03 ENCERRADO

            {
            id: 'P04',
            message: 'Qual mensagem aparece na tela do COMPUTADOR da pagina pagamento.katuxa.com.br ?',
            trigger: 'P04:1',
            },
            {
            id: 'P04:1',
            options: [
                { value: 1, label: 'Servidor Desconectado', trigger: 'P04:1:1' },
                { value: 2, label: 'Processando Pagamento', trigger: 'P04:1:2' },  
                { value: 3, label: 'Não foi possível conectar ao Zoop', trigger: 'P04:1:3' },        
            ],
            },
            {
                id: 'P04:1:1',
                asMessage: true,
                component:<ReactPlayer width={300} url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />,
                trigger: 'final',
             },
             {
                id: 'P04:1:2',
                asMessage: true,
                component:<ReactPlayer width={300} url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />,
                trigger: 'final',
             },
             {
                id: 'P04:1:3',
                message: 'Entre em contato com o setor, possívelmente esse é um problema de bloqueio da Zoop com a rede da Katuxa',
                trigger: 'final',
                },

                //// P05

                   {
                    id: 'P05',
                    message: 'Veja o video abaixo do processo de faturamento de nota fiscal do Venda Fácil',
                    trigger: 'P05:1',
                    },
                    {
                        id: 'P05:1',
                        asMessage: true,
                        component:<ReactPlayer width={300} controls={true} url='https://player.vimeo.com/video/702971401?h=6add20be08' />,
                        trigger: 'final',
                     },

// {
    //   id: '101',
    //   message: 'Huuummm.... Esse problema já um pouco complexo pra mim. Você vai fazer o seguinte, envie um e-mail para:',
    //   trigger: '102',
    // },

    // {
    //   id: '102',
    //   message: 'danielle-dp@katuxa.com.br',
    //   trigger: '103',
    // },

    // {
    //   id: '103',
    //   message: 'Relate o que aconteceu e aguarde para que ela possa entrar em contato com vc para resolver essa situação.',
    //   trigger: '104',
    // },
    // {
    //   id: '104',
    //   options: [
    //     { value: 1, label: 'Voltar ao inicio', trigger: '0' },
    //     { value: 2, label: 'Finalizar', trigger: 'final' },
    // ],

    // },


    // {
    //   id: '200',
    //   message: 'Entendi. Esse problema é com o setor de Inovação, entre em contato com João que ele vai olhar pra vc. Chama ele no WhatsApp, vou te passar o contatinho dele ;) ',
    //   trigger: '201',
    // },

    // {
    //   id: '201',
    //   message: '(37) 99906.3027',
    //   trigger: '202',
    // },

    // {
    //   id: '202',
    //   message: 'Ou liga no 2101-4353 e pede pra falar com ele',
    //   trigger: '104',
    // },

    // {
    //   id: '300',
    //   message: 'Entendi, isso não é pra acontecer, manda uma mensagem no Whatsapp do João do setor de Inovação ou liga no 2101-4353 e pede pra falar com ele  relatando esse problema, ele vai verificar para que isso nao ocorra mais',
    //   trigger: '301',
    // },

    // {
    //   id: '301',
    //   message: 'Whatsapp dele >> (37) 99906.3027',
    //   trigger: '104',
    // },


    {
      id: 'final',
      message: 'Te ajudo em algo mais?',
      trigger:'final:1'
     },
     {
        id: 'final:1',
        options: [
          { value: 1, label: 'Sim', trigger: '0' },
          { value: 2, label: 'Não', trigger: 'final:2' },      
        ],
      },
      {
        id: 'final:2',
        message: 'Se precisar é chamar!',
        trigger:'final:3'
       },

       {
        id: 'final:3',
        options: [
          { value: 1, label: 'Menu', trigger: '0' }, 
        ],
      },

     {
      id: 'P09',
      message: 'Vamos pedir para que entre em contato com o supervisor responsável Osmar e relate o ocorrido para que possa tomar',
      trigger: 'P09:1',
    },
    {
        id: 'P09:1',
        message: 'Numero: 37 9139-2211 e relate o ocorrido para que possa tomar as devidas providências',
        trigger: 'final',
      },

  ];
  

  return(
    <Container className={classes.container}>
      <Grid container spacing={3}>
        <Grid container justify="center" alignItems="center" item xs={12} md={12} lg={12}>
        <Card>
        <ChatBot width={1000} botDelay={500} headerTitle={"Fale com a Kátia, a IA da Katuxa"} placeholder={"Digite aqui a sua mensagem"} hideHeader={true} bubbleStyle={{backgroundColor:"#88171b", color:"#fff", fontFamily:'Montserrat', fontWeight:'600'}}  steps={steps} />
        </Card>
        </Grid>
      </Grid>
    </Container>
  )
}
