import {actionTypes} from '../types'
import products from '../product.json'

import Actions from "../constants/Actions";

const initialState = {
    cartItems: [],
    wishlists: [],
    products:[],

    nomeCompleto:[],
    sexo:[],
    cpf:[],
    dataNascimento:[],
    celular:[],
    email:[],
    loadingPage:false,
    cep:[],
    rua:[],
    numero:[],
    bairro:[],
    complemento:[],
    cidade:[],
    estado:[],

    comprimento:[],
    largura:[],
    altura:[],
    peso:[],

    cepOrigemProduto:[],
    drawerCarrinho:false,
    cepDestino:[],
    cepOrigem:[],
    formaEntrega:[],
    dataEntrega:[],
    valorFrete:0,
    isDescontoCrm:false,
    valorDesconto:0,
    shippingId:{},
    paymentId:{},
    tipoEntrega:{},
    produtos:[],
    subtotal:[],
    loading:false,
    orderNumber:{},
    tokenPatrus:{},
    search:[]

};
  
const cartReducer = (state = initialState, action) => {
  switch (action.type) {
   
          /////
          case Actions.DADOS_PESSOAIS:
            return {
                ...state,
                nomeCompleto:action.nomeCompleto,
                sexo:action.sexo,
                cpf:action.cpf,
                dataNascimento:action.dataNascimento,
                celular:action.celular,
                email:action.email
      
            };

            
            case Actions.SET_VALOR_CRM:
              return {
                  ...state,
                  isDescontoCrm:true,
                  valorDesconto:action.valorDesconto
              };

            

           case Actions.DRAWER_CARRINHO:
            return {
                ...state,
                drawerCarrinho:action.drawerCarrinho
            };


            case Actions.LOADING_PAGE:
              return {
                  ...state,
                  loadingPage:action.loadingPage
              };



            case Actions.SEARCH:
              return {
                  ...state,
                  search:action.search
        
              };
  

            case Actions.TOKEN_PATRUS:
              return {
                  ...state,
                  tokenPatrus:action.tokenPatrus
        
              };

            case Actions.SHIPPING_ID:
              return {
                  ...state,
                  shippingId:action.shippingId
        
              };

              case Actions.ORDER_NUMBER:
                return {
                    ...state,
                    orderNumber:action.orderNumber
          
                };

              
            case Actions.PAYMENT_ID:
              return {
                  ...state,
                  paymentId:action.paymentId
        
              };

              case Actions.TIPO_ENTREGA:
                return {
                    ...state,
                    tipoEntrega:action.tipoEntrega
          
                };


            case Actions.LOADING:
              return {
                  ...state,
                  loading:action.loading
        
              };

            case Actions.CEP_ORIGEM_PRODUTO:
              return {
                  ...state,
                  cepOrigemProduto:action.cepOrigemProduto,
              };

              case Actions.SET_TODOS_PRODUTOS:
                return {
                    ...state,
                    products:action.products,
                };

                case Actions.MEU_CARRINHO:
                  return {
                      ...state,
                       cartItems:action.cartItems
            
                }; 
    
    
  

          case Actions.DADOS_ENTREGA:
              return {
                  ...state,
                  cep:action.cep,
                  rua:action.rua,
                  numero:action.numero,
                  bairro:action.bairro,
                  complemento:action.complemento,
                  cidade:action.cidade,
                  estado:action.estado
        
            }; 

            case Actions.valorFrete:
              return {
                  ...state,
                  valorFrete:action.valorFrete,       
            }; 
            
          case Actions.valorFrete:
              return {
                  ...state,
                  comprimento:action.comprimento,
                  largura:action.largura,
                  altura:action.altura,
                  peso:action.peso,
                  formaEntrega:action.formaEntrega
          };  

          case Actions.CARRINHO:
            return {
                ...state,
                carrinho: action.carrinho,
                subtotal:action.subtotal
         };
          /////

    case actionTypes.addToCart:

      return {
        ...state, 
        cartItems: [...state.cartItems, action.payload],
      };

    case actionTypes.removeFromCart:
      let filData = state.cartItems.filter(item => item.product_id !== action.payload.product_id)
      console.log('fildata', filData)
      action.payload.quantity_inc = 1
      return {
          ...state, 
          cartItems: filData, 
         // products: [...state.products, action.payload]
      }

    case actionTypes.addItemQty:
      let addItem = []
      state.cartItems.map(item => {
          if(item.id === action.payload.id){
              item.quantity_inc += 1
          }
          return addItem.push(item)
      })
      return {
          ...state, 
          cartItems: addItem, 
      }

    case actionTypes.removeItemQty:
      let removeItem = []
      state.cartItems.map(item => {
          if(item.id === action.payload.id){
              item.quantity_inc -= 1
          }
          return removeItem.push(item)
      })
      return {
          ...state, 
          cartItems: removeItem, 
      }

    case actionTypes.addWishlist:
      let filWishlist = state.cartItems.filter(item => item.id !== action.payload.id)
      action.payload.quantity_inc = 1
      return {
          ...state, 
          cartItems: filWishlist, 
          wishlists: [...state.wishlists, action.payload]
      }



    default:
      return state;
  }
}

export default cartReducer;
  